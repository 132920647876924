/**=====================
    52. RTL CSS Start
==========================**/
[main-theme-layout="rtl"] {
  #bitcoinchart-1,
  #bitcoinchart-2,
  #bitcoinchart-3 {
    direction: ltr;
  }
  .card-body {
    .alert {
      svg {
        ~ p {
          padding-left: unset;
          padding-right: 20px;
        }
      }
    }
  }
  .location-checkbox{
    span{
      padding-left: unset;
      padding-right: 33px;
    }
  }
  .apexcharts-legend-marker {
    margin-right: unset;
    margin-left: 3px;
  }
  .color-box {
    button {
      + button {
        margin-left: unset;
        margin-right: 8px;
      }
    }
  }
  .range-slider {
    .input-range {
      direction: ltr;
    }
  }
  .dzu-inputLabelWithFiles{
    margin-left: unset !important;
    margin-right: 30px !important;
  }
  .dzu-previewButton{
    margin: 0 10px 2px 0;
  }
  .modal{
    .modal-dialog.modal-lg{
      .modal-header{
        .modal-title{
          .btn-close.invisible{
            span{
              left: 10px;
              right: unset;
            }
          }
        }
      }
    }
  }
  .welcome-popup{
    .btn-close{
      left: unset;
      right: -20px;
    }
  } 
  .cont{
    .sub-cont{
      direction: ltr;
    }
    > div{
      &:nth-child(1){
        justify-content: flex-end;
      }
    }
  }
  .action-alert{
    .btn-close{
      right: unset;
      left: 75px !important;
    }
  }
  .cke_toolbar_separator{
    float: right;
  }
  .cke_toolbar_break{
    clear: right;
  }
  .has-aux{
    .rbt-input{
      padding-right: 14px;
      padding-left: 34px;
    }
  } 
  .rbt-aux{
    right: unset;
    left: 0;
  }
  .timepicker-form{
    .react-timekeeper{
      .react-timekeeper__clock-hours{
        left: 34px;
      }
    }
  }
  .form-inline{
    .form-group{
      margin-left: 15px;
      margin-right: unset;
    }
  }
  .ribbon-wrapper{
    .ribbon.ribbon-space-bottom{
      left: unset;
      right: 0;
    }
  }
  .pagination-lg, .pagination-sm{
    .page-item{
      &:first-child{
        .page-link{
          border-radius: 0 30px 30px 0;
        }
      }
      &:last-child{
        .page-link{
          border-radius: 30px 0 0 30px;
        }
      }
    }
  }
  .action-text {
    .alert-dismissible {
      .btn-close {
        top: 0 !important;
        left: 32px;
        right: unset;
        span{
          padding: 3px 5px;
        }
      }
    }
  }
  .icon-hover-bottom{
    .icon-popup{
      .close-icon{
        right: unset;
        left: 10px;
      }
    }
  }  
  .ecommerce-small-chart{
    .small-bar{
      .flot-chart-container{
        svg{
          left: unset;
          right: -20px;
        }
      }
    }
  }
  .page-wrapper{
    .page-main-header{
      .main-header-left{
        padding-left: unset;
        padding-right: 30px;
      }
    }
  }
  .ms-auto{
    margin-right: auto !important;
    margin-left: unset !important;
  }
  .m-l-40 {
    margin-left: unset !important;
    margin-right: 40px !important;
  }
  .p-l-25 {
    padding-left: unset;
    padding-right: 25px;
  }
  .border-end{
    border-right: unset !important;
    border-left: 1px solid #dee2e6 !important;
  }
  .navs-dropdown {
    .onhover-show-div {
      right: 0;
    }
  }
  .chart-widget{
    .apexcharts-canvas{
      direction: ltr;
    }
    .apexcharts-legend-marker{
      margin-right: 3px;
      margin-left: unset;
    }
  }
  .listing{
    .card-body{
      ol.d-block{
        padding-left: 0;
      }
    }
  }
  .blockquote{
    border-left: unset;
    border-right: 4px solid $light-gray;
    footer{
      &.blockquote-footer{
        margin-right: 0 !important;
      }
    }
    &.text-center{
      border: unset;
    }
    &.text-end{
      border-left: 4px solid $light-gray;
      border-right: unset;
    }
  }
  .chart-widget{
    .card-body{
      &.ps-3{
        padding-left: 1rem !important;
      }
    } 
  }
  .right-setting {
    .setting-dot {
      float: left;
      margin-left: unset !important;
    }
  }
  .height-equal{
    button.btn{
      span{
        margin-left: unset;
        margin-right: 6px;
      }
    }
  }
  .modal-dialog{
    &.modal-lg{
      .modal-header{
        .modal-title{
          button.btn-close{
            right: unset;
            left: 4px;
          }
        }
      }
    }
  }
  .default-according{
    .checkbox_animated, .radio_animated{
      margin: 0 8px 0 16px;
    }
  }
  .modal{
    .modal-dialog{
      .modal-header{
        position: relative;
        button.btn-close{
          span{
            left: 20px;
            right: unset;
          }
        }
      }
    }
  } 
  .product-filter{
    .product-box{
      .product-details{
        &.text-start{
          text-align: left !important;
          i.me-1{
            margin-right: 0.25rem !important;
            margin-left: unset !important;
          }
        }
      }
    }
  }
  .range-slider{
    .row{
      > div{
        > div{
          direction: ltr;
        }
      }
    }
  }
  .monthly-overview {
    .card-header {
      span {
        right: unset;
        left: 50px;
      }
      .badge {
        margin-left: 0;
        margin-right: 100px;
      }
    }
  }
  .product-wrapper{
    &.sidebaron{
      .product-sidebar{
        .filter-section{
          .card{
            .left-filter{
              .filter-cards-view{
                > div{
                  &:nth-child(3){
                    .product-filter{
                      direction: ltr;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .product-box{
    .product-img{
      .product-hover{
        ul{
          li{
            padding: 10px 12px;
          }
        }
      }
    }
  }
  .vertical-timeline--one-column{
    .vertical-timeline-element-content{
      text-align: left;
    }
  }
  .product-filter{
    .color-selector{
      ul{
        li{
          + li{
            margin-left: unset;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .square-product-setting{
    .icon-grid{
      margin: 0 0 0 10px;
    }
  } 
  .breadcrumb-item{
    + .breadcrumb-item{
      &::before{
        float: right;
      }
    }
  }
  .alert-dismissible.alert{
    .btn-close.btn-default{
      right: unset;
      left: 6px;
    }
  } 
  .pagination{
    .page-item{
      &:first-child{
        .page-link{
          border-left: 0;
        }
      }
    }
  }
  .recent-table{
    table{
      tbody{
        tr{
          td{
            &:last-child{
              text-align: left;
            }
          }
        }
      }
    }
  }
  .weather-bg{
    .weather-details{
      padding-left: unset;
      padding-right: 50px;
      .weather-title{
        span{
          margin-right: unset;
          margin-left: 10px;
          padding-left: 0;
        }
      }
    }
  } 
  .month-overview{
    span{
      &.badge{
        margin-left: unset;
        margin-right: 100px;
      }
    }
  }
  .weather-widget{
    .weather-widget-top{
      .location{
        left: 80px;
        right: unset;
      }
    }
  }
  .more-btn{
    .btn-light{
      right: unset;
      left: 0;
    }
  }
  .rating-star-wrapper{
    .m-r-5{
      margin-right: 5px;
      margin-left: unset;
    }
  }
  .page-wrapper{
    .page-body-wrapper{
      .page-header{
        .row{
          .breadcrumb-right{
            .breadcrumb{
              .breadcrumb-item{
                a{
                  padding: 4px 6px 2px 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .page-main-header{
    .main-header-right{
      .nav-right{
        .onhover-dropdown{
          .onhover-show-div{
            right: 13px;
            left: unset;
          }
        }
        .notification-dropdown{
          left: unset !important;
          right: -230px !important;
        }
      }
    }
  }
  .task-list{
    text-align: right;
    li{
      span{
        padding-left: 5px;
        padding-right: unset;
      }
    }
  }
  .cal-date-widget{
    .cal-info{
      padding-left: unset;
      padding-right: 15px;
      .b-r-light{
        border-right: 1px solid $light-color !important;
        border-left: unset !important;
        &.pe-3{
          padding-right: 1rem !important;
          padding-left: unset !important;
        }
      }
      .ps-3{
        padding-right: unset !important;
      }
    }
    .row{
      > div.ps-0{
        padding-left: 12px !important;
      }
    }
  }
  .weather-details {
    .media-body {
      &:before {
        left: unset;
        right: -22px !important;
      }
    }
    .weather-title {
      span {
        margin-left: 10px;
        padding-left: 40px;
      }
    }
  }
  // index page start
  .position-set {
    left: 50px;
    right: unset;
  }
  .card-bg {
    .media {
      h5 {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }
  .badge-transparent {
    i {
      padding-right: unset;
      padding-left: 5px;
    }
  }
  .creative-dots {
    .big-dot {
      left: unset;
      right: -32px;
    }
    .semi-big-dot {
      left: unset;
      right: -14px;
    }
    .medium-dot {
      left: unset;
      right: 18px;
    }
    .semi-medium-dot {
      left: unset;
      right: 20px;
    }
    .semi-small-dot {
      left: unset;
      right: 32px;
    }
    .small-dot {
      left: unset;
      right: 42px;
    }
  }
  // index page end
  .default-according {
    &.style-1 {
      button {
        text-align: right;
        &:before {
          left: 20px;
          right: unset;
        }
        i {
          right: 18px;
          left: unset;
        }
      }
    }
    .card {
      .card-header {
        i {
          right: 18px;
          left: unset;
        }
      }
    }
  }
  .ProfileCard-details {
    padding-right: 30px;
    padding-left: unset;
    float: right;
  }
  .theme-tab {
    .tab-title {
      li {
        a {
          svg {
            margin-left: 5px;
            margin-right: unset;
          }
        }
      }
    }
  }
  .spent {
    .spent-graph {
      .project-budget {
        margin-left: 30px;
        margin-right: unset;
      }
    }
  }
  .support-table {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              padding-right: 0;
              padding-left: unset;
            }
          }
        }
      }
    }
  }
  .widget-joins {
    .row {
      .pe-0 {
        padding-right: 15px !important;
      }
      .ps-0 {
        padding-left: 15px !important;
      }
    }
  }
  .order-box {
    .sub-total,
    .total,
    .qty {
      .shipping-class,
      li {
        .shopping-checkout-option,
        span {
          float: left;
        }
      }
      li {
        .count {
          float: left;
        }
      }
    }
  }
  .img-paypal {
    margin-right: 15px;
    margin-left: unset;
  }
  .cart {
    .qty-box {
      .input-group {
        .btn {
          border-radius: 0 !important;
        }
      }
    }
  }
  .todo {
    .todo-list-wrapper {
      #todo-list {
        li {
          .task-container {
            .task-action-btn {
              .action-box {
                margin-left: unset;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  .product-qnty {
    fieldset {
      .input-group {
        .input-group-prepend {
          .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
  .grid-options {
    margin-right: 10px;
    margin-left: unset;
    ul {
      li {
        a {
          .line-grid-1 {
            right: 12px;
            left: unset;
          }
          .line-grid-2 {
            right: 18px;
            left: unset;
          }
          .line-grid-3 {
            right: 36px;
            left: unset;
          }
          .line-grid-4 {
            right: 42px;
            left: unset;
          }
          .line-grid-5 {
            right: 48px;
            left: unset;
          }
          .line-grid-6 {
            right: 66px;
            left: unset;
          }
          .line-grid-7 {
            right: 72px;
            left: unset;
          }
          .line-grid-8 {
            right: 78px;
            left: unset;
          }
          .line-grid-9 {
            right: 84px;
            left: unset;
          }
          .line-grid-10 {
            right: 103px;
            left: unset;
          }
          .line-grid-11 {
            right: 109px;
            left: unset;
          }
          .line-grid-12 {
            right: 115px;
            left: unset;
          }
          .line-grid-13 {
            right: 121px;
            left: unset;
          }
          .line-grid-14 {
            right: 127px;
            left: unset;
          }
          .line-grid-15 {
            right: 133px;
            left: unset;
          }
        }
      }
    }
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          margin-right: calc(25% + 9px);
          margin-left: unset;
        }
      }
    }
  }
  .product-filter {
    &.new-products {
      .owl-theme {
        .owl-nav {
          left: 0;
          right: unset;
        }
        .owl-item {
          .item {
            .product-box {
              .product-details {
                &.text-start {
                  text-align: left !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .product-sidebar {
    .filter-section {
      .card {
        .card-header {
          h6 {
            .pull-right {
              i {
                left: auto;
                right: unset;
              }
            }
          }
        }
      }
    }
  }
  .feature-products {
    form {
      .form-group {
        i {
          left: 30px;
          right: unset;
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-details {
          text-align: right;
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .iconsidebar-menu {
        .iconMenu-bar {
          .iconbar-mainmenu {
            right: 90px;
            left: unset;
          }
        }
      }
      .page-body {
        margin-left: 0;
        margin-right: 300px;
      }
      .page-header {
        .row {
          .breadcrumb-right {
            .breadcrumb {
              float: left;
            }
          }
        }
      }
    }
  }
  .me-0,
  .mx-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  .footer {
    .pull-right {
      float: left;
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      footer {
        margin-right: 255px;
        margin-left: 0px;
      }
    }
  }
  .checkbox_animated,
  .radio_animated {
    margin: 0 0 0 16px;
  }
  .button.remove {
    left: -10px;
    right: unset;
  }
  .box-layout {
    .page-main-header {
      &.open {
        margin-right: auto;
        padding-right: 0;
      }
    }
  }
  .code-box-copy__btn {
    left: 11px;
    right: unset;
  }
  ul {
    &.nav-menus {
      border-bottom: none;
    }
    &.notification-dropdown {
      &.onhover-show-div {
        li {
          text-align: right;
        }
      }
    }
  }
  .badge {
    + .badge {
      margin-right: 5px;
      margin-left: unset;
    }
  }
  .offset-xl-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  .m-l-10 {
    margin-right: 10px !important;
    margin-left: unset !important;
  }
  .m-r-20 {
    margin-left: 20px;
    margin-right: unset;
  }
  .m-r-30 {
    margin-left: 30px;
    margin-right: unset;
  }
  .m-r-5 {
    margin-left: 5px;
    margin-right: unset;
  }
  .text-start {
    text-align: right !important;
  }
  .m-r-10 {
    margin-left: 10px;
    margin-right: unset;
  }
  .pe-3,
  .px-3 {
    padding-left: 1rem !important;
    padding-right: unset !important;
  }
  .p-r-0 {
    padding-left: 0;
    padding-right: 15px;
  }
  .m-l-20 {
    margin-right: 20px !important;
    margin-left: unset !important;
  }
  .ps-3,
  .px-3 {
    padding-right: 1rem !important;
  }
  .b-r-light {
    border-left: 1px solid $light-color !important;
    border-right: unset !important;
  }
  .float-end {
    float: left !important;
  }
  .float-start {
    float: right !important;
  }
  .text-end {
    text-align: left !important;
  }
  .border-right {
    border-left: 1px solid #dee2e6 !important;
    border-right: unset !important;
  }
  .pe-0,
  .px-0 {
    padding-left: 0 !important;
    padding-right: unset !important;
  }
  .ps-0,
  .px-0 {
    padding-right: 0 !important;
    padding-left: unset !important;
  }
  .ms-1 {
    margin-left: unset !important;
    margin-right: 0.25rem !important;
  }
  .owl-carousel {
    direction: ltr;
  }
  .btn-group {
    > {
      .btn:not(:last-child):not(.dropdown-toggle),
      :not(:last-child) > .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
      .btn:not(:first-child) {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .btn:nth-child(2) {
        border-radius: 0 !important;
      }
    }

    .btn-group > :not(:first-child) > .btn {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .btn + {
      .btn,
      .btn-group {
        margin-right: -1px;
      }
    }
    .btn-group + {
      .btn,
      .btn-group {
        margin-right: -1px;
      }
    }
    .btn-group-vertical {
      .btn + {
        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }
      .btn-group + {
        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }
    }
  }
  .me-3,
  .mx-3 {
    margin-right: unset !important;
    margin-left: 1rem !important;
  }
  .me-5,
  .mx-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  .ms-3,
  .mx-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  .m-r-15 {
    margin-left: 15px;
    margin-right: unset;
  }
  .b-l-light {
    border-right: 1px solid $light-color !important;
    border-left: unset !important;
  }
  .p-l-0 {
    padding-left: unset;
    padding-right: 0px;
  }
  .ps-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important;
  }
  .me-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  .ms-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  .pe-2 {
    padding-right: unset !important;
    padding-left: 0.5rem !important;
  }
  .alert {
    text-align: right;
  }
  .pull-right {
    float: left;
  }
  .form-inline {
    .form-group {
      margin-left: 15px;
      margin-right: 0;
    }
  }
  .card {
    text-align: right;
    .card-header {
      .card-header-right {
        left: 40px;
        right: unset;
        .card-option {
          text-align: left;
          &.list-unstyled {
            padding-right: 0;
            padding-left: unset;
          }
        }
      }
      .pull-left {
        float: right;
      }
      &.card-header-border {
        .right-header {
          span {
            &:first-child {
              padding-right: unset;
              padding-left: 20px;
            }
          }
        }
      }
    }
    &.full-card {
      left: 0;
      right: unset;
    }
    .card-body {
      .alert {
        svg {
          margin-left: 4px;
          margin-right: unset;
        }
      }
      .value-left {
        margin-left: 30px;
        margin-right: unset;
      }
      .square {
        margin-left: 5px;
        margin-right: unset;
      }
    }
  }
  .modal-header {
    .btn-close {
      margin-left: 0;
    }
  }
  .modal-footer {
    > :not(:first-child) {
      margin-right: 0.5rem;
      margin-left: 0;
    }
  }
  .form-inline {
    .form-group {
      .col-form-label {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
  .breadcrumb-item {
    & + .breadcrumb-item {
      padding-right: 0.5rem;
      padding-left: unset;
      &::before {
        padding-left: 0.5rem;
        padding-right: unset;
      }
    }
  }
  .form-check{
    .form-check-input{
      float: right;
      margin-left: unset;
      margin-right: -1.5em;
    }
  } 
  form{
    &.needs-validation{
      .dflex{
        .input-group{
          .w-auto.input-group-text{
            border-left: unset;
          }
        }
      }
    }
  }
  .form-select{
    background-position: left 0.75rem center;
    padding: 0.375rem 0.75rem 0.375rem  2.25rem;
  }
  .was-validated .form-control:valid, .form-control.is-valid{
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-right: 12px;
    padding-left: calc(1.5em + 0.75rem);
  }
  .was-validated .form-select:valid:not([multiple]):not([size]), 
  .was-validated .form-select:valid:not([multiple])[size="1"], 
  .form-select.is-valid:not([multiple]):not([size]), 
  .form-select.is-valid:not([multiple])[size="1"]{
    padding-left: 4.125rem;
    padding-right: 0.75rem;
    background-position: left 0.75rem center, center left 2.25rem;
  }
  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) 
  > .dropdown-toggle:nth-last-child(n+3){
    border-radius: 0 .25rem .25rem 0;
  }
  code[class*="language-"],
  pre[class*="language-"] {
    text-align: right;
  }
  .input-group-prepend {
    margin-left: -1px;
    margin-right: unset;
  }
  .input-group > .form-control:not(:last-child),
  .rtl .input-group > .form-select:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .border-tab-primary.nav-left .nav-link {
    text-align: left;
  }
  // social app page css
  .socialprofile {
    .social-group {
      ul {
        li {
          &:nth-child(4) {
            margin-right: -22px;
          }
        }
      }
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-plaintext {
          padding-right: 15px;
          padding-left: unset;
        }
      }
    }
    .media {
      .social-status {
        right: 35px;
        left: unset;
      }
    }
  }
  .social-chat {
    .other-msg {
      margin-right: 40px;
      margin-left: unset;
    }
    .media-body {
      &:before {
        left: 100%;
        right: unset;
        border-left: 7px solid $semi-dark;
        border-right: unset;
      }
      &:after {
        left: 100%;
        right: unset;
        border-left: 7px solid $white;
        border-right: unset;
      }
    }
  }
  .timeline-content {
    .comment-number {
      i {
        margin-left: 20px;
        margin-right: unset;
      }
    }
  }
  .photos {
    ul {
      li {
        margin-right: unset;
        margin-left: 15px;
        &:nth-child(3n) {
          margin-left: 0;
        }
      }
    }
  }
  .avatar-showcase {
    .friend-pic {
      margin-left: 8px;
      margin-right: unset;
    }
    .pepole-knows {
      ul {
        li {
          margin-left: 21px;
          margin-right: unset;
          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          left: 100px;
          right: unset;
          li {
            margin-left: 10px;
            margin-right: unset;
            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  // faq page css
  .faq-form {
    .search-icon {
      left: 12px;
      right: unset;
    }
  }
  .faq-widgets {
    .media-body {
      p {
        padding-left: 20px;
        padding-right: unset;
      }
    }
  }
  .header-faq,
  .faq-title {
    text-align: right;
  }
  .faq-accordion {
    .faq-header {
      svg {
        left: 30px;
        right: unset;
      }
    }
    .card {
      .btn-link {
        svg {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
  }
  .navigation-option {
    ul {
      li {
        padding: 15px 0 15px 15px;
        a {
          padding-right: 40px;
          padding-left: unset;
          svg {
            right: 15px;
            left: unset;
          }
        }
      }
    }
  }
  .updates-faq {
    margin-right: unset;
    margin-left: 30px;
  }
  // knowledgebase page css
  .browse {
    .browse-articles {
      span {
        svg {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
  }
  // learning app page css
  .categories {
    .learning-header {
      padding-right: 30px;
      padding-left: unset;
    }
  }
  .pricing-block {
    svg {
      direction: ltr;
    }
  }
  // chart peity css
  p {
    &.data-attributes {
      svg {
        margin-left: 10px;
        margin-right: unset;
      }
    }
  }
  // avatars page css
  .avatars {
    .avatar {
      margin-left: 10px;
      margin-right: unset;
      &:last-child {
        margin-left: 0;
      }
      .status {
        left: 4px;
        right: unset;
      }
    }
  }
  .customers {
    &.avatar-group {
      margin-left: 30px;
      margin-right: unset;
    }
  }
  // dashboard css
  .alert-theme,
  .alert-copy {
    i {
      margin-right: 0 !important;
    }
    button {
      left: 30px !important;
      right: unset !important;
    }
  }
  .status-circle {
    right: 40px;
    left: unset;
  }
  // crypto dashboard page
  .crypto-table-market{
    table{
      tbody{
        tr{
          td{
            &:last-child{
              text-align: left;
            }
          }
        }
      }
    }
  }
  .btc-buy-sell {
    .btc-amount {
      text-align: left;
    }
  }
  .more-btn {
    .btn {
      margin-right: unset;
      margin-left: -50px;
      padding-left: 50px;
      padding-right: unset;
      &:nth-child(2) {
        padding: 25px 21px;
        margin-right: unset;
        margin-left: 12px;
        border-radius: 0 50% 50% 0;
      }
    }
  }
  .right-angle {
    i {
      padding-right: 16px;
      padding-left: 0;
    }
  }
  .coin-card {
    .right-setting {
      i {
        margin-left: 0.5rem !important;
        margin-right: unset !important;
      }
    }
  }

  // ecommerce dashboard css
  .customers {
    ul {
      li {
        + li {
          margin-right: -22px;
          margin-left: unset;
        }
        &:nth-child(4) {
          margin-right: -1px;
          margin-left: unset;
        }
      }
    }
  }
  .crypto-revenue {
    .card-header {
      h5 {
        .badge {
          margin-left: unset;
          margin-right: 35px;
        }
      }
    }
  }
  // knob chart css
  .knob-chart {
    .chart-clock-main {
      .clock-small {
        right: 10px;
        left: unset;
      }
    }
  }
  // general widgets css
  .weather-widget-two {
    .top-bg-whether {
      left: -28px;
      right: unset;
    }
    .bottom-whetherinfo {
      svg {
        left: unset;
        right: -35px;
      }
    }
  }
  .mobile-clock-widget {
    .bg-svg {
      left: unset;
      right: -25px;
    }
  }
  // to-do css
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        .btn-label {
          margin-left: 5px;
          margin-right: unset;
        }
        left: 30px;
        right: unset;
      }
      #todo-list {
        li {
          .task-container {
            .task-action-btn {
              text-align: left;
            }
          }
        }
      }
      .todo-list-footer {
        .card-header-add {
          right: unset;
          left: 0;
        }
      }
    }
    .notification-popup {
      left: 10px;
      right: unset;
    }
  }
  .todo-options {
    .badges-todo {
      h6 {
        margin-left: 10px;
        margin-right: unset;
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
  .todo-list-btns {
    .dropdown-basic {
      .btn-group {
        .form-group {
          .checkbox {
            padding-right: 14px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            padding-left: unset;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
          }
        }
      }
      .separated-btn {
        margin-right: -6px;
        margin-left: unset;
        .btn {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
      }
    }
  }
  .search-todo {
    .dropdown-basic {
      .btn-group {
        margin-left: 18px;
        margin-right: unset;
      }
    }
  }
  //sticky note css
  .sticky-note {
    .note {
      float: right;
    }
  }
  #testimonial {
    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          .slide--item {
            .media {
              .me-3,
              .mx-3 {
                margin-right: 1rem !important;
                margin-left: unset !important;
              }
            }
          }
        }
      }
    }
  }
  .needs-validation,
  .dflex {
    .input-group > .form-control:not(:last-child),
    .input-group > .form-select:not(:last-child) {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .form-check {
    padding-right: 1.25rem;
    padding-left: unset;
  }
  .contact-search {
    .form-group {
      &:before {
        right: 50px !important;
        left: unset !important;
      }
      &:after {
        right: 20px !important;
        left: unset !important;
      }
    }
  }   
  // email css
  .email-wrap {
    .actions{
      li{
        margin-right: unset;
        margin-left: 25px;
      }
    } 
    .email-body{
      .attachment{
        .list-inline{
          .list-inline-item{
            padding-right: unset;
            padding-left: 13px;
          }
        }
      }
    }
    .row {
      .col-xl-6 {
        padding-right: 0;
        padding-left: 15px;
      }
      .col-xl-3 {
        + .col-xl-3 {
          padding-left: 0;
          padding-right: 15px;
        }
      }
    }
    .email-app-sidebar {
      .main-menu {
        & > li {
          text-align: right;
          a {
            i {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }
      ul {
        padding-right: 0;
        li {
          a {
            padding-left: unset;
            padding-right: 20px;
          }
        }
      }
    }
    .email-content {
      .email-top {
        .user-emailid {
          &:after {
            float: left;
          }
        }
      }
    }
    .email-right-aside {
      .email-body {
        .pe-0 {
          padding-right: unset !important;
        }
      }
      .radius-left {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-radius: 4px;
      }
    }
  }
  // calender css
  .calendar-wrap {
    .fc {
      direction: rtl;
      text-align: right;

      .fc-toolbar {
        .fc-left {
          float: right;
        }
        .fc-right {
          float: left;
        }
        & > * {
          & > * {
            margin-left: 0;
          }
        }
      }
    }
    .fc-events-container {
      text-align: right;
    }
    .fc-scroller {
      margin-right: unset;
      margin-left: -17px;
    }
  }
  .fc-agenda-view {
    .fc-day-grid {
      .fc-row {
        margin-right: 0 !important;
      }
    }
  }
  .fc-unthemed {
    .fc-row {
      margin-right: 0 !important;
    }
  }
  // buttons css
  .btn-group-showcase {
    .btn-group {
      margin-left: 20px;
      margin-right: unset;
    }
  }
  .btn-showcase {
    .btn {
      margin-right: unset;
      margin-left: 18px;
    }
  }
  .btn-square {
    &.dropdown-toggle {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  // button-builder css
  &.button-builder {
    ul.the-icons {
      li {
        float: right;
      }
    }
    .color-slelector {
      float: right;
    }
    .custom-button-color {
      .form-control {
        border-radius: 0 5px 5px 0;
        &.pull-left {
          float: right;
        }
      }
    }
    .hint-label {
      float: right;
      padding: 4px 020px 0 0;
    }
  }
  #customer-review {
    .owl-item {
      img {
        order: 2;
      }
    }
  }
  .options {
    > div {
      margin: 0 0 8px 8px;
    }
  }
  // modal page
  .modal-dialog {
    .modal-content {
      .modal-body {
        text-align: right;
        p {
          a {
            margin-left: 0;
          }
        }
      }
    }
  }
  // forms page
  .drag-box {
    fieldset {
      .component {
        .form-group {
          .text-lg-left {
            text-align: right !important;
          }
          .input-group {
            .input-group-prepend {
              .btn,
              .input-group-text {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
              }
              .btn-right,
              .checkbox-radius {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
            #prependedcheckbox {
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }
            #appendedcheckbox {
              border-right-color: $light-semi-gray;
            }
            #buttondropdown {
              margin-right: unset;
              margin-left: -1px;
            }
          }
        }
      }
    }
  }
  .drag {
    form {
      text-align: right;
      input {
        text-align: right;
      }
      label {
        text-align: right !important;
      }
      .form-group {
        .input-group {
          .input-group-prepend {
            .btn,
            .input-group-text {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-top-right-radius: 0.25rem;
              border-bottom-right-radius: 0.25rem;
            }
            .btn-right,
            .checkbox-radius {
              border-top-left-radius: 0.25rem;
              border-bottom-left-radius: 0.25rem;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
          #buttondropdown {
            margin-right: unset;
            margin-left: -1px;
          }
        }
      }
    }
  }
  .draggable {
    label {
      text-align: right;
    }
  }
  .form-horizontal {
    &.theme-form {
      fieldset {
        .tab-content {
          .tab-pane {
            .component {
              .form-group {
                .text-lg-left {
                  text-align: right !important;
                }
                .input-group {
                  .input-group-prepend {
                    .btn,
                    .input-group-text {
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      border-top-right-radius: 0.25rem;
                      border-bottom-right-radius: 0.25rem;
                    }
                    .btn-right,
                    .checkbox-radius {
                      border-top-left-radius: 0.25rem;
                      border-bottom-left-radius: 0.25rem;
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    }
                  }
                  #prependedcheckbox {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                  }
                  #appendedcheckbox {
                    border-right-color: $light-semi-gray;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // sidebar
  .page-wrapper {
    .page-body-wrapper {
      .iconsidebar-menu {
        &.iconbar-mainmenu-close {
          ~ .page-body,
          ~ footer {
            margin-left: unset;
            margin-right: 90px;
          }
          .iconMenu-bar {
            .iconbar-mainmenu {
              animation: fadeInRight 1000ms ease-in-out !important;
              margin-left: unset;
              margin-right: -225px;
            }
          }
        }
        &.iconbar-second-close {
          .iconMenu-bar {
            margin-left: unset;
            margin-right: -320px;
            .iconbar-mainmenu {
              margin-left: unset;
              margin-right: -320px;
            }
          }
          ~ .page-body,
          ~ footer {
            margin-left: unset;
            margin-right: 0;
          }
        }
        .iconMenu-bar {
          height: calc(100vh - 80px);
          .iconbar-mainmenu {
            animation: bounceInRight 1000ms ease-in-out;
            text-align: right;
            .iconbar-header {
              padding: 20px 20px 20px 0;
            }
            li {
              padding-left: unset;
              padding-right: 20px;
            }
          }
        }
      }
    }
    &.horizontal_sidebar {
      .page-body-wrapper {
        .iconsidebar-menu {
          .iconMenu-bar {
            height: auto;
          }
        }
        .page-body,
        footer {
          margin-right: 0;
        }
      }
    }
  }
  // search page
  .search-page {
    .nav i {
      margin-left: 10px;
      margin-right: unset;
    }
  }
  .product-stts {
    display: inline-block;
    .ms-1 {
      margin-right: 0.25rem !important;
      margin-left: unset !important;
    }
  }
  .search-form input {
    padding: 10px 70px 10px 10px;
    text-align: right;
  }
  // icons page
  .icon-hover-bottom {
    .icon-title {
      text-align: right;
    }
    .icon-popup {
      .icon-last {
        .form-inline {
          .form-group {
            .btn {
              margin-right: 15px;
              margin-left: unset;
            }
          }
        }
      }
    }
  }
  .icon-lists {
    div {
      i {
        margin: 0 10px 0 15px;
      }
    }
    ul {
      padding-right: 0;
    }
  }
  ul {
    &.icon-lists {
      padding-right: 30px;
    }
  }
  // customizer
  .floated-customizer-btn {
    &.third-floated-btn {
      left: 35px;
      right: unset;
    }
    & > span {
      margin-right: 10px;
    }
  }
  .floated-customizer-panel {
    text-align: right;
    left: -85px;
    right: unset;
    &.active {
      left: 35px;
      right: unset;
    }
    .btn-close-customizer-btn {
      left: 30px;
      right: unset;
    }
    ul {
      padding-right: 0;
    }
  }
  .radio {
    label {
      padding-left: 0;
      padding-right: 15px;
      &::before {
        right: 0;
        margin-right: -20px;
        left: unset;
      }
    }
  }
  // sidebar main page
  .page-wrapper {
    .page-body-wrapper {
      .page-header {
        .row {
          h2,
          h6 {
            text-align: right;
          }
        }
      }
      .footer-fix {
        margin-right: 250px;
        margin-left: unset;
      }
    }
  }
  // right-sidebar page
  .right-sidebar {
    left: -285px;
    right: unset;
    .modal-header {
      .modal-title {
        text-align: right;
      }
    }
    .friend-list-search {
      .fa {
        left: 35px;
        right: unset;
      }
    }
    &.show {
      left: 0;
    }
  }
  // page main header
  .page-main-header {
    // margin-right: 255px;
    margin-left: unset;
    .main-header-right {
      .nav-right {
        .droplet-dropdown {
          right: -315px !important;
          left: unset !important;
          &:after,
          &:before {
            right: unset;
            left: 27px;
          }
          li {
            .row {
              .droplet-main {
                border-right: 1px solid $light-semi-gray;
                &:nth-child(3n) {
                  border-right: 1px solid $light-semi-gray;
                }
                &:first-child {
                  border-right: none;
                }
              }
            }
          }
        }
        text-align: left;
        > ul {
          > li {
            .dot {
              left: 17px;
              right: unset;
            }
            .media {
              .dotted-animation {
                left: -3px;
                right: unset;
                .main-circle {
                  left: 6px;
                  right: unset;
                }
                .animate-circle {
                  left: -4px;
                  right: unset;
                }
              }
            }
            &.onhover-dropdown {
              .profile-dropdown {
                right: -65px !important;
                left: unset !important;
                &:before {
                  right: unset;
                  left: 20px;
                }
                &:after {
                  right: unset;
                  left: 20px;
                }
              }
              &:last-child {
                padding-left: 0;
                padding-right: 20px;
                border-right: none;
              }
            }
            &:first-child {
              border-right: none;
              .search-form {
                .form-group {
                  &:after {
                    right: 22px;
                    left: unset;
                  }
                  &:before {
                    right: 53px;
                    left: unset;
                  }
                }
              }
            }
            text-align: right;
            i {
              &.ms-2 {
                margin-right: 0.5rem !important;
                margin-left: unset !important;
              }
            }
          }
          .search-form {
            input {
              padding: 10px 70px 10px 10px;
            }
          }
        }
        .profile-dropdown {
          li {
            svg {
              margin-left: 10px;
              margin-right: unset;
            }
          }
        }
      }
    }
    &.open {
      margin-right: 0;
    }
  }
  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        right: initial;
        left: -30px;
        padding: 0;
        &:before {
          right: 34px !important;
          left: unset;
        }
        &:after {
          right: 34px !important;
          left: unset;
        }
        li {
          margin-right: 0 !important;
          span {
            &.badge {
              margin-left: unset !important;
            }
            svg {
              margin-right: unset;
              margin-left: 10px;
            }
          }
          .notification-icon {
            margin-left: 20px;
            margin-right: unset;
          }
        }
      }
    }
  }
  // alert page
  .alert {
    i {
      margin-left: 5px;
      margin-right: 0;
    }
  }
  .inverse {
    padding: 13px 65px 13px 20px;
    i {
      left: unset;
      right: 0;
    }
  }
  @each $var in $alert-name {
    $i: index($alert-name, $var);
    .alert-#{$var}.inverse {
      &:before {
        border-right: 7px solid nth($alert-color, $i);
        right: 54px;
        left: unset;
        border-left: unset;
        @media (max-width: 575px){
          right: 40px;
        }
      }
      i {
        border-radius: 0 15px 15px 0;
      }
    }
  }
  .alert-dismissible {
    .btn-close {
      left: 0;
      right: unset;
      span {
        padding-left: 0;
        padding-right: unset;
      }
    }
  }
  .icofont {
    &.icofont-truck {
      display: inline-block;
    }
  }
  // blog page
  .blog-box {
    .blog-details {
      padding-left: 20px;
      text-align: right;
      .blog-social {
        padding-right: 0;
        li {
          padding-right: 20px;
          padding-left: unset;
          &:first-child {
            border-left: 1px solid #777777;
            border-right: unset;
            padding-left: 20px;
            padding-right: unset;
          }
          & + li {
            margin-left: unset;
            padding-left: 0;
          }
        }
      }
    }
    .blog-details-main {
      .blog-social {
        li {
          border-left: 1px solid;
          border-right: unset;
          &:last-child {
            border-left: none;
          }
        }
      }
    }
    .blog-date {
      span {
        font-size: 36px;
        font-weight: 500;
        padding-left: 5px;
      }
    }
  }
  .comment-box {
    .media {
      img {
        margin-left: 45px;
        margin-right: unset;
      }
      h6 {
        text-align: right;
      }
    }
    ul {
      ul {
        margin-right: 135px;
        margin-left: unset;
        padding-right: 0;
        &.comment-social.float-start{
          margin-right: 0;
          margin-left: unset;
        }
      }
    }
    .comment-social {
      text-align: left;
      li {
        padding-left: 0;
        padding-right: 20px;
        &:first-child {
          border-left: 1px solid $light-semi-gray;
          border-right: unset;
          padding-right: 0;
          padding-left: 20px;
        }
      }
    }
  }
  // chat page
  .call-chat-body {
    .chat-box {
      .chat-right-aside {
        .chat {
          .chat-history {
            .caller-img {
              &.ps-0 {
                padding-left: 15px !important;
              }
            }
          }
        }
      }
    }
  }
  .chat-box {
    .user-image {
      float: right;
      margin-left: 5px;
      margin-right: 0;
    }
    .people-list {
      .search {
        i {
          left: 10px;
          right: unset;
        }
      }
    }
    .about {
      float: right;
      padding-right: 10px;
      padding-left: unset;
      text-align: right;
    }
    .chat-menu {
      border-right: 1px solid $light-color;
      border-left: unset;
      .nav {
        padding-right: 0;
      }
      &.ps-0 {
        padding-left: 15px !important;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-message {
          .smiley-box {
            margin-left: 0.5rem;
            margin-right: unset;
          }
        }
        .chat-msg-box {
          .my-message {
            border-top-left-radius: 10px;
            border-top-right-radius: 0;
          }
          .other-message {
            border-top-left-radius: 0;
            border-top-right-radius: 10px;
          }
        }
      }
      &.pe-0 {
        padding-right: 15px !important;
      }
    }
    .chat {
      .chat-message {
        .text-box {
          .input-group-append {
            margin-right: -2px;
          }
        }
      }
    }
  }
  .chat-msg-box {
    ul {
      padding-right: 0;
    }
  }
  .chat-left-aside {
    .status-circle {
      left: unset;
      right: 40px;
    }
  }
  .chat-right-aside {
    .chat {
      .chat-header {
        .chat-menu-icons {
          padding-left: 0;
          padding-right: unset;
          &.float-sm-end {
            float: left !important;
          }
        }
        img {
          float: right;
        }
      }
    }
  }
  // cke editor page
  .cke_toolbar,
  .cke_toolgroup,
  a.cke_button,
  .cke_combo_text {
    float: right;
  }
  .cke_reset_all,
  .cke_reset_all *,
  .cke_reset_all a,
  .cke_reset_all textarea {
    text-align: right;
  }
  .cke_combo_text {
    padding-left: unset;
    padding-right: 10px;
  }
  #editor1 {
    #cke_editor1 {
      #cke_1_contents {
        iframe {
          html {
            direction: rtl !important;
          }
        }
      }
    }
  }
  // coming soon page
  #clockdiv {
    ul {
      padding-right: 0;
    }
  }
  // social app page
  .custom-card {
    .card-footer {
      & > div {
        & + div {
          border-right: 1px solid $light-semi-gray;
          border-left: unset;
        }
      }
    }
  }
  .card-social {
    padding-right: 0;
  }
  .card-absolute {
    .card-header {
      right: 15px;
      left: unset;
    }
  }
  .custom-radio-ml {
    margin-left: unset;
    margin-right: 20px;
  }
  .rangeslider,
  .react-stickies-wrapper,
  #basic-apex {
    direction: ltr;
  }
  .crop-portion {
    margin-left: unset;
    margin-right: 30px;
  }

  // dropdown page
  .dropdown-basic {
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
    .separated-btn {
      .btn {
        border-radius: 0.25rem;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .dropdown,
    .dropup {
      .dropdown-menu {
        text-align: right;
      }
    }
  }
  // error page
  .error-wrapper {
    .col-md-8 {
      &.offset-md-2 {
        margin: 0 auto !important;
      }
    }
  }
  // product page
  .product-box {
    .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
    .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
      border-left-color: $info-color;
    }
    .ribbon-bookmark.ribbon-vertical-left:before,
    .ribbon-bookmark.ribbon-vertical-right:before {
      right: 0;
      left: unset;
      border-left: 15px solid #2a3142;
    }
    .ribbon-bookmark.ribbon-info:before {
      border-left-color: transparent;
    }
    .ribbon-warning.ribbon-clip:before {
      border-left-color: #ff850d;
      border-right-color: unset;
    }
    .ribbon-clip {
      right: -14px;
      left: unset;
      &:before {
        right: 0;
        left: unset;
      }
    }
  }
  .product-hover {
    ul {
      padding-right: 0;
    }
  }
  .customer-review {
    .media {
      img {
        margin-left: 25px;
        margin-right: 0;
      }
    }
  }
  .nav {
    padding-right: 0;
  }
  .form-label {
    &::after {
      left: 0;
      right: unset;
    }
  }
  // general widgets page
  .static-top-widget .media-body {
    padding-right: 30px;
    padding-left: unset;
    .icon-bg {
      left: -20px;
      right: unset;
    }
  }
  .widget-joins {
    .media {
      .details {
        border-right: 1px solid $light-semi-gray;
        border-left: unset;
      }
      .media-body {
        text-align: right;
      }
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        text-align: left;
      }
    }
  }
  .btn-group {
    > .btn:not(:last-child):not(.dropdown-toggle) {
      > .btn-group:not(:last-child) > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .list-group {
    padding-right: 0;
    i,
    img {
      margin-left: 10px;
      margin-right: 0;
    }
  }
  // authentication page
  .authentication-box {
    text-align: right;
  }
  // mega options page
  .mega-horizontal {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }
  }
  // navs page
  .navs-icon {
    svg {
      margin-left: 10px;
      margin-right: unset;
    }
    .fa {
      margin-left: 10px;
      margin-right: unset;
    }
  }
  .nav-list {
    .nav-list-disc {
      text-align: right;
      padding-right: 0;
    }
  }
  .ps-navs-inline {
    padding-right: 30px !important;
    padding-left: 0 !important;
  }
  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
    left: 77px;
    right: unset;
  }
  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
    left: 77px;
    right: unset;
  }
  .navs-dropdown {
    .onhover-show-div {
      .navs-icon {
        li {
          text-align: right;
        }
      }
    }
  }
  .navs-icon.default-according.style-1 {
    li {
      button[aria-expanded="true"] {
        &:before {
          left: 2px;
          right: unset;
        }
      }
      button[aria-expanded="false"]:before {
        left: 2px;
        right: unset;
      }
    }
  }
  // search page css
  .search-form {
    .form-group {
      &:after {
        right: 53px;
        left: unset;
      }
      &:before {
        right: 82px;
        left: unset;
      }
    }
  }
  // order history page css
  .order-history {
    table {
      tbody {
        tr {
          td {
            .product-name {
              .order-process {
                .order-process-circle {
                  &:before {
                    right: -15px;
                    left: unset;
                  }
                }
              }
            }
          }
        }
      }
      th,
      td {
        &:first-child {
          text-align: right;
        }
      }
    }
  }
  .mega-inline,
  .mega-vertical,
  .mega-horizontal {
    .media {
      margin-right: 20px;
      margin-left: unset;
    }
  }
  .pull-left {
    float: right;
  }
  .rbt-menu {
    text-align: right;
  }
  .me-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  .ms-4 {
    margin-left: unset !important;
    margin-right: 1.5rem !important;
  }
  // product-page
  .product-page-details {
    span {
      padding-right: 15px;
      padding-left: 0;
    }
  }
  .m-checkbox-inline {
    label {
      margin-left: 20px;
      margin-right: 0;
    }
    .radio,
    .checkbox {
      + .radio,
      + .checkbox {
        margin-left: unset;
        margin-right: 15px;
      }
    }
  }
  .form-label-align-right {
    label {
      text-align: left;
    }
  }
  // rating page
  .br-theme-bars-reversed,
  .br-theme-bars-movie,
  .br-theme-bars-1to10,
  .br-theme-bars-square {
    .br-widget {
      .br-current-rating {
        float: right;
      }
      a {
        float: right;
      }
    }
  }
  // ribbons page
  .ribbon-left {
    right: auto;
    left: -2px;
  }
  .ribbon-vertical-left {
    right: auto;
    left: 12px;
  }
  .ribbon-vertical-right {
    left: auto;
    right: 12px;
  }
  // search page
  .star-ratings {
    ul {
      &.search-info {
        padding-right: 0;
        li {
          & + li {
            border-right: 1px solid #a5afc6;
            border-left: unset;
            padding-right: 8px;
            padding-left: 0;
            margin-right: 8px;
            margin-left: 0;
          }
        }
      }
    }
  }
  // select 2 page
  .editor-statusbar {
    text-align: left;
    span {
      margin-right: 1em;
      margin-left: unset;
    }
  }
  .primary-color,
  .yellow-color {
    ul {
      padding-right: 0;
    }
  }
  // steps page
  .u-step-desc {
    text-align: right;
  }
  .u-step-number {
    right: 20px;
    left: unset;
    & ~ .u-step-desc {
      margin-right: 50px;
    }
  }
  .u-pearl {
    &:after {
      left: 0;
      right: unset;
    }
    &:before {
      right: 0;
      left: unset;
    }
  }
  .u-step-icon {
    float: right;
    margin-left: 0.5em;
    margin-right: unset;
  }
  // summernote page
  .list-icons {
    padding-right: 0;
  }
  .card-header.note-toolbar {
    .note-color {
      .dropdown-toggle {
        padding-right: 5px;
      }
    }
    .form-group {
      &.draggable {
        text-align: right;
      }
    }

    .form-check {
      padding-right: 24px;
      padding-left: unset;
    }
    form {
      .form-control {
        padding-right: 24px;
        padding-left: unset;
        .form-label {
          &::after {
            right: 0;
            left: unset;
          }
          &::before {
            right: 0;
            left: unset;
          }
        }
      }
    }
  }
  // input group page
  .dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: unset;
  }
  // tabbed card page
  .tabbed-card {
    ul {
      left: 15px;
      right: unset;
    }
  }
  // checkbox & radios page
  .checkbox {
    label {
      padding-right: 16px;
      padding-left: unset;
      &::before {
        top: 2px;
        right: 0;
        left: unset;
        margin-right: -16px;
        margin-left: unset;
      }
    }
  }
  .radio {
    label {
      &::after {
        right: 3px;
        left: unset;
        margin-right: -20px;
        margin-left: unset;
      }
    }
  }
  .charts-wrapper{
    .apexcharts-legend-marker{
      margin-right: 3px;
      margin-left: unset;
    }
    .card{
      .card-body{
        > div{
          direction: ltr;
        }
      }
    }
  }
  // timeline page
  .vertical-timeline {
    direction: ltr;
  }
  .list-inline-item {
    &:not(:last-child) {
      margin-left: 0.5rem;
      margin-right: unset;
    }
  }
  .like-comment {
    ul {
      &.list-inline {
        padding-right: 0;
      }
    }
  }
  .typeahead {
    text-align: right;
  }
  .listing {
    ul {
      padding-right: 0;
      .icofont {
        float: right;
      }
    }
  }
  // gallery page
  .gallery {
    .hover-5 {
      img {
        margin-right: 30px;
      }
      &:hover {
        img {
          margin-right: 0;
        }
      }
    }
  }
  .lg-outer {
    text-align: right;
  }
  .lg-toolbar .lg-icon {
    float: left;
  }
  #lg-counter {
    padding-right: 20px;
    padding-left: unset;
    float: right;
  }
  // chart widget page
  .chart-widget-top {
    .text-end {
      text-align: left !important;
    }
    .num {
      .ms-1 {
        margin-right: 0.25rem !important;
      }
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        left: -240px;
      }
    }
    .bottom-content {
      .b-r-light {
        border-left: 1px solid #eeeeee !important;
        border-right: unset !important;
      }
      .num {
        .ms-1 {
          margin-right: 0.25rem !important;
        }
      }
    }
  }
  // dashboard project page
  .crm-activity {
    ul {
      &.dates {
        li {
          + li {
            border-right: 1px solid #ddd;
            padding-right: 10px;
            margin-right: 10px;
            border-left: unset;
            padding-left: unset;
            margin-left: unset;
          }
        }
      }
    }
  }
  div {
    &.dt-buttons {
      float: right;
    }
  }
  .dt-button-collection {
    left: -39px !important;
  }
  // dropzone page
  .dropzone {
    .dz-preview {
      .dz-error-mark,
      .dz-success-mark {
        right: 50%;
        margin-right: -27px;
        margin-left: unset;
        left: unset;
      }
    }
  }
  // footer page
  .page-wrapper {
    .page-body-wrapper {
      footer {
        margin-right: 300px;
        margin-left: unset;
        p {
          i {
            margin-right: 5px;
            margin-left: unset;
          }
        }
      }
    }
  }
  .footer-copyright {
    text-align: right;
  }
  .footer-links {
    text-align: left;
  }
  .page-item {
    &:first-child {
      .page-link {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &:last-child {
      .page-link {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  // popover page
  .popover.top,
  .popover.bottom {
    > .arrow:after {
      margin-right: -10px;
      margin-left: unset;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    > .input-group-append {
      > .btn,
      > .input-group-text {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
    > .form-control,
    > .form-select {
      &:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  // typahead page
  .typeahead {
    span {
      &.twitter-typeahead {
        .tt-suggestion {
          text-align: right;
        }
      }
    }
    .league-name {
      text-align: right;
      margin-right: 5px;
    }
  }
  .Typeahead-menu {
    right: -7px;
    .ProfileCard-details {
      .ProfileCard-realName {
        .realname {
          text-align: right;
        }
      }
    }
  }
  // call-chat page
  .follow {
    .text-md-end {
      text-align: left !important;
    }
  }
  // vector map page
  .jvectormap-zoomin,
  .jvectormap-zoomout,
  .jvectormap-goback {
    right: 10px;
    left: unset;
  }

  //customizer rtl css
  .customizer-links {
    right: unset;
    left: 0;
    &.open {
      right: unset;
      left: 330px;
      border-radius: 0 8px 8px 0;
    }
  }
  .customizer-contain {
    right: unset;
    left: -334px;
    &.open {
      right: unset;
      left: 0px;
    }
    .customizer-header {
      .icon-close {
        left: unset;
        right: 30px;
      }
    }
  }
  // dark only rtl css
  &.dark-only {
    .b-r-light {
      border-left: 1px solid $dark-border-color !important;
      border-right: unset !important;
    }
    .crm-activity{
      ul.dates{
        li{
          + li{
            border-right: 1px solid $dark-border-color;
            border-left: unset;
          }
        }
      }
    }
    .page-wrapper{
      .page-body-wrapper{
        .page-body{
          .browser-widget.b-r-light{
            border-right: unset !important;
          }
        }
      }
    }  
    .page-main-header {
      // margin-right: 255px;
      margin-left: unset;
      .main-header-right {
        .nav-right {
          .droplet-dropdown {
            li {
              .row {
                .droplet-main {
                  &:nth-child(3n) {
                    border-right: 1px solid $light-all-font-color !important;
                  }
                  &:first-child,
                  &:nth-child(4) {
                    border-right: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // boxes layout rtl css
  .box-layout {
    &.page-wrapper {
      .page-main-header {
        padding-right: 250px;
        padding-left: unset;
        &.open {
          padding-right: 0;
        }
      }
    }
  }

  // responsive css
  @media screen and (max-width: 1660px) {
    .more-btn{
      .btn{
        &:nth-child(2){
          border-radius: 15px;
        }
      }
    }
    // video chat page
    .caller-img {
      left: unset;
      right: 15px;
    }
  }
  @media screen and (max-width: 1660px) and (min-width: 1441px){

    .ecommerce-chart-card{
      .small-bar{
        .flot-chart-container{
          svg{
            right: -20px;
          }
        }
      }
      .ecommerce-chart{
        .sale-chart{
          .media-body{
            margin-left: unset !important;
            margin-right: 14px !important;
          }
        }
      }
    }   
  }
  @media only screen and (max-width: 1366px) {
    .comment-box{
      ul{
        ul.comment-social.float-start{
          float: right !important;
        }
      }
    }
    .m-r-30{
      margin-left: 20px;
      margin-right: unset;
    }
    .sale-chart {
      left: 60px;
      right: unset;
    }
    .product-wrapper {
      .product-grid {
        .feature-products {
          margin-right: 0;
        }
      }
    }
    .product-grid {
      margin-right: 0;
    }
    .d-none-productlist {
      margin-left: 10px;
      margin-right: unset;
    }
    .product-sidebar {
      transform: translateX(300px) scaleX(0);
    }
    .photos {
      ul {
        li {
          &:nth-child(3n) {
            margin-left: 15px;
            margin-right: unset;
          }
        }
      }
    }
    // chat page
    .chat-left-aside {
      .status-circle {
        left: unset;
        right: 22px;
      }
    }
  }
  @media only screen and (max-width: 1366px) and (min-width: 1200px){
    .blog-box {
      .blog-details-main{
        .blog-social{
          li{
            &:first-child{
              padding-left: 10px;
              padding-right: 0;
            }
            &:last-child{
              padding-left: 0;
              padding-right: 10px;
            }
          }
        }
      }
      .blog-details {
        .blog-social {
          li {
            &:first-child {
              padding-right: unset;
              padding-left: 22px;
            }
            + li {
              padding-left: unset;
              padding-right: 22px;
              margin-right: 0;
            }
          }
        }
      }
    }
    .m-checkbox-inline{
      .radio{
        &:last-child{
          margin-right: 0;
          margin-left: unset;
        }
      }
      .checkbox{
        &:last-child{
          margin-right: 0;
          margin-left: unset;
        }
      }
    } 
  }
  @media (min-width: 1200px) {
    // chat page
    .chat-box {
      .pe-xl-0,
      .px-xl-0 {
        padding-left: 0 !important;
        padding-right: 15px !important;
      }
      .ps-xl-0,
      .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 15px !important;
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .browser-widget.b-r-light{
      border-left: unset !important;
    }
    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 45px;
          }
        }
      }
    }
    .email-wrap {
      .row {
        .col-xl-6 {
          padding-right: 15px;
        }
        .col-xl-3 {
          + .col-xl-3 {
            padding-left: 15px;
          }
        }
      }
      .email-app-sidebar {
        .main-menu {
          & > li {
            text-align: right;
            a {
              i {
                margin-right: unset;
                margin-left: 10px;
              }
            }
          }
        }
        ul {
          padding-right: 0;
        }
      }
      .email-content {
        .email-top {
          .user-emailid {
            &:after {
              float: left;
            }
          }
        }
      }
      .email-right-aside {
        .email-body {
          .pe-0 {
            padding-right: unset !important;
          }
        }
        .radius-left {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-radius: 4px;
        }
      }
    }
    .chat-menu-icons {
      text-align: left;
    }
    .chat-box {
      .chat-history {
        .row {
          .col-sm-7 {
            padding-left: 0 !important;
            padding-right: unset !important;
          }
        }
      }
    }
    .chat-menu {
      left: 0;
      right: unset;
    }
  }
  @media only screen and (min-width: 1170px){
    .vertical-timeline--two-columns{
      .vertical-timeline-element-content{
        .vertical-timeline-element-date{
          text-align: left;
        }
      }
    }  
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px){
    .sale-chart{
      left: 40px;
    }
    .ecommerce-small-chart{
      .small-bar{
        .flot-chart-container{
          svg{
            right: 0;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px){
    .m-checkbox-inline{
      .radio{
        &:last-child{
          margin-right: 0;
          margin-left: unset;
        }
      }
      .checkbox{
        &:last-child{
          margin-right: 0;
          margin-left: unset;
        }
      }
    } 
  }
  @media only screen and (max-width: 991px) {
    .vertical-timeline--two-columns{
      .vertical-timeline-element-content{
        text-align: left;
      }
    }
    .modal{
      .modal-dialog{
        .modal-header{
          button.btn-close{
            span{
              left: 18px;
              right: unset;
            }
          }
        }
      }
    }
    .modal-dialog.modal-lg{
      .modal-header{
        .modal-title{
          button.btn-close{
            left: 18px;
            right: unset;
          }
        }
      }
    } 
    .general-widget{
      .table{
        tr{
          th, td{
            &:first-child{
              padding-right: 30px;
            }
            &:last-child{
              padding-left: 30px;
            }
          }
        }
      }
    }
    .page-wrapper{
      .page-main-header{
        .main-header-left{
          padding: 20px 20px 20px 0;
          margin-right: 0;
        }
      }
      .page-body-wrapper{
        .page-header{
          .row{
            .breadcrumb-right{
              .breadcrumb{
                .breadcrumb-item{
                  a{
                    padding: 4px 5px 2px 6px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .page-wrapper{
      .page-main-header{
        .main-header-right{
          .nav-right{
            > ul{
              > li{
                padding: 10px 20px 11px 20px !important;
              }
            }
          }
        }
      }
    }
    .sale-chart {
      left: 40px;
    }
    .faq-accordion {
      .faq-header {
        svg {
          left: 20px;
        }
      }
    }
    .categories {
      .learning-header {
        padding-right: 20px;
      }
    }
    .position-set {
      left: 30px;
    }
    .page-wrapper {
      .page-main-header {
        .main-header-left {
          .logo-wrapper {
            img {
              margin-left: 15px;
              margin-right: unset;
            }
          }
        }
      }
    }
    .cd-timeline-img {
      i {
        right: 50% !important;
      }
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-header {
          .breadcrumb {
            float: right;
            margin-right: 0;
          }
        }
        .page-body {
          min-height: calc(100vh - 60px);
        }
      }
      .page-main-header {
        margin-right: 0;
      }
    }
    .card-body {
      .value-left {
        margin-left: 20px;
      }
    }
    .card {
      .card-header {
        .card-header-right {
          left: 20px;
        }
        ul {
          &.creative-dots {
            li {
              &.big-dot {
                left: unset;
                right: -25px;
              }
              &.semi-medium-dot {
                left: unset;
                right: 14px;
              }
              &.semi-small-dot {
                left: unset;
                right: 26px;
              }
            }
          }
        }
      }
    }
    // index page
    .monthly-overview,
    .yearly {
      .card-header {
        .right-badge {
          left: 30px;
        }
      }
    }
    // sidebar css
    .page-wrapper {
      .page-body-wrapper {
        .page-body,
        footer {
          margin-left: unset !important;
          margin-right: 0 !important;
        }
        .page-header {
          .row {
            .breadcrumb-right {
              .breadcrumb {
                float: right;
              }
            }
          }
        }
        .iconsidebar-menu {
          .iconMenu-bar {
            height: calc(100vh - 65px);
          }
        }
      }
    }
    .page-main-header {
      .main-header-right {
        .nav-right {
          .notification-dropdown {
            left: -125px !important;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .float-md-end {
      float: left !important;
    }
    .text-md-end {
      text-align: left !important;
    }
    .offset-md-3 {
      margin-right: 25%;
      margin-left: unset;
    }
    .text-md-start {
      text-align: right !important;
    }
    .ecommerce-widget {
      .text-md-end {
        text-align: left !important;
        ul {
          text-align: left;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .blog-single{
      .comment-box{
        .media{
          img{
            margin-left: 30px;
            margin-right: unset;
          }
        }
        .comment-social{
          margin-right: 0;
          li{
            &:first-child{
              padding-left: 15px;
              padding-right: 0;
            }
            + li{
              padding-right: 15px;
              padding-left: unset;
            }
          }
        }
      }
    } 
    .text-md-end{
      text-align: left;
    }
    .dz-message{
      .dzu-dropzone{
        .dzu-inputLabelWithFiles{
          margin-right: 0 !important;
          margin-left: unset !important;
        }
      }
    }
    .cal-date-widget{
      .cal-info{
        padding-right: 0;
        padding-left: unset;
      }
      .row{
        > div.ps-0{
          padding-right: 12px !important;
        }
      }
    }  
    .footer{
      .pull-right{
        float: none;
      }
    } 
    .page-wrapper{
      .page-main-header{
        .main-header-right{
          .nav-right{
            > ul{
              > li{
                padding: 8px 10px 10px 10px !important;
              }
            }
          }
        }
      }
    }
    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 25px !important;
          }
        }
      }
    }
    .ecommerce-widget {
      .text-md-end {
        text-align: left !important;
      }
    }
    .bar-chart-widget {
      .earning-details {
        i {
          left: -147px !important;
        }
      }
    }
    // main header
    .page-wrapper {
      .page-main-header {
        .main-header-right {
          .nav-right {
            .droplet-dropdown {
              left: -192px !important;
              &:before,
              &:after {
                right: 162px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 576px) {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }
    .text-sm-end {
      text-align: left !important;
    }
    .float-sm-end {
      float: left !important;
    }
    .me-sm-2,
    .mx-sm-2 {
      margin-left: 0.5rem !important;
      margin-right: unset !important;
    }
  }
  @media only screen and (max-width: 575px) {
    .default-chartist-container.ct-10, .default-chartist-container.ct-11{
      width: 46%;
    }
    .m-r-30{
      margin-left: 15px;
      margin-right: unset;
    }
    .theme-form{
      .form-group{
        .radio, .m-checkbox-inline{
          &.ms-4{
            margin-left: unset !important;
            margin-right: 8px !important;
          }
        }
      }
    }
    .job-search{
      .media{
        .m-r-20{
          margin-right: unset;
          margin-left: 0;
        }
      }
    }
    .crypto-revenue{
      .card-header{
        .text-end{
          text-align: right !important;
        }
      }
    }
    .weather-bg{
      .weather-details{
        .weather-title{
          padding-right: unset;
          padding-left: 25px;
        }
      }
    }  
    .btc-overview{
      .card-header{
        .text-end{
          text-align: right !important;
        }
      }
    }
    .comment-box{
      ul{
        ul{
          margin-right: 30px;
          margin-left: unset;
        }
      }
    }
    .border-tab-primary.nav-left{
      .nav-link{
        text-align: center;
      }
    } 
    .btc-table{
      .btc-sell{
        .btn{
          &:nth-child(2){
            margin-left: -40px;
            margin-right: -40px;
          }
        }
      }
    }
    .mega-horizontal{
      .offset-sm-3{
        margin-right: unset;
      }
    } 
    .sticky-header-main{
      .card-header{
        .btn.pull-right{
          float: right;
          margin-right: 0 !important;
          margin-left: unset !important;
        }
      }
    }
    .rating{
      .text-end{
        text-align: right !important;
      }
    } 
    .btc-table-xs-l{
      padding-right: 11px;
      padding-left: 12px;
    }
    .page-main-header{
      .main-header-right{
        .nav-right{
          > ul{
            > li.onhover-dropdown{
              .profile-dropdown{
                left: unset !important;
                right: -93px !important;
              }
              .notification-dropdown{
                left: unset !important;
                right: -108px !important;
              }
            }
          }
        }
      }
    }
    .page-wrapper{
      .page-main-header{
        .main-header-right{
          .nav-right{
            > ul{
              > li{
                padding: 0 8px !important;
              }
            }
          }
        }
      }
    } 
    .crypto-revenue {
      .card-header {
        h5 {
          .badge {
            margin-right: 5px;
          }
        }
      }
    }
    .sale-chart {
      left: 32px;
    }
    .faq-accordion {
      .faq-header {
        svg {
          left: 15px;
        }
      }
    }
    .position-set {
      left: 25px;
    }
    .categories {
      .learning-header {
        padding-right: 15px;
      }
    }
    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 15px !important;
            li {
              margin-left: 0px;
            }
          }
        }
      }
    }
    .page-wrapper {
      .search-form {
        .form-group {
          margin-left: 0;
        }
      }
    }
    .card-body {
      .value-left {
        margin-left: 15px;
      }
    }
    .card {
      .card-header {
        ul {
          &.creative-dots {
            li {
              &.big-dot {
                left: unset;
                right: -16px;
              }
              &.semi-big-dot {
                left: unset;
                right: -12px;
              }
              &.semi-medium-dot {
                left: unset;
                right: 12px;
              }
              &.semi-small-dot {
                left: unset;
                right: 22px;
              }
            }
          }
        }
      }
    }
    // main header
    .page-wrapper {
      .page-main-header {
        .main-header-right {
          .nav-right {
            > ul {
              .search-form {
                .form-control-plaintext {
                  left: unset;
                  right: -10px;
                }
              }
            }
            .onhover-dropdown {
              .bookmark-flip {
                left: unset;
                right: 96px;
              }
            }
            .droplet-dropdown {
              right: -91px !important;
              &:before,
              &:after {
                right: 100px;
              }
            }
          }
        }
      }
    }
    // index page
    .monthly-overview,
    .yearly {
      .card-header {
        .right-badge {
          left: 25px;
        }
      }
    }
    .year-overview {
      .card-header {
        .text-end {
          text-align: right !important;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .mega-vertical{
      .media{
        margin-right: 8px;
        margin-left: unset;
      }
    }
    .mega-horizontal, .mega-vertical, .mega-inline{
      .p-20.media{
        .radio.me-3{
          margin-left: 8px !important;
          margin-right: unset !important;
        }
      }
    }
    .icon-hover-bottom{
      .icon-first{
        margin-right: unset;
        margin-left: 10px;
      }
    } 
    .crm-activity{
      ul.dates{
        li{
          + li{
            padding-right: 8px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 420px) {
    .m-checkbox-inline{
      label{
        margin-left: 9px;
        margin-right: unset;
      }
      .radio{
        &:last-child{
          margin-right: 0;
          margin-left: unset;
        }
      }
      .checkbox{
        &:last-child{
          margin-right: 0;
          margin-left: unset;
        }
      }
    } 
    .customizer-links {
      &.open {
        left: 252px;
        right: unset;
      }
    }
  }
  @media only screen and (max-width: 360px) {
    .add-post{
      .btn-showcase{
        .btn{
          margin-left: 10px;
          margin-right: unset;
          &:last-child{
            margin-right: unset;
            margin-left: 0;
          }
        }
      }
    }
    .blog-box{
      .blog-details{
        .blog-social{
          li{
            &:first-child{
              padding-right: unset;
              padding-left: 6px;
            }
            + li{
              padding-left: unset;
              padding-right: 6px;
            }
          }
        }
      }
    }
    .action-text{
      .alert-dismissible{
        .btn-close{
          left: 20px;
          right: unset;
        }
      }
    } 
    .page-main-header{
      .main-header-right{
        .nav-right{
          .droplet-dropdown{
            right: -102px !important;
          }
          > ul{
            > li.onhover-dropdown{
              .notification-dropdown{
                right: -130px !important;
              }
              .profile-dropdown{
                right: -112px !important;
                left: unset !important;
              }
            }
          }
        }
      }
    }
    .page-wrapper{
      .page-main-header{
        .main-header-right{
          .nav-right{
            .droplet-dropdown{
              left: unset;
              width: 280px;
            }
          }
        }
      }
    }    
    .social-app-profile {
      .hovercard {
        .user-image {
          .avatar {
            img {
              width: 85px;
              height: 85px;
            }
            margin-top: -47px;
          }
        }
      }
    }
    .social-chat {
      .other-msg {
        margin-right: 15px;
      }
    }
  }
  @media only screen and (max-width: 340px){
    .position-set{
      left: 12px;
    }
  }
}

/**=====================
    52. RTL CSS Ends
==========================**/
