// Ionicons Icons
// --------------------------

.ionicons,
.#{$ionicons-prefix}alert:before,
.#{$ionicons-prefix}alert-circled:before,
.#{$ionicons-prefix}android-add:before,
.#{$ionicons-prefix}android-add-circle:before,
.#{$ionicons-prefix}android-alarm-clock:before,
.#{$ionicons-prefix}android-alert:before,
.#{$ionicons-prefix}android-apps:before,
.#{$ionicons-prefix}android-archive:before,
.#{$ionicons-prefix}android-arrow-back:before,
.#{$ionicons-prefix}android-arrow-down:before,
.#{$ionicons-prefix}android-arrow-dropdown:before,
.#{$ionicons-prefix}android-arrow-dropdown-circle:before,
.#{$ionicons-prefix}android-arrow-dropleft:before,
.#{$ionicons-prefix}android-arrow-dropleft-circle:before,
.#{$ionicons-prefix}android-arrow-dropright:before,
.#{$ionicons-prefix}android-arrow-dropright-circle:before,
.#{$ionicons-prefix}android-arrow-dropup:before,
.#{$ionicons-prefix}android-arrow-dropup-circle:before,
.#{$ionicons-prefix}android-arrow-forward:before,
.#{$ionicons-prefix}android-arrow-up:before,
.#{$ionicons-prefix}android-attach:before,
.#{$ionicons-prefix}android-bar:before,
.#{$ionicons-prefix}android-bicycle:before,
.#{$ionicons-prefix}android-boat:before,
.#{$ionicons-prefix}android-bookmark:before,
.#{$ionicons-prefix}android-bulb:before,
.#{$ionicons-prefix}android-bus:before,
.#{$ionicons-prefix}android-calendar:before,
.#{$ionicons-prefix}android-call:before,
.#{$ionicons-prefix}android-camera:before,
.#{$ionicons-prefix}android-cancel:before,
.#{$ionicons-prefix}android-car:before,
.#{$ionicons-prefix}android-cart:before,
.#{$ionicons-prefix}android-chat:before,
.#{$ionicons-prefix}android-checkbox:before,
.#{$ionicons-prefix}android-checkbox-blank:before,
.#{$ionicons-prefix}android-checkbox-outline:before,
.#{$ionicons-prefix}android-checkbox-outline-blank:before,
.#{$ionicons-prefix}android-checkmark-circle:before,
.#{$ionicons-prefix}android-clipboard:before,
.#{$ionicons-prefix}android-close:before,
.#{$ionicons-prefix}android-cloud:before,
.#{$ionicons-prefix}android-cloud-circle:before,
.#{$ionicons-prefix}android-cloud-done:before,
.#{$ionicons-prefix}android-cloud-outline:before,
.#{$ionicons-prefix}android-color-palette:before,
.#{$ionicons-prefix}android-compass:before,
.#{$ionicons-prefix}android-contact:before,
.#{$ionicons-prefix}android-contacts:before,
.#{$ionicons-prefix}android-contract:before,
.#{$ionicons-prefix}android-create:before,
.#{$ionicons-prefix}android-delete:before,
.#{$ionicons-prefix}android-desktop:before,
.#{$ionicons-prefix}android-document:before,
.#{$ionicons-prefix}android-done:before,
.#{$ionicons-prefix}android-done-all:before,
.#{$ionicons-prefix}android-download:before,
.#{$ionicons-prefix}android-drafts:before,
.#{$ionicons-prefix}android-exit:before,
.#{$ionicons-prefix}android-expand:before,
.#{$ionicons-prefix}android-favorite:before,
.#{$ionicons-prefix}android-favorite-outline:before,
.#{$ionicons-prefix}android-film:before,
.#{$ionicons-prefix}android-folder:before,
.#{$ionicons-prefix}android-folder-open:before,
.#{$ionicons-prefix}android-funnel:before,
.#{$ionicons-prefix}android-globe:before,
.#{$ionicons-prefix}android-hand:before,
.#{$ionicons-prefix}android-hangout:before,
.#{$ionicons-prefix}android-happy:before,
.#{$ionicons-prefix}android-home:before,
.#{$ionicons-prefix}android-image:before,
.#{$ionicons-prefix}android-laptop:before,
.#{$ionicons-prefix}android-list:before,
.#{$ionicons-prefix}android-locate:before,
.#{$ionicons-prefix}android-lock:before,
.#{$ionicons-prefix}android-mail:before,
.#{$ionicons-prefix}android-map:before,
.#{$ionicons-prefix}android-menu:before,
.#{$ionicons-prefix}android-microphone:before,
.#{$ionicons-prefix}android-microphone-off:before,
.#{$ionicons-prefix}android-more-horizontal:before,
.#{$ionicons-prefix}android-more-vertical:before,
.#{$ionicons-prefix}android-navigate:before,
.#{$ionicons-prefix}android-notifications:before,
.#{$ionicons-prefix}android-notifications-none:before,
.#{$ionicons-prefix}android-notifications-off:before,
.#{$ionicons-prefix}android-open:before,
.#{$ionicons-prefix}android-options:before,
.#{$ionicons-prefix}android-people:before,
.#{$ionicons-prefix}android-person:before,
.#{$ionicons-prefix}android-person-add:before,
.#{$ionicons-prefix}android-phone-landscape:before,
.#{$ionicons-prefix}android-phone-portrait:before,
.#{$ionicons-prefix}android-pin:before,
.#{$ionicons-prefix}android-plane:before,
.#{$ionicons-prefix}android-playstore:before,
.#{$ionicons-prefix}android-print:before,
.#{$ionicons-prefix}android-radio-button-off:before,
.#{$ionicons-prefix}android-radio-button-on:before,
.#{$ionicons-prefix}android-refresh:before,
.#{$ionicons-prefix}android-remove:before,
.#{$ionicons-prefix}android-remove-circle:before,
.#{$ionicons-prefix}android-restaurant:before,
.#{$ionicons-prefix}android-sad:before,
.#{$ionicons-prefix}android-search:before,
.#{$ionicons-prefix}android-send:before,
.#{$ionicons-prefix}android-settings:before,
.#{$ionicons-prefix}android-share:before,
.#{$ionicons-prefix}android-share-alt:before,
.#{$ionicons-prefix}android-star:before,
.#{$ionicons-prefix}android-star-half:before,
.#{$ionicons-prefix}android-star-outline:before,
.#{$ionicons-prefix}android-stopwatch:before,
.#{$ionicons-prefix}android-subway:before,
.#{$ionicons-prefix}android-sunny:before,
.#{$ionicons-prefix}android-sync:before,
.#{$ionicons-prefix}android-textsms:before,
.#{$ionicons-prefix}android-time:before,
.#{$ionicons-prefix}android-train:before,
.#{$ionicons-prefix}android-unlock:before,
.#{$ionicons-prefix}android-upload:before,
.#{$ionicons-prefix}android-volume-down:before,
.#{$ionicons-prefix}android-volume-mute:before,
.#{$ionicons-prefix}android-volume-off:before,
.#{$ionicons-prefix}android-volume-up:before,
.#{$ionicons-prefix}android-walk:before,
.#{$ionicons-prefix}android-warning:before,
.#{$ionicons-prefix}android-watch:before,
.#{$ionicons-prefix}android-wifi:before,
.#{$ionicons-prefix}aperture:before,
.#{$ionicons-prefix}archive:before,
.#{$ionicons-prefix}arrow-down-a:before,
.#{$ionicons-prefix}arrow-down-b:before,
.#{$ionicons-prefix}arrow-down-c:before,
.#{$ionicons-prefix}arrow-expand:before,
.#{$ionicons-prefix}arrow-graph-down-left:before,
.#{$ionicons-prefix}arrow-graph-down-right:before,
.#{$ionicons-prefix}arrow-graph-up-left:before,
.#{$ionicons-prefix}arrow-graph-up-right:before,
.#{$ionicons-prefix}arrow-left-a:before,
.#{$ionicons-prefix}arrow-left-b:before,
.#{$ionicons-prefix}arrow-left-c:before,
.#{$ionicons-prefix}arrow-move:before,
.#{$ionicons-prefix}arrow-resize:before,
.#{$ionicons-prefix}arrow-return-left:before,
.#{$ionicons-prefix}arrow-return-right:before,
.#{$ionicons-prefix}arrow-right-a:before,
.#{$ionicons-prefix}arrow-right-b:before,
.#{$ionicons-prefix}arrow-right-c:before,
.#{$ionicons-prefix}arrow-shrink:before,
.#{$ionicons-prefix}arrow-swap:before,
.#{$ionicons-prefix}arrow-up-a:before,
.#{$ionicons-prefix}arrow-up-b:before,
.#{$ionicons-prefix}arrow-up-c:before,
.#{$ionicons-prefix}asterisk:before,
.#{$ionicons-prefix}at:before,
.#{$ionicons-prefix}backspace:before,
.#{$ionicons-prefix}backspace-outline:before,
.#{$ionicons-prefix}bag:before,
.#{$ionicons-prefix}battery-charging:before,
.#{$ionicons-prefix}battery-empty:before,
.#{$ionicons-prefix}battery-full:before,
.#{$ionicons-prefix}battery-half:before,
.#{$ionicons-prefix}battery-low:before,
.#{$ionicons-prefix}beaker:before,
.#{$ionicons-prefix}beer:before,
.#{$ionicons-prefix}bluetooth:before,
.#{$ionicons-prefix}bonfire:before,
.#{$ionicons-prefix}bookmark:before,
.#{$ionicons-prefix}bowtie:before,
.#{$ionicons-prefix}briefcase:before,
.#{$ionicons-prefix}bug:before,
.#{$ionicons-prefix}calculator:before,
.#{$ionicons-prefix}calendar:before,
.#{$ionicons-prefix}camera:before,
.#{$ionicons-prefix}card:before,
.#{$ionicons-prefix}cash:before,
.#{$ionicons-prefix}chatbox:before,
.#{$ionicons-prefix}chatbox-working:before,
.#{$ionicons-prefix}chatboxes:before,
.#{$ionicons-prefix}chatbubble:before,
.#{$ionicons-prefix}chatbubble-working:before,
.#{$ionicons-prefix}chatbubbles:before,
.#{$ionicons-prefix}checkmark:before,
.#{$ionicons-prefix}checkmark-circled:before,
.#{$ionicons-prefix}checkmark-round:before,
.#{$ionicons-prefix}chevron-down:before,
.#{$ionicons-prefix}chevron-left:before,
.#{$ionicons-prefix}chevron-right:before,
.#{$ionicons-prefix}chevron-up:before,
.#{$ionicons-prefix}clipboard:before,
.#{$ionicons-prefix}clock:before,
.#{$ionicons-prefix}close:before,
.#{$ionicons-prefix}close-circled:before,
.#{$ionicons-prefix}close-round:before,
.#{$ionicons-prefix}closed-captioning:before,
.#{$ionicons-prefix}cloud:before,
.#{$ionicons-prefix}code:before,
.#{$ionicons-prefix}code-download:before,
.#{$ionicons-prefix}code-working:before,
.#{$ionicons-prefix}coffee:before,
.#{$ionicons-prefix}compass:before,
.#{$ionicons-prefix}compose:before,
.#{$ionicons-prefix}connection-bars:before,
.#{$ionicons-prefix}contrast:before,
.#{$ionicons-prefix}crop:before,
.#{$ionicons-prefix}cube:before,
.#{$ionicons-prefix}disc:before,
.#{$ionicons-prefix}document:before,
.#{$ionicons-prefix}document-text:before,
.#{$ionicons-prefix}drag:before,
.#{$ionicons-prefix}earth:before,
.#{$ionicons-prefix}easel:before,
.#{$ionicons-prefix}edit:before,
.#{$ionicons-prefix}egg:before,
.#{$ionicons-prefix}eject:before,
.#{$ionicons-prefix}email:before,
.#{$ionicons-prefix}email-unread:before,
.#{$ionicons-prefix}erlenmeyer-flask:before,
.#{$ionicons-prefix}erlenmeyer-flask-bubbles:before,
.#{$ionicons-prefix}eye:before,
.#{$ionicons-prefix}eye-disabled:before,
.#{$ionicons-prefix}female:before,
.#{$ionicons-prefix}filing:before,
.#{$ionicons-prefix}film-marker:before,
.#{$ionicons-prefix}fireball:before,
.#{$ionicons-prefix}flag:before,
.#{$ionicons-prefix}flame:before,
.#{$ionicons-prefix}flash:before,
.#{$ionicons-prefix}flash-off:before,
.#{$ionicons-prefix}folder:before,
.#{$ionicons-prefix}fork:before,
.#{$ionicons-prefix}fork-repo:before,
.#{$ionicons-prefix}forward:before,
.#{$ionicons-prefix}funnel:before,
.#{$ionicons-prefix}gear-a:before,
.#{$ionicons-prefix}gear-b:before,
.#{$ionicons-prefix}grid:before,
.#{$ionicons-prefix}hammer:before,
.#{$ionicons-prefix}happy:before,
.#{$ionicons-prefix}happy-outline:before,
.#{$ionicons-prefix}headphone:before,
.#{$ionicons-prefix}heart:before,
.#{$ionicons-prefix}heart-broken:before,
.#{$ionicons-prefix}help:before,
.#{$ionicons-prefix}help-buoy:before,
.#{$ionicons-prefix}help-circled:before,
.#{$ionicons-prefix}home:before,
.#{$ionicons-prefix}icecream:before,
.#{$ionicons-prefix}image:before,
.#{$ionicons-prefix}images:before,
.#{$ionicons-prefix}information:before,
.#{$ionicons-prefix}information-circled:before,
.#{$ionicons-prefix}ionic:before,
.#{$ionicons-prefix}ios-alarm:before,
.#{$ionicons-prefix}ios-alarm-outline:before,
.#{$ionicons-prefix}ios-albums:before,
.#{$ionicons-prefix}ios-albums-outline:before,
.#{$ionicons-prefix}ios-americanfootball:before,
.#{$ionicons-prefix}ios-americanfootball-outline:before,
.#{$ionicons-prefix}ios-analytics:before,
.#{$ionicons-prefix}ios-analytics-outline:before,
.#{$ionicons-prefix}ios-arrow-back:before,
.#{$ionicons-prefix}ios-arrow-down:before,
.#{$ionicons-prefix}ios-arrow-forward:before,
.#{$ionicons-prefix}ios-arrow-left:before,
.#{$ionicons-prefix}ios-arrow-right:before,
.#{$ionicons-prefix}ios-arrow-thin-down:before,
.#{$ionicons-prefix}ios-arrow-thin-left:before,
.#{$ionicons-prefix}ios-arrow-thin-right:before,
.#{$ionicons-prefix}ios-arrow-thin-up:before,
.#{$ionicons-prefix}ios-arrow-up:before,
.#{$ionicons-prefix}ios-at:before,
.#{$ionicons-prefix}ios-at-outline:before,
.#{$ionicons-prefix}ios-barcode:before,
.#{$ionicons-prefix}ios-barcode-outline:before,
.#{$ionicons-prefix}ios-baseball:before,
.#{$ionicons-prefix}ios-baseball-outline:before,
.#{$ionicons-prefix}ios-basketball:before,
.#{$ionicons-prefix}ios-basketball-outline:before,
.#{$ionicons-prefix}ios-bell:before,
.#{$ionicons-prefix}ios-bell-outline:before,
.#{$ionicons-prefix}ios-body:before,
.#{$ionicons-prefix}ios-body-outline:before,
.#{$ionicons-prefix}ios-bolt:before,
.#{$ionicons-prefix}ios-bolt-outline:before,
.#{$ionicons-prefix}ios-book:before,
.#{$ionicons-prefix}ios-book-outline:before,
.#{$ionicons-prefix}ios-bookmarks:before,
.#{$ionicons-prefix}ios-bookmarks-outline:before,
.#{$ionicons-prefix}ios-box:before,
.#{$ionicons-prefix}ios-box-outline:before,
.#{$ionicons-prefix}ios-briefcase:before,
.#{$ionicons-prefix}ios-briefcase-outline:before,
.#{$ionicons-prefix}ios-browsers:before,
.#{$ionicons-prefix}ios-browsers-outline:before,
.#{$ionicons-prefix}ios-calculator:before,
.#{$ionicons-prefix}ios-calculator-outline:before,
.#{$ionicons-prefix}ios-calendar:before,
.#{$ionicons-prefix}ios-calendar-outline:before,
.#{$ionicons-prefix}ios-camera:before,
.#{$ionicons-prefix}ios-camera-outline:before,
.#{$ionicons-prefix}ios-cart:before,
.#{$ionicons-prefix}ios-cart-outline:before,
.#{$ionicons-prefix}ios-chatboxes:before,
.#{$ionicons-prefix}ios-chatboxes-outline:before,
.#{$ionicons-prefix}ios-chatbubble:before,
.#{$ionicons-prefix}ios-chatbubble-outline:before,
.#{$ionicons-prefix}ios-checkmark:before,
.#{$ionicons-prefix}ios-checkmark-empty:before,
.#{$ionicons-prefix}ios-checkmark-outline:before,
.#{$ionicons-prefix}ios-circle-filled:before,
.#{$ionicons-prefix}ios-circle-outline:before,
.#{$ionicons-prefix}ios-clock:before,
.#{$ionicons-prefix}ios-clock-outline:before,
.#{$ionicons-prefix}ios-close:before,
.#{$ionicons-prefix}ios-close-empty:before,
.#{$ionicons-prefix}ios-close-outline:before,
.#{$ionicons-prefix}ios-cloud:before,
.#{$ionicons-prefix}ios-cloud-download:before,
.#{$ionicons-prefix}ios-cloud-download-outline:before,
.#{$ionicons-prefix}ios-cloud-outline:before,
.#{$ionicons-prefix}ios-cloud-upload:before,
.#{$ionicons-prefix}ios-cloud-upload-outline:before,
.#{$ionicons-prefix}ios-cloudy:before,
.#{$ionicons-prefix}ios-cloudy-night:before,
.#{$ionicons-prefix}ios-cloudy-night-outline:before,
.#{$ionicons-prefix}ios-cloudy-outline:before,
.#{$ionicons-prefix}ios-cog:before,
.#{$ionicons-prefix}ios-cog-outline:before,
.#{$ionicons-prefix}ios-color-filter:before,
.#{$ionicons-prefix}ios-color-filter-outline:before,
.#{$ionicons-prefix}ios-color-wand:before,
.#{$ionicons-prefix}ios-color-wand-outline:before,
.#{$ionicons-prefix}ios-compose:before,
.#{$ionicons-prefix}ios-compose-outline:before,
.#{$ionicons-prefix}ios-contact:before,
.#{$ionicons-prefix}ios-contact-outline:before,
.#{$ionicons-prefix}ios-copy:before,
.#{$ionicons-prefix}ios-copy-outline:before,
.#{$ionicons-prefix}ios-crop:before,
.#{$ionicons-prefix}ios-crop-strong:before,
.#{$ionicons-prefix}ios-download:before,
.#{$ionicons-prefix}ios-download-outline:before,
.#{$ionicons-prefix}ios-drag:before,
.#{$ionicons-prefix}ios-email:before,
.#{$ionicons-prefix}ios-email-outline:before,
.#{$ionicons-prefix}ios-eye:before,
.#{$ionicons-prefix}ios-eye-outline:before,
.#{$ionicons-prefix}ios-fastforward:before,
.#{$ionicons-prefix}ios-fastforward-outline:before,
.#{$ionicons-prefix}ios-filing:before,
.#{$ionicons-prefix}ios-filing-outline:before,
.#{$ionicons-prefix}ios-film:before,
.#{$ionicons-prefix}ios-film-outline:before,
.#{$ionicons-prefix}ios-flag:before,
.#{$ionicons-prefix}ios-flag-outline:before,
.#{$ionicons-prefix}ios-flame:before,
.#{$ionicons-prefix}ios-flame-outline:before,
.#{$ionicons-prefix}ios-flask:before,
.#{$ionicons-prefix}ios-flask-outline:before,
.#{$ionicons-prefix}ios-flower:before,
.#{$ionicons-prefix}ios-flower-outline:before,
.#{$ionicons-prefix}ios-folder:before,
.#{$ionicons-prefix}ios-folder-outline:before,
.#{$ionicons-prefix}ios-football:before,
.#{$ionicons-prefix}ios-football-outline:before,
.#{$ionicons-prefix}ios-game-controller-a:before,
.#{$ionicons-prefix}ios-game-controller-a-outline:before,
.#{$ionicons-prefix}ios-game-controller-b:before,
.#{$ionicons-prefix}ios-game-controller-b-outline:before,
.#{$ionicons-prefix}ios-gear:before,
.#{$ionicons-prefix}ios-gear-outline:before,
.#{$ionicons-prefix}ios-glasses:before,
.#{$ionicons-prefix}ios-glasses-outline:before,
.#{$ionicons-prefix}ios-grid-view:before,
.#{$ionicons-prefix}ios-grid-view-outline:before,
.#{$ionicons-prefix}ios-heart:before,
.#{$ionicons-prefix}ios-heart-outline:before,
.#{$ionicons-prefix}ios-help:before,
.#{$ionicons-prefix}ios-help-empty:before,
.#{$ionicons-prefix}ios-help-outline:before,
.#{$ionicons-prefix}ios-home:before,
.#{$ionicons-prefix}ios-home-outline:before,
.#{$ionicons-prefix}ios-infinite:before,
.#{$ionicons-prefix}ios-infinite-outline:before,
.#{$ionicons-prefix}ios-information:before,
.#{$ionicons-prefix}ios-information-empty:before,
.#{$ionicons-prefix}ios-information-outline:before,
.#{$ionicons-prefix}ios-ionic-outline:before,
.#{$ionicons-prefix}ios-keypad:before,
.#{$ionicons-prefix}ios-keypad-outline:before,
.#{$ionicons-prefix}ios-lightbulb:before,
.#{$ionicons-prefix}ios-lightbulb-outline:before,
.#{$ionicons-prefix}ios-list:before,
.#{$ionicons-prefix}ios-list-outline:before,
.#{$ionicons-prefix}ios-location:before,
.#{$ionicons-prefix}ios-location-outline:before,
.#{$ionicons-prefix}ios-locked:before,
.#{$ionicons-prefix}ios-locked-outline:before,
.#{$ionicons-prefix}ios-loop:before,
.#{$ionicons-prefix}ios-loop-strong:before,
.#{$ionicons-prefix}ios-medical:before,
.#{$ionicons-prefix}ios-medical-outline:before,
.#{$ionicons-prefix}ios-medkit:before,
.#{$ionicons-prefix}ios-medkit-outline:before,
.#{$ionicons-prefix}ios-mic:before,
.#{$ionicons-prefix}ios-mic-off:before,
.#{$ionicons-prefix}ios-mic-outline:before,
.#{$ionicons-prefix}ios-minus:before,
.#{$ionicons-prefix}ios-minus-empty:before,
.#{$ionicons-prefix}ios-minus-outline:before,
.#{$ionicons-prefix}ios-monitor:before,
.#{$ionicons-prefix}ios-monitor-outline:before,
.#{$ionicons-prefix}ios-moon:before,
.#{$ionicons-prefix}ios-moon-outline:before,
.#{$ionicons-prefix}ios-more:before,
.#{$ionicons-prefix}ios-more-outline:before,
.#{$ionicons-prefix}ios-musical-note:before,
.#{$ionicons-prefix}ios-musical-notes:before,
.#{$ionicons-prefix}ios-navigate:before,
.#{$ionicons-prefix}ios-navigate-outline:before,
.#{$ionicons-prefix}ios-nutrition:before,
.#{$ionicons-prefix}ios-nutrition-outline:before,
.#{$ionicons-prefix}ios-paper:before,
.#{$ionicons-prefix}ios-paper-outline:before,
.#{$ionicons-prefix}ios-paperplane:before,
.#{$ionicons-prefix}ios-paperplane-outline:before,
.#{$ionicons-prefix}ios-partlysunny:before,
.#{$ionicons-prefix}ios-partlysunny-outline:before,
.#{$ionicons-prefix}ios-pause:before,
.#{$ionicons-prefix}ios-pause-outline:before,
.#{$ionicons-prefix}ios-paw:before,
.#{$ionicons-prefix}ios-paw-outline:before,
.#{$ionicons-prefix}ios-people:before,
.#{$ionicons-prefix}ios-people-outline:before,
.#{$ionicons-prefix}ios-person:before,
.#{$ionicons-prefix}ios-person-outline:before,
.#{$ionicons-prefix}ios-personadd:before,
.#{$ionicons-prefix}ios-personadd-outline:before,
.#{$ionicons-prefix}ios-photos:before,
.#{$ionicons-prefix}ios-photos-outline:before,
.#{$ionicons-prefix}ios-pie:before,
.#{$ionicons-prefix}ios-pie-outline:before,
.#{$ionicons-prefix}ios-pint:before,
.#{$ionicons-prefix}ios-pint-outline:before,
.#{$ionicons-prefix}ios-play:before,
.#{$ionicons-prefix}ios-play-outline:before,
.#{$ionicons-prefix}ios-plus:before,
.#{$ionicons-prefix}ios-plus-empty:before,
.#{$ionicons-prefix}ios-plus-outline:before,
.#{$ionicons-prefix}ios-pricetag:before,
.#{$ionicons-prefix}ios-pricetag-outline:before,
.#{$ionicons-prefix}ios-pricetags:before,
.#{$ionicons-prefix}ios-pricetags-outline:before,
.#{$ionicons-prefix}ios-printer:before,
.#{$ionicons-prefix}ios-printer-outline:before,
.#{$ionicons-prefix}ios-pulse:before,
.#{$ionicons-prefix}ios-pulse-strong:before,
.#{$ionicons-prefix}ios-rainy:before,
.#{$ionicons-prefix}ios-rainy-outline:before,
.#{$ionicons-prefix}ios-recording:before,
.#{$ionicons-prefix}ios-recording-outline:before,
.#{$ionicons-prefix}ios-redo:before,
.#{$ionicons-prefix}ios-redo-outline:before,
.#{$ionicons-prefix}ios-refresh:before,
.#{$ionicons-prefix}ios-refresh-empty:before,
.#{$ionicons-prefix}ios-refresh-outline:before,
.#{$ionicons-prefix}ios-reload:before,
.#{$ionicons-prefix}ios-reverse-camera:before,
.#{$ionicons-prefix}ios-reverse-camera-outline:before,
.#{$ionicons-prefix}ios-rewind:before,
.#{$ionicons-prefix}ios-rewind-outline:before,
.#{$ionicons-prefix}ios-rose:before,
.#{$ionicons-prefix}ios-rose-outline:before,
.#{$ionicons-prefix}ios-search:before,
.#{$ionicons-prefix}ios-search-strong:before,
.#{$ionicons-prefix}ios-settings:before,
.#{$ionicons-prefix}ios-settings-strong:before,
.#{$ionicons-prefix}ios-shuffle:before,
.#{$ionicons-prefix}ios-shuffle-strong:before,
.#{$ionicons-prefix}ios-skipbackward:before,
.#{$ionicons-prefix}ios-skipbackward-outline:before,
.#{$ionicons-prefix}ios-skipforward:before,
.#{$ionicons-prefix}ios-skipforward-outline:before,
.#{$ionicons-prefix}ios-snowy:before,
.#{$ionicons-prefix}ios-speedometer:before,
.#{$ionicons-prefix}ios-speedometer-outline:before,
.#{$ionicons-prefix}ios-star:before,
.#{$ionicons-prefix}ios-star-half:before,
.#{$ionicons-prefix}ios-star-outline:before,
.#{$ionicons-prefix}ios-stopwatch:before,
.#{$ionicons-prefix}ios-stopwatch-outline:before,
.#{$ionicons-prefix}ios-sunny:before,
.#{$ionicons-prefix}ios-sunny-outline:before,
.#{$ionicons-prefix}ios-telephone:before,
.#{$ionicons-prefix}ios-telephone-outline:before,
.#{$ionicons-prefix}ios-tennisball:before,
.#{$ionicons-prefix}ios-tennisball-outline:before,
.#{$ionicons-prefix}ios-thunderstorm:before,
.#{$ionicons-prefix}ios-thunderstorm-outline:before,
.#{$ionicons-prefix}ios-time:before,
.#{$ionicons-prefix}ios-time-outline:before,
.#{$ionicons-prefix}ios-timer:before,
.#{$ionicons-prefix}ios-timer-outline:before,
.#{$ionicons-prefix}ios-toggle:before,
.#{$ionicons-prefix}ios-toggle-outline:before,
.#{$ionicons-prefix}ios-trash:before,
.#{$ionicons-prefix}ios-trash-outline:before,
.#{$ionicons-prefix}ios-undo:before,
.#{$ionicons-prefix}ios-undo-outline:before,
.#{$ionicons-prefix}ios-unlocked:before,
.#{$ionicons-prefix}ios-unlocked-outline:before,
.#{$ionicons-prefix}ios-upload:before,
.#{$ionicons-prefix}ios-upload-outline:before,
.#{$ionicons-prefix}ios-videocam:before,
.#{$ionicons-prefix}ios-videocam-outline:before,
.#{$ionicons-prefix}ios-volume-high:before,
.#{$ionicons-prefix}ios-volume-low:before,
.#{$ionicons-prefix}ios-wineglass:before,
.#{$ionicons-prefix}ios-wineglass-outline:before,
.#{$ionicons-prefix}ios-world:before,
.#{$ionicons-prefix}ios-world-outline:before,
.#{$ionicons-prefix}ipad:before,
.#{$ionicons-prefix}iphone:before,
.#{$ionicons-prefix}ipod:before,
.#{$ionicons-prefix}jet:before,
.#{$ionicons-prefix}key:before,
.#{$ionicons-prefix}knife:before,
.#{$ionicons-prefix}laptop:before,
.#{$ionicons-prefix}leaf:before,
.#{$ionicons-prefix}levels:before,
.#{$ionicons-prefix}lightbulb:before,
.#{$ionicons-prefix}link:before,
.#{$ionicons-prefix}load-a:before,
.#{$ionicons-prefix}load-b:before,
.#{$ionicons-prefix}load-c:before,
.#{$ionicons-prefix}load-d:before,
.#{$ionicons-prefix}location:before,
.#{$ionicons-prefix}lock-combination:before,
.#{$ionicons-prefix}locked:before,
.#{$ionicons-prefix}log-in:before,
.#{$ionicons-prefix}log-out:before,
.#{$ionicons-prefix}loop:before,
.#{$ionicons-prefix}magnet:before,
.#{$ionicons-prefix}male:before,
.#{$ionicons-prefix}man:before,
.#{$ionicons-prefix}map:before,
.#{$ionicons-prefix}medkit:before,
.#{$ionicons-prefix}merge:before,
.#{$ionicons-prefix}mic-a:before,
.#{$ionicons-prefix}mic-b:before,
.#{$ionicons-prefix}mic-c:before,
.#{$ionicons-prefix}minus:before,
.#{$ionicons-prefix}minus-circled:before,
.#{$ionicons-prefix}minus-round:before,
.#{$ionicons-prefix}model-s:before,
.#{$ionicons-prefix}monitor:before,
.#{$ionicons-prefix}more:before,
.#{$ionicons-prefix}mouse:before,
.#{$ionicons-prefix}music-note:before,
.#{$ionicons-prefix}navicon:before,
.#{$ionicons-prefix}navicon-round:before,
.#{$ionicons-prefix}navigate:before,
.#{$ionicons-prefix}network:before,
.#{$ionicons-prefix}no-smoking:before,
.#{$ionicons-prefix}nuclear:before,
.#{$ionicons-prefix}outlet:before,
.#{$ionicons-prefix}paintbrush:before,
.#{$ionicons-prefix}paintbucket:before,
.#{$ionicons-prefix}paper-airplane:before,
.#{$ionicons-prefix}paperclip:before,
.#{$ionicons-prefix}pause:before,
.#{$ionicons-prefix}person:before,
.#{$ionicons-prefix}person-add:before,
.#{$ionicons-prefix}person-stalker:before,
.#{$ionicons-prefix}pie-graph:before,
.#{$ionicons-prefix}pin:before,
.#{$ionicons-prefix}pinpoint:before,
.#{$ionicons-prefix}pizza:before,
.#{$ionicons-prefix}plane:before,
.#{$ionicons-prefix}planet:before,
.#{$ionicons-prefix}play:before,
.#{$ionicons-prefix}playstation:before,
.#{$ionicons-prefix}plus:before,
.#{$ionicons-prefix}plus-circled:before,
.#{$ionicons-prefix}plus-round:before,
.#{$ionicons-prefix}podium:before,
.#{$ionicons-prefix}pound:before,
.#{$ionicons-prefix}power:before,
.#{$ionicons-prefix}pricetag:before,
.#{$ionicons-prefix}pricetags:before,
.#{$ionicons-prefix}printer:before,
.#{$ionicons-prefix}pull-request:before,
.#{$ionicons-prefix}qr-scanner:before,
.#{$ionicons-prefix}quote:before,
.#{$ionicons-prefix}radio-waves:before,
.#{$ionicons-prefix}record:before,
.#{$ionicons-prefix}refresh:before,
.#{$ionicons-prefix}reply:before,
.#{$ionicons-prefix}reply-all:before,
.#{$ionicons-prefix}ribbon-a:before,
.#{$ionicons-prefix}ribbon-b:before,
.#{$ionicons-prefix}sad:before,
.#{$ionicons-prefix}sad-outline:before,
.#{$ionicons-prefix}scissors:before,
.#{$ionicons-prefix}search:before,
.#{$ionicons-prefix}settings:before,
.#{$ionicons-prefix}share:before,
.#{$ionicons-prefix}shuffle:before,
.#{$ionicons-prefix}skip-backward:before,
.#{$ionicons-prefix}skip-forward:before,
.#{$ionicons-prefix}social-android:before,
.#{$ionicons-prefix}social-android-outline:before,
.#{$ionicons-prefix}social-angular:before,
.#{$ionicons-prefix}social-angular-outline:before,
.#{$ionicons-prefix}social-apple:before,
.#{$ionicons-prefix}social-apple-outline:before,
.#{$ionicons-prefix}social-bitcoin:before,
.#{$ionicons-prefix}social-bitcoin-outline:before,
.#{$ionicons-prefix}social-buffer:before,
.#{$ionicons-prefix}social-buffer-outline:before,
.#{$ionicons-prefix}social-chrome:before,
.#{$ionicons-prefix}social-chrome-outline:before,
.#{$ionicons-prefix}social-codepen:before,
.#{$ionicons-prefix}social-codepen-outline:before,
.#{$ionicons-prefix}social-css3:before,
.#{$ionicons-prefix}social-css3-outline:before,
.#{$ionicons-prefix}social-designernews:before,
.#{$ionicons-prefix}social-designernews-outline:before,
.#{$ionicons-prefix}social-dribbble:before,
.#{$ionicons-prefix}social-dribbble-outline:before,
.#{$ionicons-prefix}social-dropbox:before,
.#{$ionicons-prefix}social-dropbox-outline:before,
.#{$ionicons-prefix}social-euro:before,
.#{$ionicons-prefix}social-euro-outline:before,
.#{$ionicons-prefix}social-facebook:before,
.#{$ionicons-prefix}social-facebook-outline:before,
.#{$ionicons-prefix}social-foursquare:before,
.#{$ionicons-prefix}social-foursquare-outline:before,
.#{$ionicons-prefix}social-freebsd-devil:before,
.#{$ionicons-prefix}social-github:before,
.#{$ionicons-prefix}social-github-outline:before,
.#{$ionicons-prefix}social-google:before,
.#{$ionicons-prefix}social-google-outline:before,
.#{$ionicons-prefix}social-googleplus:before,
.#{$ionicons-prefix}social-googleplus-outline:before,
.#{$ionicons-prefix}social-hackernews:before,
.#{$ionicons-prefix}social-hackernews-outline:before,
.#{$ionicons-prefix}social-html5:before,
.#{$ionicons-prefix}social-html5-outline:before,
.#{$ionicons-prefix}social-instagram:before,
.#{$ionicons-prefix}social-instagram-outline:before,
.#{$ionicons-prefix}social-javascript:before,
.#{$ionicons-prefix}social-javascript-outline:before,
.#{$ionicons-prefix}social-linkedin:before,
.#{$ionicons-prefix}social-linkedin-outline:before,
.#{$ionicons-prefix}social-markdown:before,
.#{$ionicons-prefix}social-nodejs:before,
.#{$ionicons-prefix}social-octocat:before,
.#{$ionicons-prefix}social-pinterest:before,
.#{$ionicons-prefix}social-pinterest-outline:before,
.#{$ionicons-prefix}social-python:before,
.#{$ionicons-prefix}social-reddit:before,
.#{$ionicons-prefix}social-reddit-outline:before,
.#{$ionicons-prefix}social-rss:before,
.#{$ionicons-prefix}social-rss-outline:before,
.#{$ionicons-prefix}social-sass:before,
.#{$ionicons-prefix}social-skype:before,
.#{$ionicons-prefix}social-skype-outline:before,
.#{$ionicons-prefix}social-snapchat:before,
.#{$ionicons-prefix}social-snapchat-outline:before,
.#{$ionicons-prefix}social-tumblr:before,
.#{$ionicons-prefix}social-tumblr-outline:before,
.#{$ionicons-prefix}social-tux:before,
.#{$ionicons-prefix}social-twitch:before,
.#{$ionicons-prefix}social-twitch-outline:before,
.#{$ionicons-prefix}social-twitter:before,
.#{$ionicons-prefix}social-twitter-outline:before,
.#{$ionicons-prefix}social-usd:before,
.#{$ionicons-prefix}social-usd-outline:before,
.#{$ionicons-prefix}social-vimeo:before,
.#{$ionicons-prefix}social-vimeo-outline:before,
.#{$ionicons-prefix}social-whatsapp:before,
.#{$ionicons-prefix}social-whatsapp-outline:before,
.#{$ionicons-prefix}social-windows:before,
.#{$ionicons-prefix}social-windows-outline:before,
.#{$ionicons-prefix}social-wordpress:before,
.#{$ionicons-prefix}social-wordpress-outline:before,
.#{$ionicons-prefix}social-yahoo:before,
.#{$ionicons-prefix}social-yahoo-outline:before,
.#{$ionicons-prefix}social-yen:before,
.#{$ionicons-prefix}social-yen-outline:before,
.#{$ionicons-prefix}social-youtube:before,
.#{$ionicons-prefix}social-youtube-outline:before,
.#{$ionicons-prefix}soup-can:before,
.#{$ionicons-prefix}soup-can-outline:before,
.#{$ionicons-prefix}speakerphone:before,
.#{$ionicons-prefix}speedometer:before,
.#{$ionicons-prefix}spoon:before,
.#{$ionicons-prefix}star:before,
.#{$ionicons-prefix}stats-bars:before,
.#{$ionicons-prefix}steam:before,
.#{$ionicons-prefix}stop:before,
.#{$ionicons-prefix}thermometer:before,
.#{$ionicons-prefix}thumbsdown:before,
.#{$ionicons-prefix}thumbsup:before,
.#{$ionicons-prefix}toggle:before,
.#{$ionicons-prefix}toggle-filled:before,
.#{$ionicons-prefix}transgender:before,
.#{$ionicons-prefix}trash-a:before,
.#{$ionicons-prefix}trash-b:before,
.#{$ionicons-prefix}trophy:before,
.#{$ionicons-prefix}tshirt:before,
.#{$ionicons-prefix}tshirt-outline:before,
.#{$ionicons-prefix}umbrella:before,
.#{$ionicons-prefix}university:before,
.#{$ionicons-prefix}unlocked:before,
.#{$ionicons-prefix}upload:before,
.#{$ionicons-prefix}usb:before,
.#{$ionicons-prefix}videocamera:before,
.#{$ionicons-prefix}volume-high:before,
.#{$ionicons-prefix}volume-low:before,
.#{$ionicons-prefix}volume-medium:before,
.#{$ionicons-prefix}volume-mute:before,
.#{$ionicons-prefix}wand:before,
.#{$ionicons-prefix}waterdrop:before,
.#{$ionicons-prefix}wifi:before,
.#{$ionicons-prefix}wineglass:before,
.#{$ionicons-prefix}woman:before,
.#{$ionicons-prefix}wrench:before,
.#{$ionicons-prefix}xbox:before {
  @extend .ion;
}
.#{$ionicons-prefix}alert:before {
  content: $ionicon-var-alert;
}
.#{$ionicons-prefix}alert-circled:before {
  content: $ionicon-var-alert-circled;
}
.#{$ionicons-prefix}android-add:before {
  content: $ionicon-var-android-add;
}
.#{$ionicons-prefix}android-add-circle:before {
  content: $ionicon-var-android-add-circle;
}
.#{$ionicons-prefix}android-alarm-clock:before {
  content: $ionicon-var-android-alarm-clock;
}
.#{$ionicons-prefix}android-alert:before {
  content: $ionicon-var-android-alert;
}
.#{$ionicons-prefix}android-apps:before {
  content: $ionicon-var-android-apps;
}
.#{$ionicons-prefix}android-archive:before {
  content: $ionicon-var-android-archive;
}
.#{$ionicons-prefix}android-arrow-back:before {
  content: $ionicon-var-android-arrow-back;
}
.#{$ionicons-prefix}android-arrow-down:before {
  content: $ionicon-var-android-arrow-down;
}
.#{$ionicons-prefix}android-arrow-dropdown:before {
  content: $ionicon-var-android-arrow-dropdown;
}
.#{$ionicons-prefix}android-arrow-dropdown-circle:before {
  content: $ionicon-var-android-arrow-dropdown-circle;
}
.#{$ionicons-prefix}android-arrow-dropleft:before {
  content: $ionicon-var-android-arrow-dropleft;
}
.#{$ionicons-prefix}android-arrow-dropleft-circle:before {
  content: $ionicon-var-android-arrow-dropleft-circle;
}
.#{$ionicons-prefix}android-arrow-dropright:before {
  content: $ionicon-var-android-arrow-dropright;
}
.#{$ionicons-prefix}android-arrow-dropright-circle:before {
  content: $ionicon-var-android-arrow-dropright-circle;
}
.#{$ionicons-prefix}android-arrow-dropup:before {
  content: $ionicon-var-android-arrow-dropup;
}
.#{$ionicons-prefix}android-arrow-dropup-circle:before {
  content: $ionicon-var-android-arrow-dropup-circle;
}
.#{$ionicons-prefix}android-arrow-forward:before {
  content: $ionicon-var-android-arrow-forward;
}
.#{$ionicons-prefix}android-arrow-up:before {
  content: $ionicon-var-android-arrow-up;
}
.#{$ionicons-prefix}android-attach:before {
  content: $ionicon-var-android-attach;
}
.#{$ionicons-prefix}android-bar:before {
  content: $ionicon-var-android-bar;
}
.#{$ionicons-prefix}android-bicycle:before {
  content: $ionicon-var-android-bicycle;
}
.#{$ionicons-prefix}android-boat:before {
  content: $ionicon-var-android-boat;
}
.#{$ionicons-prefix}android-bookmark:before {
  content: $ionicon-var-android-bookmark;
}
.#{$ionicons-prefix}android-bulb:before {
  content: $ionicon-var-android-bulb;
}
.#{$ionicons-prefix}android-bus:before {
  content: $ionicon-var-android-bus;
}
.#{$ionicons-prefix}android-calendar:before {
  content: $ionicon-var-android-calendar;
}
.#{$ionicons-prefix}android-call:before {
  content: $ionicon-var-android-call;
}
.#{$ionicons-prefix}android-camera:before {
  content: $ionicon-var-android-camera;
}
.#{$ionicons-prefix}android-cancel:before {
  content: $ionicon-var-android-cancel;
}
.#{$ionicons-prefix}android-car:before {
  content: $ionicon-var-android-car;
}
.#{$ionicons-prefix}android-cart:before {
  content: $ionicon-var-android-cart;
}
.#{$ionicons-prefix}android-chat:before {
  content: $ionicon-var-android-chat;
}
.#{$ionicons-prefix}android-checkbox:before {
  content: $ionicon-var-android-checkbox;
}
.#{$ionicons-prefix}android-checkbox-blank:before {
  content: $ionicon-var-android-checkbox-blank;
}
.#{$ionicons-prefix}android-checkbox-outline:before {
  content: $ionicon-var-android-checkbox-outline;
}
.#{$ionicons-prefix}android-checkbox-outline-blank:before {
  content: $ionicon-var-android-checkbox-outline-blank;
}
.#{$ionicons-prefix}android-checkmark-circle:before {
  content: $ionicon-var-android-checkmark-circle;
}
.#{$ionicons-prefix}android-clipboard:before {
  content: $ionicon-var-android-clipboard;
}
.#{$ionicons-prefix}android-close:before {
  content: $ionicon-var-android-close;
}
.#{$ionicons-prefix}android-cloud:before {
  content: $ionicon-var-android-cloud;
}
.#{$ionicons-prefix}android-cloud-circle:before {
  content: $ionicon-var-android-cloud-circle;
}
.#{$ionicons-prefix}android-cloud-done:before {
  content: $ionicon-var-android-cloud-done;
}
.#{$ionicons-prefix}android-cloud-outline:before {
  content: $ionicon-var-android-cloud-outline;
}
.#{$ionicons-prefix}android-color-palette:before {
  content: $ionicon-var-android-color-palette;
}
.#{$ionicons-prefix}android-compass:before {
  content: $ionicon-var-android-compass;
}
.#{$ionicons-prefix}android-contact:before {
  content: $ionicon-var-android-contact;
}
.#{$ionicons-prefix}android-contacts:before {
  content: $ionicon-var-android-contacts;
}
.#{$ionicons-prefix}android-contract:before {
  content: $ionicon-var-android-contract;
}
.#{$ionicons-prefix}android-create:before {
  content: $ionicon-var-android-create;
}
.#{$ionicons-prefix}android-delete:before {
  content: $ionicon-var-android-delete;
}
.#{$ionicons-prefix}android-desktop:before {
  content: $ionicon-var-android-desktop;
}
.#{$ionicons-prefix}android-document:before {
  content: $ionicon-var-android-document;
}
.#{$ionicons-prefix}android-done:before {
  content: $ionicon-var-android-done;
}
.#{$ionicons-prefix}android-done-all:before {
  content: $ionicon-var-android-done-all;
}
.#{$ionicons-prefix}android-download:before {
  content: $ionicon-var-android-download;
}
.#{$ionicons-prefix}android-drafts:before {
  content: $ionicon-var-android-drafts;
}
.#{$ionicons-prefix}android-exit:before {
  content: $ionicon-var-android-exit;
}
.#{$ionicons-prefix}android-expand:before {
  content: $ionicon-var-android-expand;
}
.#{$ionicons-prefix}android-favorite:before {
  content: $ionicon-var-android-favorite;
}
.#{$ionicons-prefix}android-favorite-outline:before {
  content: $ionicon-var-android-favorite-outline;
}
.#{$ionicons-prefix}android-film:before {
  content: $ionicon-var-android-film;
}
.#{$ionicons-prefix}android-folder:before {
  content: $ionicon-var-android-folder;
}
.#{$ionicons-prefix}android-folder-open:before {
  content: $ionicon-var-android-folder-open;
}
.#{$ionicons-prefix}android-funnel:before {
  content: $ionicon-var-android-funnel;
}
.#{$ionicons-prefix}android-globe:before {
  content: $ionicon-var-android-globe;
}
.#{$ionicons-prefix}android-hand:before {
  content: $ionicon-var-android-hand;
}
.#{$ionicons-prefix}android-hangout:before {
  content: $ionicon-var-android-hangout;
}
.#{$ionicons-prefix}android-happy:before {
  content: $ionicon-var-android-happy;
}
.#{$ionicons-prefix}android-home:before {
  content: $ionicon-var-android-home;
}
.#{$ionicons-prefix}android-image:before {
  content: $ionicon-var-android-image;
}
.#{$ionicons-prefix}android-laptop:before {
  content: $ionicon-var-android-laptop;
}
.#{$ionicons-prefix}android-list:before {
  content: $ionicon-var-android-list;
}
.#{$ionicons-prefix}android-locate:before {
  content: $ionicon-var-android-locate;
}
.#{$ionicons-prefix}android-lock:before {
  content: $ionicon-var-android-lock;
}
.#{$ionicons-prefix}android-mail:before {
  content: $ionicon-var-android-mail;
}
.#{$ionicons-prefix}android-map:before {
  content: $ionicon-var-android-map;
}
.#{$ionicons-prefix}android-menu:before {
  content: $ionicon-var-android-menu;
}
.#{$ionicons-prefix}android-microphone:before {
  content: $ionicon-var-android-microphone;
}
.#{$ionicons-prefix}android-microphone-off:before {
  content: $ionicon-var-android-microphone-off;
}
.#{$ionicons-prefix}android-more-horizontal:before {
  content: $ionicon-var-android-more-horizontal;
}
.#{$ionicons-prefix}android-more-vertical:before {
  content: $ionicon-var-android-more-vertical;
}
.#{$ionicons-prefix}android-navigate:before {
  content: $ionicon-var-android-navigate;
}
.#{$ionicons-prefix}android-notifications:before {
  content: $ionicon-var-android-notifications;
}
.#{$ionicons-prefix}android-notifications-none:before {
  content: $ionicon-var-android-notifications-none;
}
.#{$ionicons-prefix}android-notifications-off:before {
  content: $ionicon-var-android-notifications-off;
}
.#{$ionicons-prefix}android-open:before {
  content: $ionicon-var-android-open;
}
.#{$ionicons-prefix}android-options:before {
  content: $ionicon-var-android-options;
}
.#{$ionicons-prefix}android-people:before {
  content: $ionicon-var-android-people;
}
.#{$ionicons-prefix}android-person:before {
  content: $ionicon-var-android-person;
}
.#{$ionicons-prefix}android-person-add:before {
  content: $ionicon-var-android-person-add;
}
.#{$ionicons-prefix}android-phone-landscape:before {
  content: $ionicon-var-android-phone-landscape;
}
.#{$ionicons-prefix}android-phone-portrait:before {
  content: $ionicon-var-android-phone-portrait;
}
.#{$ionicons-prefix}android-pin:before {
  content: $ionicon-var-android-pin;
}
.#{$ionicons-prefix}android-plane:before {
  content: $ionicon-var-android-plane;
}
.#{$ionicons-prefix}android-playstore:before {
  content: $ionicon-var-android-playstore;
}
.#{$ionicons-prefix}android-print:before {
  content: $ionicon-var-android-print;
}
.#{$ionicons-prefix}android-radio-button-off:before {
  content: $ionicon-var-android-radio-button-off;
}
.#{$ionicons-prefix}android-radio-button-on:before {
  content: $ionicon-var-android-radio-button-on;
}
.#{$ionicons-prefix}android-refresh:before {
  content: $ionicon-var-android-refresh;
}
.#{$ionicons-prefix}android-remove:before {
  content: $ionicon-var-android-remove;
}
.#{$ionicons-prefix}android-remove-circle:before {
  content: $ionicon-var-android-remove-circle;
}
.#{$ionicons-prefix}android-restaurant:before {
  content: $ionicon-var-android-restaurant;
}
.#{$ionicons-prefix}android-sad:before {
  content: $ionicon-var-android-sad;
}
.#{$ionicons-prefix}android-search:before {
  content: $ionicon-var-android-search;
}
.#{$ionicons-prefix}android-send:before {
  content: $ionicon-var-android-send;
}
.#{$ionicons-prefix}android-settings:before {
  content: $ionicon-var-android-settings;
}
.#{$ionicons-prefix}android-share:before {
  content: $ionicon-var-android-share;
}
.#{$ionicons-prefix}android-share-alt:before {
  content: $ionicon-var-android-share-alt;
}
.#{$ionicons-prefix}android-star:before {
  content: $ionicon-var-android-star;
}
.#{$ionicons-prefix}android-star-half:before {
  content: $ionicon-var-android-star-half;
}
.#{$ionicons-prefix}android-star-outline:before {
  content: $ionicon-var-android-star-outline;
}
.#{$ionicons-prefix}android-stopwatch:before {
  content: $ionicon-var-android-stopwatch;
}
.#{$ionicons-prefix}android-subway:before {
  content: $ionicon-var-android-subway;
}
.#{$ionicons-prefix}android-sunny:before {
  content: $ionicon-var-android-sunny;
}
.#{$ionicons-prefix}android-sync:before {
  content: $ionicon-var-android-sync;
}
.#{$ionicons-prefix}android-textsms:before {
  content: $ionicon-var-android-textsms;
}
.#{$ionicons-prefix}android-time:before {
  content: $ionicon-var-android-time;
}
.#{$ionicons-prefix}android-train:before {
  content: $ionicon-var-android-train;
}
.#{$ionicons-prefix}android-unlock:before {
  content: $ionicon-var-android-unlock;
}
.#{$ionicons-prefix}android-upload:before {
  content: $ionicon-var-android-upload;
}
.#{$ionicons-prefix}android-volume-down:before {
  content: $ionicon-var-android-volume-down;
}
.#{$ionicons-prefix}android-volume-mute:before {
  content: $ionicon-var-android-volume-mute;
}
.#{$ionicons-prefix}android-volume-off:before {
  content: $ionicon-var-android-volume-off;
}
.#{$ionicons-prefix}android-volume-up:before {
  content: $ionicon-var-android-volume-up;
}
.#{$ionicons-prefix}android-walk:before {
  content: $ionicon-var-android-walk;
}
.#{$ionicons-prefix}android-warning:before {
  content: $ionicon-var-android-warning;
}
.#{$ionicons-prefix}android-watch:before {
  content: $ionicon-var-android-watch;
}
.#{$ionicons-prefix}android-wifi:before {
  content: $ionicon-var-android-wifi;
}
.#{$ionicons-prefix}aperture:before {
  content: $ionicon-var-aperture;
}
.#{$ionicons-prefix}archive:before {
  content: $ionicon-var-archive;
}
.#{$ionicons-prefix}arrow-down-a:before {
  content: $ionicon-var-arrow-down-a;
}
.#{$ionicons-prefix}arrow-down-b:before {
  content: $ionicon-var-arrow-down-b;
}
.#{$ionicons-prefix}arrow-down-c:before {
  content: $ionicon-var-arrow-down-c;
}
.#{$ionicons-prefix}arrow-expand:before {
  content: $ionicon-var-arrow-expand;
}
.#{$ionicons-prefix}arrow-graph-down-left:before {
  content: $ionicon-var-arrow-graph-down-left;
}
.#{$ionicons-prefix}arrow-graph-down-right:before {
  content: $ionicon-var-arrow-graph-down-right;
}
.#{$ionicons-prefix}arrow-graph-up-left:before {
  content: $ionicon-var-arrow-graph-up-left;
}
.#{$ionicons-prefix}arrow-graph-up-right:before {
  content: $ionicon-var-arrow-graph-up-right;
}
.#{$ionicons-prefix}arrow-left-a:before {
  content: $ionicon-var-arrow-left-a;
}
.#{$ionicons-prefix}arrow-left-b:before {
  content: $ionicon-var-arrow-left-b;
}
.#{$ionicons-prefix}arrow-left-c:before {
  content: $ionicon-var-arrow-left-c;
}
.#{$ionicons-prefix}arrow-move:before {
  content: $ionicon-var-arrow-move;
}
.#{$ionicons-prefix}arrow-resize:before {
  content: $ionicon-var-arrow-resize;
}
.#{$ionicons-prefix}arrow-return-left:before {
  content: $ionicon-var-arrow-return-left;
}
.#{$ionicons-prefix}arrow-return-right:before {
  content: $ionicon-var-arrow-return-right;
}
.#{$ionicons-prefix}arrow-right-a:before {
  content: $ionicon-var-arrow-right-a;
}
.#{$ionicons-prefix}arrow-right-b:before {
  content: $ionicon-var-arrow-right-b;
}
.#{$ionicons-prefix}arrow-right-c:before {
  content: $ionicon-var-arrow-right-c;
}
.#{$ionicons-prefix}arrow-shrink:before {
  content: $ionicon-var-arrow-shrink;
}
.#{$ionicons-prefix}arrow-swap:before {
  content: $ionicon-var-arrow-swap;
}
.#{$ionicons-prefix}arrow-up-a:before {
  content: $ionicon-var-arrow-up-a;
}
.#{$ionicons-prefix}arrow-up-b:before {
  content: $ionicon-var-arrow-up-b;
}
.#{$ionicons-prefix}arrow-up-c:before {
  content: $ionicon-var-arrow-up-c;
}
.#{$ionicons-prefix}asterisk:before {
  content: $ionicon-var-asterisk;
}
.#{$ionicons-prefix}at:before {
  content: $ionicon-var-at;
}
.#{$ionicons-prefix}backspace:before {
  content: $ionicon-var-backspace;
}
.#{$ionicons-prefix}backspace-outline:before {
  content: $ionicon-var-backspace-outline;
}
.#{$ionicons-prefix}bag:before {
  content: $ionicon-var-bag;
}
.#{$ionicons-prefix}battery-charging:before {
  content: $ionicon-var-battery-charging;
}
.#{$ionicons-prefix}battery-empty:before {
  content: $ionicon-var-battery-empty;
}
.#{$ionicons-prefix}battery-full:before {
  content: $ionicon-var-battery-full;
}
.#{$ionicons-prefix}battery-half:before {
  content: $ionicon-var-battery-half;
}
.#{$ionicons-prefix}battery-low:before {
  content: $ionicon-var-battery-low;
}
.#{$ionicons-prefix}beaker:before {
  content: $ionicon-var-beaker;
}
.#{$ionicons-prefix}beer:before {
  content: $ionicon-var-beer;
}
.#{$ionicons-prefix}bluetooth:before {
  content: $ionicon-var-bluetooth;
}
.#{$ionicons-prefix}bonfire:before {
  content: $ionicon-var-bonfire;
}
.#{$ionicons-prefix}bookmark:before {
  content: $ionicon-var-bookmark;
}
.#{$ionicons-prefix}bowtie:before {
  content: $ionicon-var-bowtie;
}
.#{$ionicons-prefix}briefcase:before {
  content: $ionicon-var-briefcase;
}
.#{$ionicons-prefix}bug:before {
  content: $ionicon-var-bug;
}
.#{$ionicons-prefix}calculator:before {
  content: $ionicon-var-calculator;
}
.#{$ionicons-prefix}calendar:before {
  content: $ionicon-var-calendar;
}
.#{$ionicons-prefix}camera:before {
  content: $ionicon-var-camera;
}
.#{$ionicons-prefix}card:before {
  content: $ionicon-var-card;
}
.#{$ionicons-prefix}cash:before {
  content: $ionicon-var-cash;
}
.#{$ionicons-prefix}chatbox:before {
  content: $ionicon-var-chatbox;
}
.#{$ionicons-prefix}chatbox-working:before {
  content: $ionicon-var-chatbox-working;
}
.#{$ionicons-prefix}chatboxes:before {
  content: $ionicon-var-chatboxes;
}
.#{$ionicons-prefix}chatbubble:before {
  content: $ionicon-var-chatbubble;
}
.#{$ionicons-prefix}chatbubble-working:before {
  content: $ionicon-var-chatbubble-working;
}
.#{$ionicons-prefix}chatbubbles:before {
  content: $ionicon-var-chatbubbles;
}
.#{$ionicons-prefix}checkmark:before {
  content: $ionicon-var-checkmark;
}
.#{$ionicons-prefix}checkmark-circled:before {
  content: $ionicon-var-checkmark-circled;
}
.#{$ionicons-prefix}checkmark-round:before {
  content: $ionicon-var-checkmark-round;
}
.#{$ionicons-prefix}chevron-down:before {
  content: $ionicon-var-chevron-down;
}
.#{$ionicons-prefix}chevron-left:before {
  content: $ionicon-var-chevron-left;
}
.#{$ionicons-prefix}chevron-right:before {
  content: $ionicon-var-chevron-right;
}
.#{$ionicons-prefix}chevron-up:before {
  content: $ionicon-var-chevron-up;
}
.#{$ionicons-prefix}clipboard:before {
  content: $ionicon-var-clipboard;
}
.#{$ionicons-prefix}clock:before {
  content: $ionicon-var-clock;
}
.#{$ionicons-prefix}close:before {
  content: $ionicon-var-close;
}
.#{$ionicons-prefix}close-circled:before {
  content: $ionicon-var-close-circled;
}
.#{$ionicons-prefix}close-round:before {
  content: $ionicon-var-close-round;
}
.#{$ionicons-prefix}closed-captioning:before {
  content: $ionicon-var-closed-captioning;
}
.#{$ionicons-prefix}cloud:before {
  content: $ionicon-var-cloud;
}
.#{$ionicons-prefix}code:before {
  content: $ionicon-var-code;
}
.#{$ionicons-prefix}code-download:before {
  content: $ionicon-var-code-download;
}
.#{$ionicons-prefix}code-working:before {
  content: $ionicon-var-code-working;
}
.#{$ionicons-prefix}coffee:before {
  content: $ionicon-var-coffee;
}
.#{$ionicons-prefix}compass:before {
  content: $ionicon-var-compass;
}
.#{$ionicons-prefix}compose:before {
  content: $ionicon-var-compose;
}
.#{$ionicons-prefix}connection-bars:before {
  content: $ionicon-var-connection-bars;
}
.#{$ionicons-prefix}contrast:before {
  content: $ionicon-var-contrast;
}
.#{$ionicons-prefix}crop:before {
  content: $ionicon-var-crop;
}
.#{$ionicons-prefix}cube:before {
  content: $ionicon-var-cube;
}
.#{$ionicons-prefix}disc:before {
  content: $ionicon-var-disc;
}
.#{$ionicons-prefix}document:before {
  content: $ionicon-var-document;
}
.#{$ionicons-prefix}document-text:before {
  content: $ionicon-var-document-text;
}
.#{$ionicons-prefix}drag:before {
  content: $ionicon-var-drag;
}
.#{$ionicons-prefix}earth:before {
  content: $ionicon-var-earth;
}
.#{$ionicons-prefix}easel:before {
  content: $ionicon-var-easel;
}
.#{$ionicons-prefix}edit:before {
  content: $ionicon-var-edit;
}
.#{$ionicons-prefix}egg:before {
  content: $ionicon-var-egg;
}
.#{$ionicons-prefix}eject:before {
  content: $ionicon-var-eject;
}
.#{$ionicons-prefix}email:before {
  content: $ionicon-var-email;
}
.#{$ionicons-prefix}email-unread:before {
  content: $ionicon-var-email-unread;
}
.#{$ionicons-prefix}erlenmeyer-flask:before {
  content: $ionicon-var-erlenmeyer-flask;
}
.#{$ionicons-prefix}erlenmeyer-flask-bubbles:before {
  content: $ionicon-var-erlenmeyer-flask-bubbles;
}
.#{$ionicons-prefix}eye:before {
  content: $ionicon-var-eye;
}
.#{$ionicons-prefix}eye-disabled:before {
  content: $ionicon-var-eye-disabled;
}
.#{$ionicons-prefix}female:before {
  content: $ionicon-var-female;
}
.#{$ionicons-prefix}filing:before {
  content: $ionicon-var-filing;
}
.#{$ionicons-prefix}film-marker:before {
  content: $ionicon-var-film-marker;
}
.#{$ionicons-prefix}fireball:before {
  content: $ionicon-var-fireball;
}
.#{$ionicons-prefix}flag:before {
  content: $ionicon-var-flag;
}
.#{$ionicons-prefix}flame:before {
  content: $ionicon-var-flame;
}
.#{$ionicons-prefix}flash:before {
  content: $ionicon-var-flash;
}
.#{$ionicons-prefix}flash-off:before {
  content: $ionicon-var-flash-off;
}
.#{$ionicons-prefix}folder:before {
  content: $ionicon-var-folder;
}
.#{$ionicons-prefix}fork:before {
  content: $ionicon-var-fork;
}
.#{$ionicons-prefix}fork-repo:before {
  content: $ionicon-var-fork-repo;
}
.#{$ionicons-prefix}forward:before {
  content: $ionicon-var-forward;
}
.#{$ionicons-prefix}funnel:before {
  content: $ionicon-var-funnel;
}
.#{$ionicons-prefix}gear-a:before {
  content: $ionicon-var-gear-a;
}
.#{$ionicons-prefix}gear-b:before {
  content: $ionicon-var-gear-b;
}
.#{$ionicons-prefix}grid:before {
  content: $ionicon-var-grid;
}
.#{$ionicons-prefix}hammer:before {
  content: $ionicon-var-hammer;
}
.#{$ionicons-prefix}happy:before {
  content: $ionicon-var-happy;
}
.#{$ionicons-prefix}happy-outline:before {
  content: $ionicon-var-happy-outline;
}
.#{$ionicons-prefix}headphone:before {
  content: $ionicon-var-headphone;
}
.#{$ionicons-prefix}heart:before {
  content: $ionicon-var-heart;
}
.#{$ionicons-prefix}heart-broken:before {
  content: $ionicon-var-heart-broken;
}
.#{$ionicons-prefix}help:before {
  content: $ionicon-var-help;
}
.#{$ionicons-prefix}help-buoy:before {
  content: $ionicon-var-help-buoy;
}
.#{$ionicons-prefix}help-circled:before {
  content: $ionicon-var-help-circled;
}
.#{$ionicons-prefix}home:before {
  content: $ionicon-var-home;
}
.#{$ionicons-prefix}icecream:before {
  content: $ionicon-var-icecream;
}
.#{$ionicons-prefix}image:before {
  content: $ionicon-var-image;
}
.#{$ionicons-prefix}images:before {
  content: $ionicon-var-images;
}
.#{$ionicons-prefix}information:before {
  content: $ionicon-var-information;
}
.#{$ionicons-prefix}information-circled:before {
  content: $ionicon-var-information-circled;
}
.#{$ionicons-prefix}ionic:before {
  content: $ionicon-var-ionic;
}
.#{$ionicons-prefix}ios-alarm:before {
  content: $ionicon-var-ios-alarm;
}
.#{$ionicons-prefix}ios-alarm-outline:before {
  content: $ionicon-var-ios-alarm-outline;
}
.#{$ionicons-prefix}ios-albums:before {
  content: $ionicon-var-ios-albums;
}
.#{$ionicons-prefix}ios-albums-outline:before {
  content: $ionicon-var-ios-albums-outline;
}
.#{$ionicons-prefix}ios-americanfootball:before {
  content: $ionicon-var-ios-americanfootball;
}
.#{$ionicons-prefix}ios-americanfootball-outline:before {
  content: $ionicon-var-ios-americanfootball-outline;
}
.#{$ionicons-prefix}ios-analytics:before {
  content: $ionicon-var-ios-analytics;
}
.#{$ionicons-prefix}ios-analytics-outline:before {
  content: $ionicon-var-ios-analytics-outline;
}
.#{$ionicons-prefix}ios-arrow-back:before {
  content: $ionicon-var-ios-arrow-back;
}
.#{$ionicons-prefix}ios-arrow-down:before {
  content: $ionicon-var-ios-arrow-down;
}
.#{$ionicons-prefix}ios-arrow-forward:before {
  content: $ionicon-var-ios-arrow-forward;
}
.#{$ionicons-prefix}ios-arrow-left:before {
  content: $ionicon-var-ios-arrow-left;
}
.#{$ionicons-prefix}ios-arrow-right:before {
  content: $ionicon-var-ios-arrow-right;
}
.#{$ionicons-prefix}ios-arrow-thin-down:before {
  content: $ionicon-var-ios-arrow-thin-down;
}
.#{$ionicons-prefix}ios-arrow-thin-left:before {
  content: $ionicon-var-ios-arrow-thin-left;
}
.#{$ionicons-prefix}ios-arrow-thin-right:before {
  content: $ionicon-var-ios-arrow-thin-right;
}
.#{$ionicons-prefix}ios-arrow-thin-up:before {
  content: $ionicon-var-ios-arrow-thin-up;
}
.#{$ionicons-prefix}ios-arrow-up:before {
  content: $ionicon-var-ios-arrow-up;
}
.#{$ionicons-prefix}ios-at:before {
  content: $ionicon-var-ios-at;
}
.#{$ionicons-prefix}ios-at-outline:before {
  content: $ionicon-var-ios-at-outline;
}
.#{$ionicons-prefix}ios-barcode:before {
  content: $ionicon-var-ios-barcode;
}
.#{$ionicons-prefix}ios-barcode-outline:before {
  content: $ionicon-var-ios-barcode-outline;
}
.#{$ionicons-prefix}ios-baseball:before {
  content: $ionicon-var-ios-baseball;
}
.#{$ionicons-prefix}ios-baseball-outline:before {
  content: $ionicon-var-ios-baseball-outline;
}
.#{$ionicons-prefix}ios-basketball:before {
  content: $ionicon-var-ios-basketball;
}
.#{$ionicons-prefix}ios-basketball-outline:before {
  content: $ionicon-var-ios-basketball-outline;
}
.#{$ionicons-prefix}ios-bell:before {
  content: $ionicon-var-ios-bell;
}
.#{$ionicons-prefix}ios-bell-outline:before {
  content: $ionicon-var-ios-bell-outline;
}
.#{$ionicons-prefix}ios-body:before {
  content: $ionicon-var-ios-body;
}
.#{$ionicons-prefix}ios-body-outline:before {
  content: $ionicon-var-ios-body-outline;
}
.#{$ionicons-prefix}ios-bolt:before {
  content: $ionicon-var-ios-bolt;
}
.#{$ionicons-prefix}ios-bolt-outline:before {
  content: $ionicon-var-ios-bolt-outline;
}
.#{$ionicons-prefix}ios-book:before {
  content: $ionicon-var-ios-book;
}
.#{$ionicons-prefix}ios-book-outline:before {
  content: $ionicon-var-ios-book-outline;
}
.#{$ionicons-prefix}ios-bookmarks:before {
  content: $ionicon-var-ios-bookmarks;
}
.#{$ionicons-prefix}ios-bookmarks-outline:before {
  content: $ionicon-var-ios-bookmarks-outline;
}
.#{$ionicons-prefix}ios-box:before {
  content: $ionicon-var-ios-box;
}
.#{$ionicons-prefix}ios-box-outline:before {
  content: $ionicon-var-ios-box-outline;
}
.#{$ionicons-prefix}ios-briefcase:before {
  content: $ionicon-var-ios-briefcase;
}
.#{$ionicons-prefix}ios-briefcase-outline:before {
  content: $ionicon-var-ios-briefcase-outline;
}
.#{$ionicons-prefix}ios-browsers:before {
  content: $ionicon-var-ios-browsers;
}
.#{$ionicons-prefix}ios-browsers-outline:before {
  content: $ionicon-var-ios-browsers-outline;
}
.#{$ionicons-prefix}ios-calculator:before {
  content: $ionicon-var-ios-calculator;
}
.#{$ionicons-prefix}ios-calculator-outline:before {
  content: $ionicon-var-ios-calculator-outline;
}
.#{$ionicons-prefix}ios-calendar:before {
  content: $ionicon-var-ios-calendar;
}
.#{$ionicons-prefix}ios-calendar-outline:before {
  content: $ionicon-var-ios-calendar-outline;
}
.#{$ionicons-prefix}ios-camera:before {
  content: $ionicon-var-ios-camera;
}
.#{$ionicons-prefix}ios-camera-outline:before {
  content: $ionicon-var-ios-camera-outline;
}
.#{$ionicons-prefix}ios-cart:before {
  content: $ionicon-var-ios-cart;
}
.#{$ionicons-prefix}ios-cart-outline:before {
  content: $ionicon-var-ios-cart-outline;
}
.#{$ionicons-prefix}ios-chatboxes:before {
  content: $ionicon-var-ios-chatboxes;
}
.#{$ionicons-prefix}ios-chatboxes-outline:before {
  content: $ionicon-var-ios-chatboxes-outline;
}
.#{$ionicons-prefix}ios-chatbubble:before {
  content: $ionicon-var-ios-chatbubble;
}
.#{$ionicons-prefix}ios-chatbubble-outline:before {
  content: $ionicon-var-ios-chatbubble-outline;
}
.#{$ionicons-prefix}ios-checkmark:before {
  content: $ionicon-var-ios-checkmark;
}
.#{$ionicons-prefix}ios-checkmark-empty:before {
  content: $ionicon-var-ios-checkmark-empty;
}
.#{$ionicons-prefix}ios-checkmark-outline:before {
  content: $ionicon-var-ios-checkmark-outline;
}
.#{$ionicons-prefix}ios-circle-filled:before {
  content: $ionicon-var-ios-circle-filled;
}
.#{$ionicons-prefix}ios-circle-outline:before {
  content: $ionicon-var-ios-circle-outline;
}
.#{$ionicons-prefix}ios-clock:before {
  content: $ionicon-var-ios-clock;
}
.#{$ionicons-prefix}ios-clock-outline:before {
  content: $ionicon-var-ios-clock-outline;
}
.#{$ionicons-prefix}ios-close:before {
  content: $ionicon-var-ios-close;
}
.#{$ionicons-prefix}ios-close-empty:before {
  content: $ionicon-var-ios-close-empty;
}
.#{$ionicons-prefix}ios-close-outline:before {
  content: $ionicon-var-ios-close-outline;
}
.#{$ionicons-prefix}ios-cloud:before {
  content: $ionicon-var-ios-cloud;
}
.#{$ionicons-prefix}ios-cloud-download:before {
  content: $ionicon-var-ios-cloud-download;
}
.#{$ionicons-prefix}ios-cloud-download-outline:before {
  content: $ionicon-var-ios-cloud-download-outline;
}
.#{$ionicons-prefix}ios-cloud-outline:before {
  content: $ionicon-var-ios-cloud-outline;
}
.#{$ionicons-prefix}ios-cloud-upload:before {
  content: $ionicon-var-ios-cloud-upload;
}
.#{$ionicons-prefix}ios-cloud-upload-outline:before {
  content: $ionicon-var-ios-cloud-upload-outline;
}
.#{$ionicons-prefix}ios-cloudy:before {
  content: $ionicon-var-ios-cloudy;
}
.#{$ionicons-prefix}ios-cloudy-night:before {
  content: $ionicon-var-ios-cloudy-night;
}
.#{$ionicons-prefix}ios-cloudy-night-outline:before {
  content: $ionicon-var-ios-cloudy-night-outline;
}
.#{$ionicons-prefix}ios-cloudy-outline:before {
  content: $ionicon-var-ios-cloudy-outline;
}
.#{$ionicons-prefix}ios-cog:before {
  content: $ionicon-var-ios-cog;
}
.#{$ionicons-prefix}ios-cog-outline:before {
  content: $ionicon-var-ios-cog-outline;
}
.#{$ionicons-prefix}ios-color-filter:before {
  content: $ionicon-var-ios-color-filter;
}
.#{$ionicons-prefix}ios-color-filter-outline:before {
  content: $ionicon-var-ios-color-filter-outline;
}
.#{$ionicons-prefix}ios-color-wand:before {
  content: $ionicon-var-ios-color-wand;
}
.#{$ionicons-prefix}ios-color-wand-outline:before {
  content: $ionicon-var-ios-color-wand-outline;
}
.#{$ionicons-prefix}ios-compose:before {
  content: $ionicon-var-ios-compose;
}
.#{$ionicons-prefix}ios-compose-outline:before {
  content: $ionicon-var-ios-compose-outline;
}
.#{$ionicons-prefix}ios-contact:before {
  content: $ionicon-var-ios-contact;
}
.#{$ionicons-prefix}ios-contact-outline:before {
  content: $ionicon-var-ios-contact-outline;
}
.#{$ionicons-prefix}ios-copy:before {
  content: $ionicon-var-ios-copy;
}
.#{$ionicons-prefix}ios-copy-outline:before {
  content: $ionicon-var-ios-copy-outline;
}
.#{$ionicons-prefix}ios-crop:before {
  content: $ionicon-var-ios-crop;
}
.#{$ionicons-prefix}ios-crop-strong:before {
  content: $ionicon-var-ios-crop-strong;
}
.#{$ionicons-prefix}ios-download:before {
  content: $ionicon-var-ios-download;
}
.#{$ionicons-prefix}ios-download-outline:before {
  content: $ionicon-var-ios-download-outline;
}
.#{$ionicons-prefix}ios-drag:before {
  content: $ionicon-var-ios-drag;
}
.#{$ionicons-prefix}ios-email:before {
  content: $ionicon-var-ios-email;
}
.#{$ionicons-prefix}ios-email-outline:before {
  content: $ionicon-var-ios-email-outline;
}
.#{$ionicons-prefix}ios-eye:before {
  content: $ionicon-var-ios-eye;
}
.#{$ionicons-prefix}ios-eye-outline:before {
  content: $ionicon-var-ios-eye-outline;
}
.#{$ionicons-prefix}ios-fastforward:before {
  content: $ionicon-var-ios-fastforward;
}
.#{$ionicons-prefix}ios-fastforward-outline:before {
  content: $ionicon-var-ios-fastforward-outline;
}
.#{$ionicons-prefix}ios-filing:before {
  content: $ionicon-var-ios-filing;
}
.#{$ionicons-prefix}ios-filing-outline:before {
  content: $ionicon-var-ios-filing-outline;
}
.#{$ionicons-prefix}ios-film:before {
  content: $ionicon-var-ios-film;
}
.#{$ionicons-prefix}ios-film-outline:before {
  content: $ionicon-var-ios-film-outline;
}
.#{$ionicons-prefix}ios-flag:before {
  content: $ionicon-var-ios-flag;
}
.#{$ionicons-prefix}ios-flag-outline:before {
  content: $ionicon-var-ios-flag-outline;
}
.#{$ionicons-prefix}ios-flame:before {
  content: $ionicon-var-ios-flame;
}
.#{$ionicons-prefix}ios-flame-outline:before {
  content: $ionicon-var-ios-flame-outline;
}
.#{$ionicons-prefix}ios-flask:before {
  content: $ionicon-var-ios-flask;
}
.#{$ionicons-prefix}ios-flask-outline:before {
  content: $ionicon-var-ios-flask-outline;
}
.#{$ionicons-prefix}ios-flower:before {
  content: $ionicon-var-ios-flower;
}
.#{$ionicons-prefix}ios-flower-outline:before {
  content: $ionicon-var-ios-flower-outline;
}
.#{$ionicons-prefix}ios-folder:before {
  content: $ionicon-var-ios-folder;
}
.#{$ionicons-prefix}ios-folder-outline:before {
  content: $ionicon-var-ios-folder-outline;
}
.#{$ionicons-prefix}ios-football:before {
  content: $ionicon-var-ios-football;
}
.#{$ionicons-prefix}ios-football-outline:before {
  content: $ionicon-var-ios-football-outline;
}
.#{$ionicons-prefix}ios-game-controller-a:before {
  content: $ionicon-var-ios-game-controller-a;
}
.#{$ionicons-prefix}ios-game-controller-a-outline:before {
  content: $ionicon-var-ios-game-controller-a-outline;
}
.#{$ionicons-prefix}ios-game-controller-b:before {
  content: $ionicon-var-ios-game-controller-b;
}
.#{$ionicons-prefix}ios-game-controller-b-outline:before {
  content: $ionicon-var-ios-game-controller-b-outline;
}
.#{$ionicons-prefix}ios-gear:before {
  content: $ionicon-var-ios-gear;
}
.#{$ionicons-prefix}ios-gear-outline:before {
  content: $ionicon-var-ios-gear-outline;
}
.#{$ionicons-prefix}ios-glasses:before {
  content: $ionicon-var-ios-glasses;
}
.#{$ionicons-prefix}ios-glasses-outline:before {
  content: $ionicon-var-ios-glasses-outline;
}
.#{$ionicons-prefix}ios-grid-view:before {
  content: $ionicon-var-ios-grid-view;
}
.#{$ionicons-prefix}ios-grid-view-outline:before {
  content: $ionicon-var-ios-grid-view-outline;
}
.#{$ionicons-prefix}ios-heart:before {
  content: $ionicon-var-ios-heart;
}
.#{$ionicons-prefix}ios-heart-outline:before {
  content: $ionicon-var-ios-heart-outline;
}
.#{$ionicons-prefix}ios-help:before {
  content: $ionicon-var-ios-help;
}
.#{$ionicons-prefix}ios-help-empty:before {
  content: $ionicon-var-ios-help-empty;
}
.#{$ionicons-prefix}ios-help-outline:before {
  content: $ionicon-var-ios-help-outline;
}
.#{$ionicons-prefix}ios-home:before {
  content: $ionicon-var-ios-home;
}
.#{$ionicons-prefix}ios-home-outline:before {
  content: $ionicon-var-ios-home-outline;
}
.#{$ionicons-prefix}ios-infinite:before {
  content: $ionicon-var-ios-infinite;
}
.#{$ionicons-prefix}ios-infinite-outline:before {
  content: $ionicon-var-ios-infinite-outline;
}
.#{$ionicons-prefix}ios-information:before {
  content: $ionicon-var-ios-information;
}
.#{$ionicons-prefix}ios-information-empty:before {
  content: $ionicon-var-ios-information-empty;
}
.#{$ionicons-prefix}ios-information-outline:before {
  content: $ionicon-var-ios-information-outline;
}
.#{$ionicons-prefix}ios-ionic-outline:before {
  content: $ionicon-var-ios-ionic-outline;
}
.#{$ionicons-prefix}ios-keypad:before {
  content: $ionicon-var-ios-keypad;
}
.#{$ionicons-prefix}ios-keypad-outline:before {
  content: $ionicon-var-ios-keypad-outline;
}
.#{$ionicons-prefix}ios-lightbulb:before {
  content: $ionicon-var-ios-lightbulb;
}
.#{$ionicons-prefix}ios-lightbulb-outline:before {
  content: $ionicon-var-ios-lightbulb-outline;
}
.#{$ionicons-prefix}ios-list:before {
  content: $ionicon-var-ios-list;
}
.#{$ionicons-prefix}ios-list-outline:before {
  content: $ionicon-var-ios-list-outline;
}
.#{$ionicons-prefix}ios-location:before {
  content: $ionicon-var-ios-location;
}
.#{$ionicons-prefix}ios-location-outline:before {
  content: $ionicon-var-ios-location-outline;
}
.#{$ionicons-prefix}ios-locked:before {
  content: $ionicon-var-ios-locked;
}
.#{$ionicons-prefix}ios-locked-outline:before {
  content: $ionicon-var-ios-locked-outline;
}
.#{$ionicons-prefix}ios-loop:before {
  content: $ionicon-var-ios-loop;
}
.#{$ionicons-prefix}ios-loop-strong:before {
  content: $ionicon-var-ios-loop-strong;
}
.#{$ionicons-prefix}ios-medical:before {
  content: $ionicon-var-ios-medical;
}
.#{$ionicons-prefix}ios-medical-outline:before {
  content: $ionicon-var-ios-medical-outline;
}
.#{$ionicons-prefix}ios-medkit:before {
  content: $ionicon-var-ios-medkit;
}
.#{$ionicons-prefix}ios-medkit-outline:before {
  content: $ionicon-var-ios-medkit-outline;
}
.#{$ionicons-prefix}ios-mic:before {
  content: $ionicon-var-ios-mic;
}
.#{$ionicons-prefix}ios-mic-off:before {
  content: $ionicon-var-ios-mic-off;
}
.#{$ionicons-prefix}ios-mic-outline:before {
  content: $ionicon-var-ios-mic-outline;
}
.#{$ionicons-prefix}ios-minus:before {
  content: $ionicon-var-ios-minus;
}
.#{$ionicons-prefix}ios-minus-empty:before {
  content: $ionicon-var-ios-minus-empty;
}
.#{$ionicons-prefix}ios-minus-outline:before {
  content: $ionicon-var-ios-minus-outline;
}
.#{$ionicons-prefix}ios-monitor:before {
  content: $ionicon-var-ios-monitor;
}
.#{$ionicons-prefix}ios-monitor-outline:before {
  content: $ionicon-var-ios-monitor-outline;
}
.#{$ionicons-prefix}ios-moon:before {
  content: $ionicon-var-ios-moon;
}
.#{$ionicons-prefix}ios-moon-outline:before {
  content: $ionicon-var-ios-moon-outline;
}
.#{$ionicons-prefix}ios-more:before {
  content: $ionicon-var-ios-more;
}
.#{$ionicons-prefix}ios-more-outline:before {
  content: $ionicon-var-ios-more-outline;
}
.#{$ionicons-prefix}ios-musical-note:before {
  content: $ionicon-var-ios-musical-note;
}
.#{$ionicons-prefix}ios-musical-notes:before {
  content: $ionicon-var-ios-musical-notes;
}
.#{$ionicons-prefix}ios-navigate:before {
  content: $ionicon-var-ios-navigate;
}
.#{$ionicons-prefix}ios-navigate-outline:before {
  content: $ionicon-var-ios-navigate-outline;
}
.#{$ionicons-prefix}ios-nutrition:before {
  content: $ionicon-var-ios-nutrition;
}
.#{$ionicons-prefix}ios-nutrition-outline:before {
  content: $ionicon-var-ios-nutrition-outline;
}
.#{$ionicons-prefix}ios-paper:before {
  content: $ionicon-var-ios-paper;
}
.#{$ionicons-prefix}ios-paper-outline:before {
  content: $ionicon-var-ios-paper-outline;
}
.#{$ionicons-prefix}ios-paperplane:before {
  content: $ionicon-var-ios-paperplane;
}
.#{$ionicons-prefix}ios-paperplane-outline:before {
  content: $ionicon-var-ios-paperplane-outline;
}
.#{$ionicons-prefix}ios-partlysunny:before {
  content: $ionicon-var-ios-partlysunny;
}
.#{$ionicons-prefix}ios-partlysunny-outline:before {
  content: $ionicon-var-ios-partlysunny-outline;
}
.#{$ionicons-prefix}ios-pause:before {
  content: $ionicon-var-ios-pause;
}
.#{$ionicons-prefix}ios-pause-outline:before {
  content: $ionicon-var-ios-pause-outline;
}
.#{$ionicons-prefix}ios-paw:before {
  content: $ionicon-var-ios-paw;
}
.#{$ionicons-prefix}ios-paw-outline:before {
  content: $ionicon-var-ios-paw-outline;
}
.#{$ionicons-prefix}ios-people:before {
  content: $ionicon-var-ios-people;
}
.#{$ionicons-prefix}ios-people-outline:before {
  content: $ionicon-var-ios-people-outline;
}
.#{$ionicons-prefix}ios-person:before {
  content: $ionicon-var-ios-person;
}
.#{$ionicons-prefix}ios-person-outline:before {
  content: $ionicon-var-ios-person-outline;
}
.#{$ionicons-prefix}ios-personadd:before {
  content: $ionicon-var-ios-personadd;
}
.#{$ionicons-prefix}ios-personadd-outline:before {
  content: $ionicon-var-ios-personadd-outline;
}
.#{$ionicons-prefix}ios-photos:before {
  content: $ionicon-var-ios-photos;
}
.#{$ionicons-prefix}ios-photos-outline:before {
  content: $ionicon-var-ios-photos-outline;
}
.#{$ionicons-prefix}ios-pie:before {
  content: $ionicon-var-ios-pie;
}
.#{$ionicons-prefix}ios-pie-outline:before {
  content: $ionicon-var-ios-pie-outline;
}
.#{$ionicons-prefix}ios-pint:before {
  content: $ionicon-var-ios-pint;
}
.#{$ionicons-prefix}ios-pint-outline:before {
  content: $ionicon-var-ios-pint-outline;
}
.#{$ionicons-prefix}ios-play:before {
  content: $ionicon-var-ios-play;
}
.#{$ionicons-prefix}ios-play-outline:before {
  content: $ionicon-var-ios-play-outline;
}
.#{$ionicons-prefix}ios-plus:before {
  content: $ionicon-var-ios-plus;
}
.#{$ionicons-prefix}ios-plus-empty:before {
  content: $ionicon-var-ios-plus-empty;
}
.#{$ionicons-prefix}ios-plus-outline:before {
  content: $ionicon-var-ios-plus-outline;
}
.#{$ionicons-prefix}ios-pricetag:before {
  content: $ionicon-var-ios-pricetag;
}
.#{$ionicons-prefix}ios-pricetag-outline:before {
  content: $ionicon-var-ios-pricetag-outline;
}
.#{$ionicons-prefix}ios-pricetags:before {
  content: $ionicon-var-ios-pricetags;
}
.#{$ionicons-prefix}ios-pricetags-outline:before {
  content: $ionicon-var-ios-pricetags-outline;
}
.#{$ionicons-prefix}ios-printer:before {
  content: $ionicon-var-ios-printer;
}
.#{$ionicons-prefix}ios-printer-outline:before {
  content: $ionicon-var-ios-printer-outline;
}
.#{$ionicons-prefix}ios-pulse:before {
  content: $ionicon-var-ios-pulse;
}
.#{$ionicons-prefix}ios-pulse-strong:before {
  content: $ionicon-var-ios-pulse-strong;
}
.#{$ionicons-prefix}ios-rainy:before {
  content: $ionicon-var-ios-rainy;
}
.#{$ionicons-prefix}ios-rainy-outline:before {
  content: $ionicon-var-ios-rainy-outline;
}
.#{$ionicons-prefix}ios-recording:before {
  content: $ionicon-var-ios-recording;
}
.#{$ionicons-prefix}ios-recording-outline:before {
  content: $ionicon-var-ios-recording-outline;
}
.#{$ionicons-prefix}ios-redo:before {
  content: $ionicon-var-ios-redo;
}
.#{$ionicons-prefix}ios-redo-outline:before {
  content: $ionicon-var-ios-redo-outline;
}
.#{$ionicons-prefix}ios-refresh:before {
  content: $ionicon-var-ios-refresh;
}
.#{$ionicons-prefix}ios-refresh-empty:before {
  content: $ionicon-var-ios-refresh-empty;
}
.#{$ionicons-prefix}ios-refresh-outline:before {
  content: $ionicon-var-ios-refresh-outline;
}
.#{$ionicons-prefix}ios-reload:before {
  content: $ionicon-var-ios-reload;
}
.#{$ionicons-prefix}ios-reverse-camera:before {
  content: $ionicon-var-ios-reverse-camera;
}
.#{$ionicons-prefix}ios-reverse-camera-outline:before {
  content: $ionicon-var-ios-reverse-camera-outline;
}
.#{$ionicons-prefix}ios-rewind:before {
  content: $ionicon-var-ios-rewind;
}
.#{$ionicons-prefix}ios-rewind-outline:before {
  content: $ionicon-var-ios-rewind-outline;
}
.#{$ionicons-prefix}ios-rose:before {
  content: $ionicon-var-ios-rose;
}
.#{$ionicons-prefix}ios-rose-outline:before {
  content: $ionicon-var-ios-rose-outline;
}
.#{$ionicons-prefix}ios-search:before {
  content: $ionicon-var-ios-search;
}
.#{$ionicons-prefix}ios-search-strong:before {
  content: $ionicon-var-ios-search-strong;
}
.#{$ionicons-prefix}ios-settings:before {
  content: $ionicon-var-ios-settings;
}
.#{$ionicons-prefix}ios-settings-strong:before {
  content: $ionicon-var-ios-settings-strong;
}
.#{$ionicons-prefix}ios-shuffle:before {
  content: $ionicon-var-ios-shuffle;
}
.#{$ionicons-prefix}ios-shuffle-strong:before {
  content: $ionicon-var-ios-shuffle-strong;
}
.#{$ionicons-prefix}ios-skipbackward:before {
  content: $ionicon-var-ios-skipbackward;
}
.#{$ionicons-prefix}ios-skipbackward-outline:before {
  content: $ionicon-var-ios-skipbackward-outline;
}
.#{$ionicons-prefix}ios-skipforward:before {
  content: $ionicon-var-ios-skipforward;
}
.#{$ionicons-prefix}ios-skipforward-outline:before {
  content: $ionicon-var-ios-skipforward-outline;
}
.#{$ionicons-prefix}ios-snowy:before {
  content: $ionicon-var-ios-snowy;
}
.#{$ionicons-prefix}ios-speedometer:before {
  content: $ionicon-var-ios-speedometer;
}
.#{$ionicons-prefix}ios-speedometer-outline:before {
  content: $ionicon-var-ios-speedometer-outline;
}
.#{$ionicons-prefix}ios-star:before {
  content: $ionicon-var-ios-star;
}
.#{$ionicons-prefix}ios-star-half:before {
  content: $ionicon-var-ios-star-half;
}
.#{$ionicons-prefix}ios-star-outline:before {
  content: $ionicon-var-ios-star-outline;
}
.#{$ionicons-prefix}ios-stopwatch:before {
  content: $ionicon-var-ios-stopwatch;
}
.#{$ionicons-prefix}ios-stopwatch-outline:before {
  content: $ionicon-var-ios-stopwatch-outline;
}
.#{$ionicons-prefix}ios-sunny:before {
  content: $ionicon-var-ios-sunny;
}
.#{$ionicons-prefix}ios-sunny-outline:before {
  content: $ionicon-var-ios-sunny-outline;
}
.#{$ionicons-prefix}ios-telephone:before {
  content: $ionicon-var-ios-telephone;
}
.#{$ionicons-prefix}ios-telephone-outline:before {
  content: $ionicon-var-ios-telephone-outline;
}
.#{$ionicons-prefix}ios-tennisball:before {
  content: $ionicon-var-ios-tennisball;
}
.#{$ionicons-prefix}ios-tennisball-outline:before {
  content: $ionicon-var-ios-tennisball-outline;
}
.#{$ionicons-prefix}ios-thunderstorm:before {
  content: $ionicon-var-ios-thunderstorm;
}
.#{$ionicons-prefix}ios-thunderstorm-outline:before {
  content: $ionicon-var-ios-thunderstorm-outline;
}
.#{$ionicons-prefix}ios-time:before {
  content: $ionicon-var-ios-time;
}
.#{$ionicons-prefix}ios-time-outline:before {
  content: $ionicon-var-ios-time-outline;
}
.#{$ionicons-prefix}ios-timer:before {
  content: $ionicon-var-ios-timer;
}
.#{$ionicons-prefix}ios-timer-outline:before {
  content: $ionicon-var-ios-timer-outline;
}
.#{$ionicons-prefix}ios-toggle:before {
  content: $ionicon-var-ios-toggle;
}
.#{$ionicons-prefix}ios-toggle-outline:before {
  content: $ionicon-var-ios-toggle-outline;
}
.#{$ionicons-prefix}ios-trash:before {
  content: $ionicon-var-ios-trash;
}
.#{$ionicons-prefix}ios-trash-outline:before {
  content: $ionicon-var-ios-trash-outline;
}
.#{$ionicons-prefix}ios-undo:before {
  content: $ionicon-var-ios-undo;
}
.#{$ionicons-prefix}ios-undo-outline:before {
  content: $ionicon-var-ios-undo-outline;
}
.#{$ionicons-prefix}ios-unlocked:before {
  content: $ionicon-var-ios-unlocked;
}
.#{$ionicons-prefix}ios-unlocked-outline:before {
  content: $ionicon-var-ios-unlocked-outline;
}
.#{$ionicons-prefix}ios-upload:before {
  content: $ionicon-var-ios-upload;
}
.#{$ionicons-prefix}ios-upload-outline:before {
  content: $ionicon-var-ios-upload-outline;
}
.#{$ionicons-prefix}ios-videocam:before {
  content: $ionicon-var-ios-videocam;
}
.#{$ionicons-prefix}ios-videocam-outline:before {
  content: $ionicon-var-ios-videocam-outline;
}
.#{$ionicons-prefix}ios-volume-high:before {
  content: $ionicon-var-ios-volume-high;
}
.#{$ionicons-prefix}ios-volume-low:before {
  content: $ionicon-var-ios-volume-low;
}
.#{$ionicons-prefix}ios-wineglass:before {
  content: $ionicon-var-ios-wineglass;
}
.#{$ionicons-prefix}ios-wineglass-outline:before {
  content: $ionicon-var-ios-wineglass-outline;
}
.#{$ionicons-prefix}ios-world:before {
  content: $ionicon-var-ios-world;
}
.#{$ionicons-prefix}ios-world-outline:before {
  content: $ionicon-var-ios-world-outline;
}
.#{$ionicons-prefix}ipad:before {
  content: $ionicon-var-ipad;
}
.#{$ionicons-prefix}iphone:before {
  content: $ionicon-var-iphone;
}
.#{$ionicons-prefix}ipod:before {
  content: $ionicon-var-ipod;
}
.#{$ionicons-prefix}jet:before {
  content: $ionicon-var-jet;
}
.#{$ionicons-prefix}key:before {
  content: $ionicon-var-key;
}
.#{$ionicons-prefix}knife:before {
  content: $ionicon-var-knife;
}
.#{$ionicons-prefix}laptop:before {
  content: $ionicon-var-laptop;
}
.#{$ionicons-prefix}leaf:before {
  content: $ionicon-var-leaf;
}
.#{$ionicons-prefix}levels:before {
  content: $ionicon-var-levels;
}
.#{$ionicons-prefix}lightbulb:before {
  content: $ionicon-var-lightbulb;
}
.#{$ionicons-prefix}link:before {
  content: $ionicon-var-link;
}
.#{$ionicons-prefix}load-a:before {
  content: $ionicon-var-load-a;
}
.#{$ionicons-prefix}load-b:before {
  content: $ionicon-var-load-b;
}
.#{$ionicons-prefix}load-c:before {
  content: $ionicon-var-load-c;
}
.#{$ionicons-prefix}load-d:before {
  content: $ionicon-var-load-d;
}
.#{$ionicons-prefix}location:before {
  content: $ionicon-var-location;
}
.#{$ionicons-prefix}lock-combination:before {
  content: $ionicon-var-lock-combination;
}
.#{$ionicons-prefix}locked:before {
  content: $ionicon-var-locked;
}
.#{$ionicons-prefix}log-in:before {
  content: $ionicon-var-log-in;
}
.#{$ionicons-prefix}log-out:before {
  content: $ionicon-var-log-out;
}
.#{$ionicons-prefix}loop:before {
  content: $ionicon-var-loop;
}
.#{$ionicons-prefix}magnet:before {
  content: $ionicon-var-magnet;
}
.#{$ionicons-prefix}male:before {
  content: $ionicon-var-male;
}
.#{$ionicons-prefix}man:before {
  content: $ionicon-var-man;
}
.#{$ionicons-prefix}map:before {
  content: $ionicon-var-map;
}
.#{$ionicons-prefix}medkit:before {
  content: $ionicon-var-medkit;
}
.#{$ionicons-prefix}merge:before {
  content: $ionicon-var-merge;
}
.#{$ionicons-prefix}mic-a:before {
  content: $ionicon-var-mic-a;
}
.#{$ionicons-prefix}mic-b:before {
  content: $ionicon-var-mic-b;
}
.#{$ionicons-prefix}mic-c:before {
  content: $ionicon-var-mic-c;
}
.#{$ionicons-prefix}minus:before {
  content: $ionicon-var-minus;
}
.#{$ionicons-prefix}minus-circled:before {
  content: $ionicon-var-minus-circled;
}
.#{$ionicons-prefix}minus-round:before {
  content: $ionicon-var-minus-round;
}
.#{$ionicons-prefix}model-s:before {
  content: $ionicon-var-model-s;
}
.#{$ionicons-prefix}monitor:before {
  content: $ionicon-var-monitor;
}
.#{$ionicons-prefix}more:before {
  content: $ionicon-var-more;
}
.#{$ionicons-prefix}mouse:before {
  content: $ionicon-var-mouse;
}
.#{$ionicons-prefix}music-note:before {
  content: $ionicon-var-music-note;
}
.#{$ionicons-prefix}navicon:before {
  content: $ionicon-var-navicon;
}
.#{$ionicons-prefix}navicon-round:before {
  content: $ionicon-var-navicon-round;
}
.#{$ionicons-prefix}navigate:before {
  content: $ionicon-var-navigate;
}
.#{$ionicons-prefix}network:before {
  content: $ionicon-var-network;
}
.#{$ionicons-prefix}no-smoking:before {
  content: $ionicon-var-no-smoking;
}
.#{$ionicons-prefix}nuclear:before {
  content: $ionicon-var-nuclear;
}
.#{$ionicons-prefix}outlet:before {
  content: $ionicon-var-outlet;
}
.#{$ionicons-prefix}paintbrush:before {
  content: $ionicon-var-paintbrush;
}
.#{$ionicons-prefix}paintbucket:before {
  content: $ionicon-var-paintbucket;
}
.#{$ionicons-prefix}paper-airplane:before {
  content: $ionicon-var-paper-airplane;
}
.#{$ionicons-prefix}paperclip:before {
  content: $ionicon-var-paperclip;
}
.#{$ionicons-prefix}pause:before {
  content: $ionicon-var-pause;
}
.#{$ionicons-prefix}person:before {
  content: $ionicon-var-person;
}
.#{$ionicons-prefix}person-add:before {
  content: $ionicon-var-person-add;
}
.#{$ionicons-prefix}person-stalker:before {
  content: $ionicon-var-person-stalker;
}
.#{$ionicons-prefix}pie-graph:before {
  content: $ionicon-var-pie-graph;
}
.#{$ionicons-prefix}pin:before {
  content: $ionicon-var-pin;
}
.#{$ionicons-prefix}pinpoint:before {
  content: $ionicon-var-pinpoint;
}
.#{$ionicons-prefix}pizza:before {
  content: $ionicon-var-pizza;
}
.#{$ionicons-prefix}plane:before {
  content: $ionicon-var-plane;
}
.#{$ionicons-prefix}planet:before {
  content: $ionicon-var-planet;
}
.#{$ionicons-prefix}play:before {
  content: $ionicon-var-play;
}
.#{$ionicons-prefix}playstation:before {
  content: $ionicon-var-playstation;
}
.#{$ionicons-prefix}plus:before {
  content: $ionicon-var-plus;
}
.#{$ionicons-prefix}plus-circled:before {
  content: $ionicon-var-plus-circled;
}
.#{$ionicons-prefix}plus-round:before {
  content: $ionicon-var-plus-round;
}
.#{$ionicons-prefix}podium:before {
  content: $ionicon-var-podium;
}
.#{$ionicons-prefix}pound:before {
  content: $ionicon-var-pound;
}
.#{$ionicons-prefix}power:before {
  content: $ionicon-var-power;
}
.#{$ionicons-prefix}pricetag:before {
  content: $ionicon-var-pricetag;
}
.#{$ionicons-prefix}pricetags:before {
  content: $ionicon-var-pricetags;
}
.#{$ionicons-prefix}printer:before {
  content: $ionicon-var-printer;
}
.#{$ionicons-prefix}pull-request:before {
  content: $ionicon-var-pull-request;
}
.#{$ionicons-prefix}qr-scanner:before {
  content: $ionicon-var-qr-scanner;
}
.#{$ionicons-prefix}quote:before {
  content: $ionicon-var-quote;
}
.#{$ionicons-prefix}radio-waves:before {
  content: $ionicon-var-radio-waves;
}
.#{$ionicons-prefix}record:before {
  content: $ionicon-var-record;
}
.#{$ionicons-prefix}refresh:before {
  content: $ionicon-var-refresh;
}
.#{$ionicons-prefix}reply:before {
  content: $ionicon-var-reply;
}
.#{$ionicons-prefix}reply-all:before {
  content: $ionicon-var-reply-all;
}
.#{$ionicons-prefix}ribbon-a:before {
  content: $ionicon-var-ribbon-a;
}
.#{$ionicons-prefix}ribbon-b:before {
  content: $ionicon-var-ribbon-b;
}
.#{$ionicons-prefix}sad:before {
  content: $ionicon-var-sad;
}
.#{$ionicons-prefix}sad-outline:before {
  content: $ionicon-var-sad-outline;
}
.#{$ionicons-prefix}scissors:before {
  content: $ionicon-var-scissors;
}
.#{$ionicons-prefix}search:before {
  content: $ionicon-var-search;
}
.#{$ionicons-prefix}settings:before {
  content: $ionicon-var-settings;
}
.#{$ionicons-prefix}share:before {
  content: $ionicon-var-share;
}
.#{$ionicons-prefix}shuffle:before {
  content: $ionicon-var-shuffle;
}
.#{$ionicons-prefix}skip-backward:before {
  content: $ionicon-var-skip-backward;
}
.#{$ionicons-prefix}skip-forward:before {
  content: $ionicon-var-skip-forward;
}
.#{$ionicons-prefix}social-android:before {
  content: $ionicon-var-social-android;
}
.#{$ionicons-prefix}social-android-outline:before {
  content: $ionicon-var-social-android-outline;
}
.#{$ionicons-prefix}social-angular:before {
  content: $ionicon-var-social-angular;
}
.#{$ionicons-prefix}social-angular-outline:before {
  content: $ionicon-var-social-angular-outline;
}
.#{$ionicons-prefix}social-apple:before {
  content: $ionicon-var-social-apple;
}
.#{$ionicons-prefix}social-apple-outline:before {
  content: $ionicon-var-social-apple-outline;
}
.#{$ionicons-prefix}social-bitcoin:before {
  content: $ionicon-var-social-bitcoin;
}
.#{$ionicons-prefix}social-bitcoin-outline:before {
  content: $ionicon-var-social-bitcoin-outline;
}
.#{$ionicons-prefix}social-buffer:before {
  content: $ionicon-var-social-buffer;
}
.#{$ionicons-prefix}social-buffer-outline:before {
  content: $ionicon-var-social-buffer-outline;
}
.#{$ionicons-prefix}social-chrome:before {
  content: $ionicon-var-social-chrome;
}
.#{$ionicons-prefix}social-chrome-outline:before {
  content: $ionicon-var-social-chrome-outline;
}
.#{$ionicons-prefix}social-codepen:before {
  content: $ionicon-var-social-codepen;
}
.#{$ionicons-prefix}social-codepen-outline:before {
  content: $ionicon-var-social-codepen-outline;
}
.#{$ionicons-prefix}social-css3:before {
  content: $ionicon-var-social-css3;
}
.#{$ionicons-prefix}social-css3-outline:before {
  content: $ionicon-var-social-css3-outline;
}
.#{$ionicons-prefix}social-designernews:before {
  content: $ionicon-var-social-designernews;
}
.#{$ionicons-prefix}social-designernews-outline:before {
  content: $ionicon-var-social-designernews-outline;
}
.#{$ionicons-prefix}social-dribbble:before {
  content: $ionicon-var-social-dribbble;
}
.#{$ionicons-prefix}social-dribbble-outline:before {
  content: $ionicon-var-social-dribbble-outline;
}
.#{$ionicons-prefix}social-dropbox:before {
  content: $ionicon-var-social-dropbox;
}
.#{$ionicons-prefix}social-dropbox-outline:before {
  content: $ionicon-var-social-dropbox-outline;
}
.#{$ionicons-prefix}social-euro:before {
  content: $ionicon-var-social-euro;
}
.#{$ionicons-prefix}social-euro-outline:before {
  content: $ionicon-var-social-euro-outline;
}
.#{$ionicons-prefix}social-facebook:before {
  content: $ionicon-var-social-facebook;
}
.#{$ionicons-prefix}social-facebook-outline:before {
  content: $ionicon-var-social-facebook-outline;
}
.#{$ionicons-prefix}social-foursquare:before {
  content: $ionicon-var-social-foursquare;
}
.#{$ionicons-prefix}social-foursquare-outline:before {
  content: $ionicon-var-social-foursquare-outline;
}
.#{$ionicons-prefix}social-freebsd-devil:before {
  content: $ionicon-var-social-freebsd-devil;
}
.#{$ionicons-prefix}social-github:before {
  content: $ionicon-var-social-github;
}
.#{$ionicons-prefix}social-github-outline:before {
  content: $ionicon-var-social-github-outline;
}
.#{$ionicons-prefix}social-google:before {
  content: $ionicon-var-social-google;
}
.#{$ionicons-prefix}social-google-outline:before {
  content: $ionicon-var-social-google-outline;
}
.#{$ionicons-prefix}social-googleplus:before {
  content: $ionicon-var-social-googleplus;
}
.#{$ionicons-prefix}social-googleplus-outline:before {
  content: $ionicon-var-social-googleplus-outline;
}
.#{$ionicons-prefix}social-hackernews:before {
  content: $ionicon-var-social-hackernews;
}
.#{$ionicons-prefix}social-hackernews-outline:before {
  content: $ionicon-var-social-hackernews-outline;
}
.#{$ionicons-prefix}social-html5:before {
  content: $ionicon-var-social-html5;
}
.#{$ionicons-prefix}social-html5-outline:before {
  content: $ionicon-var-social-html5-outline;
}
.#{$ionicons-prefix}social-instagram:before {
  content: $ionicon-var-social-instagram;
}
.#{$ionicons-prefix}social-instagram-outline:before {
  content: $ionicon-var-social-instagram-outline;
}
.#{$ionicons-prefix}social-javascript:before {
  content: $ionicon-var-social-javascript;
}
.#{$ionicons-prefix}social-javascript-outline:before {
  content: $ionicon-var-social-javascript-outline;
}
.#{$ionicons-prefix}social-linkedin:before {
  content: $ionicon-var-social-linkedin;
}
.#{$ionicons-prefix}social-linkedin-outline:before {
  content: $ionicon-var-social-linkedin-outline;
}
.#{$ionicons-prefix}social-markdown:before {
  content: $ionicon-var-social-markdown;
}
.#{$ionicons-prefix}social-nodejs:before {
  content: $ionicon-var-social-nodejs;
}
.#{$ionicons-prefix}social-octocat:before {
  content: $ionicon-var-social-octocat;
}
.#{$ionicons-prefix}social-pinterest:before {
  content: $ionicon-var-social-pinterest;
}
.#{$ionicons-prefix}social-pinterest-outline:before {
  content: $ionicon-var-social-pinterest-outline;
}
.#{$ionicons-prefix}social-python:before {
  content: $ionicon-var-social-python;
}
.#{$ionicons-prefix}social-reddit:before {
  content: $ionicon-var-social-reddit;
}
.#{$ionicons-prefix}social-reddit-outline:before {
  content: $ionicon-var-social-reddit-outline;
}
.#{$ionicons-prefix}social-rss:before {
  content: $ionicon-var-social-rss;
}
.#{$ionicons-prefix}social-rss-outline:before {
  content: $ionicon-var-social-rss-outline;
}
.#{$ionicons-prefix}social-sass:before {
  content: $ionicon-var-social-sass;
}
.#{$ionicons-prefix}social-skype:before {
  content: $ionicon-var-social-skype;
}
.#{$ionicons-prefix}social-skype-outline:before {
  content: $ionicon-var-social-skype-outline;
}
.#{$ionicons-prefix}social-snapchat:before {
  content: $ionicon-var-social-snapchat;
}
.#{$ionicons-prefix}social-snapchat-outline:before {
  content: $ionicon-var-social-snapchat-outline;
}
.#{$ionicons-prefix}social-tumblr:before {
  content: $ionicon-var-social-tumblr;
}
.#{$ionicons-prefix}social-tumblr-outline:before {
  content: $ionicon-var-social-tumblr-outline;
}
.#{$ionicons-prefix}social-tux:before {
  content: $ionicon-var-social-tux;
}
.#{$ionicons-prefix}social-twitch:before {
  content: $ionicon-var-social-twitch;
}
.#{$ionicons-prefix}social-twitch-outline:before {
  content: $ionicon-var-social-twitch-outline;
}
.#{$ionicons-prefix}social-twitter:before {
  content: $ionicon-var-social-twitter;
}
.#{$ionicons-prefix}social-twitter-outline:before {
  content: $ionicon-var-social-twitter-outline;
}
.#{$ionicons-prefix}social-usd:before {
  content: $ionicon-var-social-usd;
}
.#{$ionicons-prefix}social-usd-outline:before {
  content: $ionicon-var-social-usd-outline;
}
.#{$ionicons-prefix}social-vimeo:before {
  content: $ionicon-var-social-vimeo;
}
.#{$ionicons-prefix}social-vimeo-outline:before {
  content: $ionicon-var-social-vimeo-outline;
}
.#{$ionicons-prefix}social-whatsapp:before {
  content: $ionicon-var-social-whatsapp;
}
.#{$ionicons-prefix}social-whatsapp-outline:before {
  content: $ionicon-var-social-whatsapp-outline;
}
.#{$ionicons-prefix}social-windows:before {
  content: $ionicon-var-social-windows;
}
.#{$ionicons-prefix}social-windows-outline:before {
  content: $ionicon-var-social-windows-outline;
}
.#{$ionicons-prefix}social-wordpress:before {
  content: $ionicon-var-social-wordpress;
}
.#{$ionicons-prefix}social-wordpress-outline:before {
  content: $ionicon-var-social-wordpress-outline;
}
.#{$ionicons-prefix}social-yahoo:before {
  content: $ionicon-var-social-yahoo;
}
.#{$ionicons-prefix}social-yahoo-outline:before {
  content: $ionicon-var-social-yahoo-outline;
}
.#{$ionicons-prefix}social-yen:before {
  content: $ionicon-var-social-yen;
}
.#{$ionicons-prefix}social-yen-outline:before {
  content: $ionicon-var-social-yen-outline;
}
.#{$ionicons-prefix}social-youtube:before {
  content: $ionicon-var-social-youtube;
}
.#{$ionicons-prefix}social-youtube-outline:before {
  content: $ionicon-var-social-youtube-outline;
}
.#{$ionicons-prefix}soup-can:before {
  content: $ionicon-var-soup-can;
}
.#{$ionicons-prefix}soup-can-outline:before {
  content: $ionicon-var-soup-can-outline;
}
.#{$ionicons-prefix}speakerphone:before {
  content: $ionicon-var-speakerphone;
}
.#{$ionicons-prefix}speedometer:before {
  content: $ionicon-var-speedometer;
}
.#{$ionicons-prefix}spoon:before {
  content: $ionicon-var-spoon;
}
.#{$ionicons-prefix}star:before {
  content: $ionicon-var-star;
}
.#{$ionicons-prefix}stats-bars:before {
  content: $ionicon-var-stats-bars;
}
.#{$ionicons-prefix}steam:before {
  content: $ionicon-var-steam;
}
.#{$ionicons-prefix}stop:before {
  content: $ionicon-var-stop;
}
.#{$ionicons-prefix}thermometer:before {
  content: $ionicon-var-thermometer;
}
.#{$ionicons-prefix}thumbsdown:before {
  content: $ionicon-var-thumbsdown;
}
.#{$ionicons-prefix}thumbsup:before {
  content: $ionicon-var-thumbsup;
}
.#{$ionicons-prefix}toggle:before {
  content: $ionicon-var-toggle;
}
.#{$ionicons-prefix}toggle-filled:before {
  content: $ionicon-var-toggle-filled;
}
.#{$ionicons-prefix}transgender:before {
  content: $ionicon-var-transgender;
}
.#{$ionicons-prefix}trash-a:before {
  content: $ionicon-var-trash-a;
}
.#{$ionicons-prefix}trash-b:before {
  content: $ionicon-var-trash-b;
}
.#{$ionicons-prefix}trophy:before {
  content: $ionicon-var-trophy;
}
.#{$ionicons-prefix}tshirt:before {
  content: $ionicon-var-tshirt;
}
.#{$ionicons-prefix}tshirt-outline:before {
  content: $ionicon-var-tshirt-outline;
}
.#{$ionicons-prefix}umbrella:before {
  content: $ionicon-var-umbrella;
}
.#{$ionicons-prefix}university:before {
  content: $ionicon-var-university;
}
.#{$ionicons-prefix}unlocked:before {
  content: $ionicon-var-unlocked;
}
.#{$ionicons-prefix}upload:before {
  content: $ionicon-var-upload;
}
.#{$ionicons-prefix}usb:before {
  content: $ionicon-var-usb;
}
.#{$ionicons-prefix}videocamera:before {
  content: $ionicon-var-videocamera;
}
.#{$ionicons-prefix}volume-high:before {
  content: $ionicon-var-volume-high;
}
.#{$ionicons-prefix}volume-low:before {
  content: $ionicon-var-volume-low;
}
.#{$ionicons-prefix}volume-medium:before {
  content: $ionicon-var-volume-medium;
}
.#{$ionicons-prefix}volume-mute:before {
  content: $ionicon-var-volume-mute;
}
.#{$ionicons-prefix}wand:before {
  content: $ionicon-var-wand;
}
.#{$ionicons-prefix}waterdrop:before {
  content: $ionicon-var-waterdrop;
}
.#{$ionicons-prefix}wifi:before {
  content: $ionicon-var-wifi;
}
.#{$ionicons-prefix}wineglass:before {
  content: $ionicon-var-wineglass;
}
.#{$ionicons-prefix}woman:before {
  content: $ionicon-var-woman;
}
.#{$ionicons-prefix}wrench:before {
  content: $ionicon-var-wrench;
}
.#{$ionicons-prefix}xbox:before {
  content: $ionicon-var-xbox;
}
