/**=====================
   47. theme layout CSS Start
==========================**/
//Dark layout
body {
  &.light-only {
    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          background-color: $light-color;
        }
      }
    }
  }

  &.dark-only {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    .cke_chrome {
      border: 1px solid $dark-border-color;
    }
    .loader-wrapper {
      .typewriter {
        h1 {
          color: $dark-all-font-color;
        }
      }
    }
    .basic-calendar{
      .fc-theme-standard{
        .fc-scrollgrid{
          border-color: $dark-border-color;
        }
        th, td{
          border-color: $dark-border-color;
        }
      }
    }
    .product-modal{
      .product-size{
        .btn{
          border-color: $dark-border-color;
        }
      }
      .product-qnty{
        .touchspin{
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
      }
    }
    .swal2-popup{
      background-color: $dark-card-background;
      .swal2-title, .swal2-input{
        color: $dark-all-font-color;
      }
      .swal2-html-container{
        color: $dark-small-font-color;
      }
    }  
    .mega-inline,
    .mega-vertical,
    .mega-horizontal {
      .card {
        border-color: $light-all-font-color;
      }
    }
    .classic_theme_container {
      .classic_time {
        color: $theme-body-font-color;
      }
    }
    ol {
      &.progtrckr {
        li {
          color: $white;
        }
      }
    }
    .welcome-popup{
      .modal-body{
        .modal-header{
          border-bottom: unset;
        }
      }
    }
    .contact-profile {
      .icon-wrapper {
        background-color: $dark-body-background;
      }
    }
    .note {
      textarea {
        color: $white;
      }
    }
    .rbt-input-multi {
      .rbt-input-main {
        color: $white;
      }
    }
    .rbt-aux {
      .rbt-close {
        color: $white;
      }
    }
    .rdt_TableHeader{
      background-color: $dark-body-background;
    }
    .table-border-vertical{
      tr,td,th{
        border-top: unset !important;
        border-bottom: unset !important;
      }
      thead{
        th{
          border-bottom: 2px solid $dark-border-color !important;
        }
      }
    }
    .table-border-vertical{
      &.table-bordered{
        th, td{
          border: 1px solid $dark-border-color !important;
        }
        th{
          border-bottom: 2px solid $dark-border-color !important;
        }
      }
    }
    .data-tables{
      .rdt_Table{
        .rdt_TableHead{
          .rdt_TableHeadRow{
            background-color: $dark-body-background;
          }
          .rdt_TableCol{
            color: $dark-all-font-color;
          }
        }
        .rdt_TableRow{
          color: $dark-all-font-color;
          &:nth-child(odd){
            background-color: $dark-card-background;
          }
          &:nth-child(even){
            background-color: $dark-body-background;
          }
        }
      }
      .rdt_Pagination{
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        svg{
          fill: $dark-all-font-color;
        }
      }
    }
    $border-name: primary, secondary, success, danger, info, light, dark, warning;
    $border-color: $primary-color, $secondary-color, $success-color, $danger-color,
      $info-color, $light-color, $dark-color, $warning-color;
    @each $var in $border-name {
      $i: index($border-name, $var);
      table thead .border-bottom-#{$var} th,
      table tbody .border-bottom-#{$var} th,
      table tbody .border-bottom-#{$var} td {
        border-bottom: 1px solid nth($border-color, $i) !important;
        border-top: unset !important;
      }
    }
    // calender page
    .rbc-toolbar {
      button {
        color: $dark-all-font-color;
        &:hover,
        &:focus {
          background-color: $dark-body-background;
        }
      }
    }
    .rbc-off-range-bg {
      background-color: $dark-body-background;
    }
    .rbc-month-view,
    .rbc-time-view {
      border: 1px solid $dark-border-color;
    }
    .rbc-header,
    .rbc-timeslot-group {
      border-bottom: 1px solid $dark-border-color;
    }
    .rbc-day-bg,
    .rbc-header {
      + .rbc-day-bg,
      + .rbc-header {
        border-left: 1px solid $dark-border-color;
      }
    }
    .rbc-month-row {
      + .rbc-month-row {
        border-top: 1px solid $dark-border-color;
      }
    }
    .rbc-time-header-content {
      border-left: 1px solid $dark-border-color;
    }
    .rbc-time-content {
      > * {
        + * {
          > * {
            border-left: 1px solid $dark-border-color;
          }
        }
      }
    }
    .rbc-day-slot {
      .rbc-time-slot {
        border-top: 1px solid $dark-border-color;
      }
    }
    .rbc-time-content {
      border-top: 2px solid $dark-border-color;
    }
    .rbc-agenda-view {
      table {
        &.rbc-agenda-table {
          border: 1px solid $dark-border-color;
          thead {
            > tr {
              > th {
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
          tbody {
            > tr {
              > td {
                + td {
                  border-left: 1px solid $dark-border-color;
                }
              }
              + tr {
                border-top: 1px solid $dark-border-color;
              }
            }
          }
        }
      }
    }
    .fc-unthemed {
      th,
      td,
      thead,
      tbody,
      .fc-divider,
      .fc-row,
      .fc-content,
      .fc-popover,
      .fc-list-view,
      .fc-list-heading td {
        border-color: $dark-border-color;
      }
      td {
        &.fc-today {
          background: $dark-body-background;
        }
      }
    }
    #external-events {
      border: 1px solid $dark-border-color;
    }
    .crm-activity{
      ul.dates{
        li{
          + li{
            border-left: 1px solid $dark-border-color;
          }
        }
      }
    }
    .date-picker{
      .react-datepicker{
        background-color: $dark-card-background;
        .react-datepicker__time{
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        .react-datepicker__time-list-item{
          &:hover{
            background-color: $dark-card-background !important;
          }
        }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
          color: $dark-all-font-color;
        }
        .react-datepicker__day{
          &:hover{
            background-color: $dark-body-background;
          }
        }
        .react-datepicker__header {
          border-bottom: 1px solid $dark-border-color;
        }
      }
    }
    .tables-wrapper{
      .table-responsive{
        tbody{
          tr{
            th,td{
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .border-end{
      border-right: 1px solid $dark-border-color !important;
    }
    .typeahead{
      .theme-form{
        .rbt{
          .close.rbt-close{
            color: $dark-all-font-color;
          }
        }
      }
    }
    .product-table, .order-history, .support-table{
      .rdt_Pagination{
        background-color: $dark-card-background;
        color: $dark-all-font-color;
        svg{
          fill: $dark-all-font-color;
        }
      }
      .rdt_Table{
        .rdt_TableBody{
          .rdt_TableRow{
            border-bottom: 1px solid $dark-border-color;
            color: $dark-all-font-color;
            &:last-child{
              border-bottom: unset;
            }
            &:nth-child(odd){
              .rdt_TableCell{
                background-color: $dark-card-background;
              }
            }
            &:nth-child(even){
              .rdt_TableCell{
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .rdt_Table{
      background-color: $dark-card-background;
      border: 1px solid $dark-border-color;
      color: $dark-all-font-color;
      .rdt_TableHeadRow{
        background-color: $dark-card-background;
        color: $dark-all-font-color;
        border-bottom: 1px solid $dark-border-color;
        .rdt_TableCol{
          color: $dark-all-font-color;
          .rdt_TableCol_Sortable{
            &:hover{
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .order-history{
      div.rdt_TableHeader{
        + div{
          &:nth-child(2){
            border: 1px solid $dark-border-color !important;
          }
        }
      }
      .rdt_Table{
        .rdt_TableBody{
          .rdt_TableCell{
            .product-name{
              a{
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .apexcharts-xaxistooltip, .apexcharts-yaxistooltip{
      color: $dark-all-font-color;
      background: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .apexcharts-xaxistooltip-bottom{
      &::after{
        border-bottom-color: $dark-border-color;
      }
    }
    .apexcharts-yaxistooltip-left{
      &::after{
        border-left-color: $dark-border-color;
      }
    }
    .rbt-token{
      background-color: $dark-card-background !important;
      color: $dark-all-font-color !important;
    }
    .mega-vertical{
      .card{
        border: 1px solid $dark-border-color;
      }
    }
    .draggable-card{
      .react-kanban-board{
        .react-kanban-column{
          background-color: $dark-body-background;
          .react-kanban-card{
            background-color: $dark-card-background;
            .react-kanban-card__title{
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    // ecommerce app page
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .order-history {
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .input-number {
      background-color: $dark-body-background !important;
    }
    .product-size {
      .btn-outline-light {
        color: $white;
      }
    }
    // google chart
    #reactgooglegraph-7,
    #reactgooglegraph-8,
    #reactgooglegraph-13 {
      svg {
        g {
          rect {
            fill: $transparent-color;
          }
        }
      }
    }
    #reactgooglegraph-5,
    #reactgooglegraph-6 {
      svg {
        rect {
          fill: $transparent-color;
        }
        g {
          text {
            fill: $white;
          }
        }
      }
    }
    #reactgooglegraph-9 {
      svg {
        g {
          rect[height="42"] {
            &:nth-child(odd) {
              fill: $dark-card-background;
            }
            &:nth-child(even) {
              fill: $dark-body-background;
            }
          }
          rect[height="300"] {
            fill: $dark-card-background;
          }
          line {
            ~ text {
              fill: $dark-all-font-color !important;
            }
          }
        }
      }
    }
    #reactgooglegraph-10,
    #reactgooglegraph-11 {
      svg {
        g {
          g {
            rect {
              ~ g {
                text {
                  fill: $dark-all-font-color !important;
                }
              }
            }
            g {
              text[y="428.6"],
              text[x="184"] {
                fill: $dark-all-font-color !important;
              }
              rect[height="1"] {
                fill: $light-all-font-color !important;
              }
            }
          }
          rect[height="350"] {
            ~ g {
              g {
                rect {
                  fill: $light-all-font-color !important;
                }
              }
            }
          }
          > text[y="67.6"] {
            fill: $dark-all-font-color !important;
          }
        }
      }
    }
    #reactgooglegraph-12 {
      svg {
        g {
          text[y="54.05"] {
            fill: $dark-all-font-color !important;
          }
          g {
            g {
              text[x="110"],
              text[y="343.05"] {
                fill: $dark-all-font-color !important;
              }
              rect[height="247"] {
                fill: $light-all-font-color !important;
              }
            }
          }
        }
      }
    }
    #reactgooglegraph-13 {
      svg {
        g {
          text {
            fill: $dark-all-font-color !important;
          }
        }
      }
    }
    .table-primary{
      &.table-primary{
        &.table-striped{
          tbody{
            tr{
              &:nth-child(even){
                &:hover{
                  th,td{
                    color: $black !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    // apex chart
    .apexcharts-grid {
      .apexcharts-gridlines-horizontal {
        line {
          stroke: $dark-border-color !important;
        }
      }
    }
    #radarchart {
      svg {
        .apexcharts-plot-series {
          polygon {
            stroke: $black !important;
            &:nth-child(odd) {
              fill: rgba($primary-color, 0.1) !important;
            }
            &:nth-child(even) {
              fill: $dark-card-background !important;
            }
          }
          line {
            stroke: $black !important;
          }
        }
      }
    }
    // draggable card  page
    .fiziMw {
      background-color: $dark-body-background;
    }
    .fAifAI,
    .igzqQK {
      background-color: $dark-card-background;
    }
    .hbRaEE {
      border-bottom: 1px solid $dark-border-color;
    }
    .pricing-simple,
    .pricing-block {
      box-shadow: 0px 0px 34px 0px rgba($dark-body-background, 0.5) !important;
    }
    // timeline css
    .vertical-timeline-element-content {
      background-color: white;
      .vertical-timeline-element-content-arrow {
        border-right: 7px solid gray;
      }
    }
    .vertical-timeline {
      &::before {
        background: $dark-body-background;
      }
    }
    .vertical-timeline-element-icon {
      box-shadow: 0 0 0 4px $dark-body-background,
        inset 0 2px 0 rgba($black, 0.08), 0 3px 0 4px rgba($black, 0.05);
    }
    // react datatable css start
    .bDqsaw {
      background-color: $dark-card-background;
    }
    .icryKO,
    .hQKtXE {
      color: $dark-all-font-color;
    }
    .wHZdS {
      color: $dark-all-font-color;
      fill: $dark-all-font-color;
    }
    .bfkBMm {
      background-color: $dark-card-background;
      color: $white;
      &:nth-of-type(odd) {
        background-color: $dark-body-background;
      }
    }
    .brMVWh {
      background-color: $dark-card-background;
      color: $white;
      &:nth-of-type(odd) {
        background-color: $dark-body-background;
      }
    }
    .dmliPE {
      background-color: $dark-card-background;
      color: $white;
    }
    .gxxxtc {
      color: $white;
    }
    .iOfXhs {
      &:hover {
        color: $white;
      }
    }
    .ezWsxJ {
      background-color: rgba($primary-color, 0.05) !important;
      color: $white;
    }
    .dgelcB {
      background-color: $transparent-color;
    }
    .rdt_Table {
      border: 1px solid $dark-border-color;
    }
    // react datatable css end
    .page-wrapper
      .page-body-wrapper
      .iconsidebar-menu
      .iconMenu-bar
      .iconbar-mainmenu
      li.active
      a.active {
      color: $white;
      font-weight: 700;
    }
    .ProfileCard {
      &:hover {
        background: $dark-card-background;
      }
    }
    .ProfileCard-realName {
      a,
      span {
        color: $light-color;
      }
    }
    #candlestick-chart {
      .apexcharts-tooltip {
        &.apexcharts-theme-light {
          background: $dark-body-background;
          border: $dark-body-background;
        }
      }
    }
    .popover-header {
      color: $black;
    }
    .nav-primary {
      .nav-link,
      .show {
        &.active,
        > .nav-link {
          background-color: $primary-color !important;
          color: $white !important;
        }
      }
    }
    .nav-secondary {
      .nav-link,
      .show {
        &.active,
        > .nav-link {
          background-color: $secondary-color !important;
          color: $white !important;
        }
      }
    }
    .apexcharts-tooltip {
      &.apexcharts-theme-light {
        background: $dark-body-background;
        border: $dark-body-background;
        .apexcharts-tooltip-title {
          background: $dark-card-background;
          border-bottom: 1px solid $dark-border-color;
        }
      }
    }
    .activity-table .activity-details h6 {
      color: $light-color;
    }
    .activity-table .light-text {
      color: rgba(255, 255, 255, 0.3);
    }
    .card {
      &.card-bg {
        background-color: #181a2d !important;
        .card-header {
          background-color: transparent;
        }
      }
      .card-header {
        border-bottom: 1px solid $dark-border-color;
        background-color: $dark-card-background;
        &.no-border {
          border-bottom: none;
        }
      }
    }
    .setting-dot {
      .setting-bg {
        background-color: $secondary-color;
      }
    }
    .card {
      .card-header {
        h5 {
          background: unset;
          -webkit-text-fill-color: $white;
        }
      }
    }
    .crypto-graph-card {
      .media {
        .bitcoin-graph-content {
          span {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .crypto-table-market {
      table {
        thead {
          tr {
            th {
              background-color: #2a3849;
            }
          }
        }
      }
    }
    .crypto-revenue {
      .apexcharts-canvas {
        svg {
          .apexcharts-inner {
            defs {
              linearGradient {
                stop {
                  &:nth-child(2),
                  &:last-child {
                    stop-color: $transparent-color !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .apex-chart-container {
      .apexcharts-legend {
        .apexcharts-legend-series {
          span {
            color: $dark-all-font-color !important;
          }
        }
      }
    }
    .apexcharts-canvas {
      svg {
        .apexcharts-title-text {
          fill: $white;
        }
        .apexcharts-subtitle-text {
          fill: $white;
        }
        .apexcharts-yaxis {
          .apexcharts-yaxis-texts-g {
            .apexcharts-yaxis-label {
              fill: $white;
            }
          }
        }
        .apexcharts-xaxis {
          .apexcharts-xaxis-texts-g {
            .apexcharts-xaxis-label {
              fill: $white;
            }
          }
        }
        .apexcharts-legend-series {
          span {
            color: $dark-all-font-color !important;
          }
        }
        .apexcharts-datalabels-group {
          .apexcharts-datalabel-label {
            fill: $white;
          }
          .apexcharts-datalabel-value {
            fill: $white;
          }
        }
      }
    }
    .Typeahead-menu {
      background-color: $dark-body-background;
    }
    .ecommerce-widget {
      border: 1px solid $dark-border-color;
    }
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: $dark-border-color !important;
            border-color: $dark-border-color !important;
          }
        }
      }
    }
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        padding: 40px;
      }
    }
    .order-history{
      table{
        thead{
          th{
            border-top: unset !important;
          }
        }
        tbody{
          tr{
            &:last-child{
              td{
                border-bottom: unset;
              }
            }
            td{
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .invoice{
      .invoice-table{
        .payment.digits{
          border-bottom: 1px solid $dark-border-color;
        }
        table.table-striped{
          > tbody{
            > tr{
              &:first-child{
                td{
                  background-color: $dark-body-background;
                  border-top: 2px solid $dark-border-color !important;
                  h6{
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-border-color;
      }
      .qty {
        li {
          color: #bfc2c6;
          span {
            color: #bfc2c6;
          }
        }
        border-bottom: 1px solid $dark-border-color;
      }
      .sub-total {
        li {
          color: #bfc2c6;
        }
      }
      .total {
        li {
          color: #bfc2c6;
        }
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .btc-buy-sell {
      .btc-amount {
        span {
          color: $white;
        }
      }
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(41, 50, 64, 0.52);
    }
    .card {
      .card-header {
        .card-header-right {
          background-color: $dark-card-background;
        }
      }
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .slider-product {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }
        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-border-color;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;
            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .google-visualization-tooltip {
      text {
        fill: rgba(0, 0, 0, 0.7) !important;
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white !important;
      }
    }
    .loader-wrapper {
      background-color: $dark-body-background;
      .loader {
        background-color: $dark-body-background !important;
      }
    }
    .page-wrapper {
      /* Main Header start */
      .page-main-header {
        background-color: $dark-body-background;
        .main-header-right {
          .mobile-sidebar {
            .switch {
              background-color: $dark-body-background;
              svg {
                color: $white !important;
              }
            }
          }
          background-color: $dark-card-background;
          box-shadow: 0 0 18px 3px rgba(42, 50, 61, 1);
          li {
            i {
              color: $light-color;
            }
          }
          .nav-right {
            .flip-card {
              .flip-card-inner {
                .front,
                .back {
                  background-color: $dark-body-background;
                }
                .back {
                  .flip-back-content {
                    input {
                      border-color: $light-all-font-color;
                      color: $light-color;
                    }
                  }
                  li {
                    &:last-child {
                      background: $dark-body-background;
                      border-top: 1px solid $light-all-font-color;
                    }
                  }
                }
              }
            }
            .bookmark-dropdown {
              li {
                &:last-child {
                  border-top: 1px solid $light-all-font-color;
                }
              }
              .flip-btn {
                color: $white;
              }
            }
            .nav-menus {
              .droplet-dropdown {
                li {
                  .row {
                    .droplet-main {
                      a{
                        span{
                          color: $dark-all-font-color;
                        }
                      }
                      &:nth-child(3n) {
                        border-right: none;
                      }
                      border-right: 1px solid $light-all-font-color;
                      border-bottom: 1px solid $light-all-font-color;
                    }
                  }
                }
              }
            }
            > ul {
              > li {
                &:before {
                  background-color: $light-all-font-color;
                }
                svg {
                  stroke: $white;
                }
                &:first-child {
                  .search-form {
                    .form-group {
                      &:after {
                        color: rgba($white, 0.6);
                      }
                      &:before {
                        background: rgba($white, 0.6);
                      }
                    }
                  }
                }
              }
            }
            .nav-menus {
              li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  .bg-light {
                    background-color: #282e38 !important;
                    color: $dark-all-font-color !important;
                  }
                }
              }
            }
            .profile-dropdown {
              li {
                a {
                  color: $dark-all-font-color;
                  svg {
                    path,
                    line,
                    circle {
                      color: $dark-all-font-color !important;
                    }
                  }
                  &:hover {
                    svg {
                      line,
                      path,
                      circle {
                        color: $primary-color !important;
                      }
                    }
                  }
                }
              }
            }
            .search-form {
              input {
                color: rgba($white, 0.6);
                &:focus-visible{
                  outline: none;
                }
                &::placeholder {
                  color: rgba($white, 0.6);
                }
              }
            }
            > ul {
              > li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before,
                  &:after {
                    border-bottom: 7px solid $primary-color !important;
                  }
                }
              }
            }
          }
        }
        .main-header-left {
          background-color: $dark-card-background;
          .logo-wrapper {
            a {
              .image-dark {
                display: none;
              }
              .image-light {
                display: block;
              }
            }
          }
        }
        @media only screen and (max-width: 991px) {
          .main-header-right {
            .nav-right {
              > ul {
                border-top: 1px solid $light-all-font-color;
                background-color: $dark-card-background;
                box-shadow: 0 2px 2px 2px $dark-border-color;
              }
            }
          }
        }
        @media only screen and (max-width: 575px) {
          .nav-right {
            > ul {
              .search-form {
                .form-control-plaintext {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      .right-sidebar {
        background-color: $dark-body-background;
        box-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
        }
        .friend-list-search {
          background-color: $dark-card-background;
          input {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
          .fa {
            color: $light-all-font-color;
          }
        }
      }
      &.horizontal_sidebar {
        .page-body-wrapper {
          .page-main-header {
            .main-header-right {
              border-bottom: 1px solid $dark-border-color;
            }
          }
          .iconsidebar-menu {
            .iconMenu-bar {
              li {
                &:active,
                &:active,
                &:focus,
                &.open {
                  .bar-icons {
                    span,
                    i {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      /* Main Header ends */
      .page-body-wrapper {
        .iconsidebar-menu {
          .sidebar {
            .iconMenu-bar {
              background-color: $dark-card-background;
              box-shadow: inset -2px 0px 2px 0px #4c5564;
              .bar-icons {
                border: none;
              }
              .iconbar-mainmenu {
                background-color: $dark-card-background;
                box-shadow: none;
                .iconbar-header {
                  background-color: $dark-card-background;
                  color: $white;
                }
                li {
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
              li {
                a {
                  i {
                    color: $dark-all-font-color;
                  }
                  span {
                    color: $white;
                  }
                }
              }
            }
          }
        }
        /* body start*/
        .page-body {
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }
          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .media-body,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users {
            .media {
              .media-body {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .media {
              .media-body {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .media {
              .media-body {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-border-color;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .media {
              .media-body {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .media {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
              .media-body {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }
        .page-header {
          .row {
            h2 {
              color: $white;
              span {
                color: $white;
              }
            }
            h6 {
              color: $dark-small-font-color;
            }
            .breadcrumb {
              .breadcrumb-item {
                color: $white;
                a {
                  color: $white;
                  background-color: $dark-card-background;
                }
              }
            }
            .breadcrumb-right {
              .breadcrumb {
                .breadcrumb-item {
                  &:before {
                    color: $white;
                  }
                }
              }
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .card-g-primary {
            .card-header {
              background-color: $primary-color !important;
              border-bottom: $white !important;
            }
          }
          .card-g-secondary {
            .card-header {
              background-color: $secondary-color !important;
              border-bottom: $white !important;
            }
          }
          .card-g-success {
            .card-header {
              background-color: $success-color !important;
              border-bottom: $white !important;
            }
          }
          .card-g-info {
            .card-header {
              background-color: $info-color !important;
              border-bottom: $white !important;
            }
          }
          .card-g-warning {
            .card-header {
              background-color: $warning-color !important;
              border-bottom: $white !important;
            }
          }
          .card-g-danger {
            .card-header {
              background-color: $danger-color !important;
              border-bottom: $white !important;
            }
          }
          .card {
            background-color: $dark-card-background;
            box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
            .chart-block {
              #bar-chart2 {
                svg {
                  > rect {
                    fill: $dark-card-background;
                  }
                  > g {
                    text {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }
              .word-tree {
                svg {
                  > g {
                    > rect {
                      fill: $dark-card-background;
                    }
                    > text {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .card-header {
              .buttons-right {
                .right-header-dropdown.onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-body-background;
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
              &.card-header-border {
                .right-header {
                  span {
                    &:first-child {
                      color: $dark-small-font-color;
                    }
                  }
                  .right-header-dropdown.onhover-show-div {
                    background-color: $dark-body-background;
                    box-shadow: 0 0 2px 2px $dark-body-background;
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                i {
                  color: $dark-all-font-color;
                }
              }
            }
            .alert-dark {
              color: $dark-small-font-color;
              a {
                color: $dark-small-font-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            .table {
              th,
              td {
                color: $dark-all-font-color;
              }
              thead {
                th {
                  border-bottom: 2px solid $dark-border-color;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 2px solid $primary-color !important;
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $theme-font-color;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .thead-light {
                th {
                  color: $dark-all-font-color;
                }
              }
              .double,
              .dotted,
              .dashed {
                border-color: $dark-border-color;
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
            }
            .table-borderless {
              th,
              td {
                border: none !important;
              }
              thead,
              tbody {
                th,
                + tbody {
                  border: none !important;
                }
              }
            }
            .table-responsive {
              .table {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                  border-color: $dark-border-color;
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-bordered {
              td,
              th {
                border-color: $dark-border-color;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-border-color;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .bg-white {
              background-color: $dark-card-background !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-border-color !important;
            }
            .ct-grid {
              stroke: $dark-small-font-color;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
            .text-muted {
              color: $sidebar-submenu-font-color !important;
            }

            .contact-form {
              .theme-form {
                border: 1px solid $dark-border-color;
                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
            .btn-outline-light,
            .btn-outline-dark {
              color: $white !important;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }
            .border-right {
              border-right: 1px solid $dark-border-color !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#gantt_chart {
                svg {
                  g {
                    rect {
                      &:first-child {
                        fill: $dark-body-background;
                      }
                    }
                  }
                }

                rect {
                  &:nth-child(6) {
                    fill: $dark-body-background;
                  }
                }
              }

              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-right: 1px solid $dark-border-color !important;
            }
            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }
                .ct-grid {
                  stroke: $white;
                }
              }
              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-border-color;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-border-color;
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-border-color;
              }
            }
            .page-link {
              color: $dark-all-font-color;
              background-color: $dark-card-background;
            }
            .page-item {
              &:hover {
                .page-link {
                  background-color: $secondary-color;
                }
              }
            }
            .page-item.active {
              .page-link {
                background-color: $secondary-color;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .border-tab-primary.nav-tabs {
              border-bottom: 1px solid $dark-border-color;
              .nav-item {
                .nav-link {
                  color: $dark-all-font-color;
                }
              }
              .nav-link {
                &.active,
                &:focus,
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .br-theme-bars-1to10,
            .br-theme-bars-movie,
            .br-theme-bars-pill,
            .br-theme-bars-reversed,
            .br-theme-bars-horizontal {
              .br-widget {
                a {
                  background-color: $dark-border-color;
                  &.br-active,
                  &.br-selected {
                    background-color: $primary-color;
                  }
                }
              }
            }
            .br-theme-bars-square {
              .br-widget {
                a {
                  border: 2px solid $dark-border-color;
                  background-color: $dark-card-background;
                  color: $dark-all-font-color;
                  &.br-active,
                  &.br-selected {
                    border: 2px solid $primary-color;
                    color: $primary-color;
                  }
                }
              }
            }
            .br-theme-fontawesome-stars,
            .br-theme-fontawesome-stars-o {
              .br-widget {
                a {
                  &:after {
                    color: $dark-border-color;
                  }
                  &.br-selected,
                  &.br-active {
                    &:after {
                      color: $primary-color;
                    }
                  }
                }
              }
            }
            .scroll-demo {
              border: 1px solid $dark-border-color;
            }
            .search-form {
              input {
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .breadcrumb {
              background-color: $dark-body-background;
            }
            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }
            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart,
            #website-visiter-chart {
              svg {
                opacity: 0.5;
              }
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .separator {
              border: 1px solid $dark-border-color;
            }
            &.default-according {
              li {
                .collapse {
                  background: $transparent-color;
                }
              }
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: $primary-color !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: $primary-color;
                  a {
                    color: $primary-color;
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 2px 2px $dark-border-color;
              .navs-icon {
                li {
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: $primary-color !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }
          .default-according {
            .card {
              box-shadow: 0px 0px 1px 1px rgb(55, 66, 82);
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
            }
          }
          .border {
            border: 1px solid $dark-border-color !important;
          }
          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }
            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-right: 1px solid #586082;
                  }
                  &:last-child {
                    border-right: none;
                  }
                }
              }
              p {
                color: $dark-all-font-color;
              }
              .single-blog-content-top {
                border-top: 1px solid #586082;
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .comment-box {
            .media {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }
              img {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
            .comment-social {
              li {
                color: $dark-small-font-color;
                &:first-child {
                  border-right: 1px solid #586082;
                }
              }
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
          }
          .table-hover {
            tbody {
              tr {
                &:hover {
                  background-color: $dark-body-background;
                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }
          ul.the-icons {
            li {
              border: 1px dotted $dark-border-color;
              color: $sidebar-submenu-font-color;
              display: inline-block;
              padding: 10px;
              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }
              em {
                display: none;
              }
            }
          }
          .dzu-previewButton {
            filter: brightness(1) invert(1);
          }
          .dzu-previewContainer {
            border-bottom: 1px solid $light-all-font-color;
          }
          $page-name: theme, primary, secondary, success, danger, info, dark,
            warning;
          $page-color: $primary-color, $primary-color, $secondary-color,
            $success-color, $danger-color, $info-color, $dark-color,
            $warning-color;
          @each $var in $page-name {
            $i: index($page-name, $var);
            .pagination-#{$var} {
              .page-item {
                .page-link {
                  color: nth($page-color, $i);
                }
                &.active {
                  .page-link {
                    color: $white;
                    background-color: nth($page-color, $i);
                    border-color: nth($page-color, $i);
                  }
                }
              }
            }
          }
          .pagination {
            .page-item {
              .page-link {
                border-color: $light-all-font-color;
              }
              &:hover {
                .page-link {
                  background: $dark-body-background;
                }
              }
            }
          }
          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-border-color;
              }
              h6 {
                color: $dark-all-font-color;
              }
            }
            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }
          #donut-color-chart-morris,
          #donut-color-chart-morris-daily {
            svg {
              text {
                fill: $dark-all-font-color;
              }
            }
          }
          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }
            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-social {
              li {
                a {
                  color: $dark-small-font-color;
                }
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }
                + div {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          .form-control {
            background-color: $dark-body-background;
            color: $dark-all-font-color;
            border: 1px solid $dark-border-color !important;
          }
          .search-knowledge {
            input {
              background-color: $dark-card-background;
            }
          }
          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown-divider {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
          .dropzone.dz-clickable {
            .dz-message {
              h6 {
                color: $dark-all-font-color;
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .dropzone {
            .dz-preview {
              background-color: $dark-body-background;
              .dz-details {
                background-color: $dark-card-background;
              }
            }
          }
          .browser-widget {
            .media-body {
              column-rule: 1px solid $dark-border-color;
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .email-wrap {
            .email-app-sidebar {
              .media {
                img {
                  border: 2px solid $dark-body-background;
                }
              }
              .main-menu {
                > li {
                  a {
                    color: $dark-all-font-color;
                    &:hover {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            .email-right-aside {
              .email-body {
                .inbox {
                  .media.active {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
            .media-body {
              h6 {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
              p {
                color: $dark-small-font-color;
              }
            }
            .email-top {
              border-bottom: 1px solid $dark-border-color;
              .dropdown {
                .dropdown-menu {
                  .dropdown-item {
                    color: $dark-all-font-color;
                    &:hover {
                      background: $dark-card-background;
                    }
                  }
                }
              }
            }
            p {
              color: $dark-small-font-color;
            }
            .email-content {
              .email-top {
                .user-emailid:after {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              color: $dark-all-font-color;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }
              &:hover {
                background-color: $dark-body-background;
              }
            }
          }
          .widget-joins {
            .media {
              .media-body {
                > span {
                  color: $dark-small-font-color;
                }
              }
              .details {
                border-left: 1px solid $dark-border-color;
              }
            }
            &:before,
            &:after {
              background-color: $dark-border-color;
            }
          }
          .social-widget-card {
            h5,
            h4 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .b-b-light {
            border-bottom: 1px solid $dark-border-color !important;
          }
          .b-r-dark {
            border-right: 1px solid $white !important;
          }
          .testimonial {
            i {
              color: $dark-border-color;
            }
            p {
              color: $dark-all-font-color;
            }
            h5 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .grid-showcase {
            span {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
          }
          .grid-align {
            .row {
              background-color: rgba($primary-color, 0.05);
              border: 1px solid rgba($primary-color, 0.05);
            }
          }
          .border-style {
            .card {
              border: 1px solid $dark-border-color;
            }
          }
          .offer-style {
            .card {
              border: 1px dashed $dark-border-color;
            }
          }
          .search-page {
            .info-block {
              + .info-block {
                border-top: 1px solid $dark-border-color;
              }
            }
            ul {
              &.search-info {
                li {
                  + li {
                    border-left: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }
          .timeline-small {
            .media {
              .timeline-round {
                &.timeline-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
            }
          }
          /* body end*/
        }
        footer {
          p {
            color: $dark-all-font-color;
          }
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
          background-image: none;
        }
        .form-select {
          background: $dark-body-background !important;
          color: $dark-all-font-color;
        }
        .form-label {
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-border-color;
        }
      }
      .dt-button-info {
        background-color: $dark-card-background;
        border: 1px solid $dark-small-font-color;
        h2 {
          background-color: $dark-card-background;
        }
      }
      .chat-box {
        .about {
          .name {
            color: $dark-all-font-color;
          }
        }
        .chat-menu {
          border-left: 1px solid $dark-border-color;
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color;
            .nav-item {
              .nav-link {
                &.active {
                  color: $dark-all-font-color !important;
                }
              }
            }
          }

          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }
              .avatar {
                img {
                  border: 5px solid $dark-border-color;
                }
              }
            }
            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }
            .follow {
              span {
                color: $dark-small-font-color;
              }
              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .status {
          color: $dark-small-font-color;
          p {
            color: $dark-all-font-color !important;
          }
        }
        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-border-color;
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }
            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-border-color;
              }
              .message {
                color: $dark-all-font-color;
              }
              .other-message {
                background-color: $dark-body-background;
              }
            }
            .chat-message {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-border-color;
                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
      pre {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-border-color;
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        background-color: $dark-card-background;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $dark-all-font-color;
        &.active {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .tab-javascript {
        ul {
          &.list-group {
            &:first-child {
              .list-group-item {
                border-top: 1px solid rgba($white, 0.3) !important;
              }
            }
          }
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }
      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }
      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }
      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }
      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }
      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }
      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }
      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }
      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-border-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-border-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
          .btn-close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-border-color;
        }
      }
      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba($dark-card-background, 0.9) !important;
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-border-color;
            border-bottom: 1px solid $dark-border-color;
            .title {
              color: $dark-all-font-color;
            }
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-border-color;
          &:before {
            background: $dark-card-background;
            color: $dark-all-font-color;
          }
        }
      }
      .checkbox {
        label {
          &::before {
            background-color: $dark-body-background;
            border-color: $dark-body-background;
          }
        }
      }
      .authentication-main {
        background-color: $dark-body-background;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-border-color;
                }
              }
              .opt-box {
                background-color: rgba($primary-color, 0.1);
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-background;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
        }
        .bg-primary {
          .btn-link {
            background-color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: $secondary-color;
            border: 1px solid $secondary-color;
          }
        }
      }
      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }
      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-border-color;
                }
              }
            }
            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .caller-img {
          img {
            opacity: 0.7;
          }
        }
        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: rgba(0, 0, 0, 0.75);
                background-blend-mode: overlay;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .chat-menu {
          border-top: 1px solid $dark-border-color;
          background-color: $dark-card-background;
        }
        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
        .product-sidebar {
          &.open {
            &:before,
            &:after {
              border-bottom: 7px solid $dark-card-background;
            }
          }
        }
      }
      @media only screen and (max-width: 767px) {
        .feature-products {
          .filter-toggle {
            background-color: $dark-card-background;
          }
        }
      }
      @media only screen and (max-width: 575px) {
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-border-color;
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
        .widget-joins {
          .border-after-xs {
            &:after {
              background-color: $dark-border-color;
            }
          }
        }
        .page-main-header {
          .main-header-right {
            .nav-right {
              .droplet-dropdown {
                li {
                  .row {
                    .droplet-main {
                      &:nth-child(3n) {
                        border-right: 1px solid $light-all-font-color !important;
                      }
                      &:nth-child(2n) {
                        border-right: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .general-widget {
      .table {
        tr {
          td {
            border-bottom: 1px solid $dark-border-color;
          }
        }
      }
    }
    // react calendar css start
    .react-calendar__tile {
      color: $white;
    }
    .react-calendar {
      .react-calendar__navigation {
        button {
          color: $white;
        }
      }
    }
    // react calendar css end
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .drag {
      background-color: $dark-card-background;
      form {
        background: $dark-card-background;
        box-shadow: 0 0 30px $dark-border-color;
        border: 1px dotted $dark-border-color;
        input {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
        .help-block {
          text-transform: capitalize;
          color: $dark-small-font-color;
        }
      }
    }
    .draggable {
      input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      p {
        color: $dark-small-font-color;
      }
      select {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      .radio,
      .checkbox {
        label {
          &::before {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-border-color;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-border-color;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-border-color;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }
    .editor-toolbar {
      border-top: 1px solid $dark-small-font-color;
      border-left: 1px solid $dark-small-font-color;
      border-right: 1px solid $dark-small-font-color;
      a {
        color: $dark-all-font-color !important;
        &:hover,
        &.active {
          background: $dark-border-color;
        }
      }
      i.separator {
        border-left: 1px solid $dark-small-font-color;
        border-right: 1px solid $dark-small-font-color;
      }
      button {
        color: $dark-all-font-color;
        &:hover,
        &:focus,
        &.active {
          background: $dark-body-background;
          border-color: $dark-border-color$dark-border-color;
        }
      }
      &.fullscreen {
        &::before {
          background: linear-gradient(
            to right,
            $dark-card-background 0,
            rgba($black, 0) 100%
          );
        }
        &::after {
          background: linear-gradient(
            to right,
            rgba($black, 0) 0,
            $dark-card-background 100%
          );
        }
      }
    }
    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-border-color;
        }
      }
    }
    .editor-preview {
      background-color: $dark-card-background;
    }
    .editor-toolbar.fullscreen,
    .editor-preview-side {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
    }
    .input-cropper {
      border-color: $light-all-font-color;
    }
    .fileContainer {
      background: $dark-card-background;
    }
    .u-step {
      background: rgba($primary-color, 0.1);
      &.active,
      &.current {
        background: $primary-color;
        color: $white;
      }
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-border-color;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-border-color;
        border: 2px solid $dark-border-color;
      }
      &:after {
        background-color: #334053;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .swal-modal {
      background-color: $dark-card-background;
      .swal-title {
        color: $dark-all-font-color;
      }
      .swal-text {
        color: $dark-small-font-color;
      }
      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid $dark-border-color;
      .nav-link {
        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
        &:hover,
        &:focus {
          border-color: $dark-border-color $dark-border-color $dark-border-color;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-border-color
              $dark-border-color;
          }
        }
      }
    }
    .border-tab-primary.nav-left,
    .border-tab-primary.nav-right {
      .nav-link {
        color: $dark-all-font-color;
        &.active {
          color: $primary-color;
        }
      }
      .show {
        > .nav-link {
          color: $primary-color;
        }
      }
    }
    .border-tab-primary.nav-left.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          border-left-color: $secondary-color;
          color: $secondary-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $secondary-color;
        color: $secondary-color !important;
      }
      .nav-item.show {
        color: $secondary-color !important;
        border-left-color: $secondary-color;
      }
    }
    .border-tab-primary.nav-left.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $success-color;
        color: $success-color !important;
      }
      .nav-item.show {
        color: $success-color !important;
        border-left-color: $success-color;
      }
    }
    .border-tab-primary.nav-right.nav-info {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $info-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $info-color;
        color: $info-color !important;
      }
      .nav-item.show {
        color: $info-color !important;
        border-left-color: $info-color;
      }
    }
    .border-tab-primary.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $secondary-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $secondary-color !important;
        }
      }
    }
    .border-tab-primary.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $success-color !important;
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-border-color;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-border-color;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: #d8ecff;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background: $dark-border-color;
      }
    }
    .timeliny {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
      &::before {
        background-color: $white;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $white;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $white;
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: $primary-color;
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .tt-suggestion {
        color: $dark-all-font-color;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .blockquote-footer {
      color: $dark-small-font-color;
    }
    .code-box-copy {
      pre {
        background-color: $dark-body-background;
        code {
          background-color: $dark-body-background;
        }
      }
      pre[class*="language-"] {
        border: 1px solid $dark-border-color;
      }
      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        color: $white;
      }
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;
        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }
    .dt-button-info {
      background-color: $dark-card-background;
      border: 1px solid $dark-border-color;
      h2 {
        background-color: $dark-card-background;
        border-bottom: 1px solid $dark-border-color;
      }
    }
    .cont,
    .sub-cont {
      background-color: $dark-card-background;
    }
    .error-wrapper {
      background-color: rgba($dark-body-background, 0.8);
      .sub-content {
        color: $dark-all-font-color;
      }
      &.maintenance-bg {
        background-color: #0f1011;
      }
    }
    .b-light {
      border: 1px solid $dark-border-color !important;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        border-bottom: 1px solid $dark-border-color;
      }
      .ui-front {
        .form-control {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
    }
    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
      }
      .modal-header {
        .btn-close {
          color: $light-all-font-color;
        }
      }
      .modal-footer {
        border-top: 1px solid $light-all-font-color;
      }
    }
    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: $info-color;
    }
    .token {
      &.boolean,
      &.constant,
      &.deleted,
      &.number,
      &.property,
      &.symbol,
      &.tag {
        color: $danger-color;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-border-color !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .alert-theme {
      span {
        + span {
          + span {
            border: 1px solid $primary-color;
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }
      i {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }
    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    .basic-calendar {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-basicWeek-button,
            .btn-light {
              color: $black !important;
            }
            .fc-month-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .categories {
      ul {
        li {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .social-status {
      .media {
        .media-body {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
      form {
        .form-group {
          .form-control-plaintext {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }
          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .social-chat {
      .media-body {
        border: 1px solid $dark-border-color;
        &:after {
          border-right: 7px solid $dark-card-background;
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    &[main-theme-layout="rtl"] {
      @media only screen and (max-width: 575px) {
        .page-main-header {
          .main-header-right {
            .nav-right {
              .droplet-dropdown {
                li {
                  .row {
                    .droplet-main {
                      &:nth-child(odd) {
                        border-right: none !important;
                      }
                      &:nth-child(even) {
                        border-right: 1px solid $light-all-font-color !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.sidebar-dark {
    .page-wrapper
      .page-body-wrapper
      .iconsidebar-menu
      .iconMenu-bar
      .iconbar-mainmenu
      li.active
      a.active {
      color: #fff;
      font-weight: 700;
    }
    .page-body-wrapper {
      .iconsidebar-menu {
        .sidebar {
          .iconMenu-bar {
            background-color: $dark-card-background;
            box-shadow: inset -2px 0px 2px 0px #4c5564;
            .bar-icons {
              border: none;
            }
            .iconbar-mainmenu {
              background-color: $dark-card-background;
              box-shadow: none;
              .iconbar-header {
                background-color: $dark-card-background;
                color: $white;
              }
              li {
                a {
                  color: $dark-all-font-color;
                }
              }
            }
            li {
              a {
                i {
                  color: $dark-all-font-color;
                }
                span {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .page-wrapper {
      &.horizontal_sidebar {
        .page-body-wrapper {
          .page-main-header {
            .main-header-right {
              border-bottom: 1px solid $dark-border-color;
            }
          }
          .iconsidebar-menu {
            .iconMenu-bar {
              li {
                &:active,
                &:active,
                &:focus,
                &.open {
                  .bar-icons {
                    span,
                    i {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/**=====================
   47. theme layout CSS ends
==========================**/
